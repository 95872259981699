import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { EmployeesService, IEmployeeListDTO, IGetEmployeeListRequest } from '@app/core/employees';
import { LayoutService } from '@app/core/layout';
import { MessageBoxService } from '@app/shared';


@Component({
  selector: 'app-employees-container',
  templateUrl: './employees-container.component.html',
  styleUrls: ['./employees-container.component.scss']
})
export class EmployeeListContainerComponent {

  public employees$: BehaviorSubject<IEmployeeListDTO[]>;
  public loading: boolean;
  public showSearch: boolean;
  public request: IGetEmployeeListRequest;
  public totalRecords: number;

  constructor(
    private service: EmployeesService,
    private layout: LayoutService,
    private msgbox: MessageBoxService
  ) {

    this.request = {
    };

    this.employees$ = new BehaviorSubject(null);

    this.layout.setToolbar({
      showMenuButton: true,
      title: 'Empleados',
      rightButtons: [{
        icon: 'search',
        action: () => this.toggleSearch()
      }]
    });

    this.loadData();
  }

  private toggleSearch(): void {
    this.showSearch = !this.showSearch;
  }

  private loadData(): void {

    this.loading = true;

    this.service
    .getEmployeeList(this.request)
    .subscribe((data) => {
      this.totalRecords = data.totalRecords;
      this.employees$.next(data.records);
      this.loading = false;
    }, (ex) => {
      this.loading = false;
      throw ex;
    });
  }

  public searchChanged($event: string): void {
    this.request.searchText = $event;
    this.loadData();
  }

  public deleteEmployee(employee: IEmployeeListDTO): void {

    this.msgbox
    .showYesNo({ title: '¿Eliminar empleado?' })
    .subscribe((choice) => {

      if (choice) {

        this.loading = true;

        this.service
        .deleteEmployee(employee.uid)
        .subscribe(() => {

          if (this.totalRecords > 0) {
            this.totalRecords--;
          }

          const records = this.employees$.getValue();
          records.splice(records.findIndex(e => e.uid === employee.uid), 1);
          this.employees$.next([...records]);

        }, () => {}, () => this.loading = false);
      }

    });

  }
}
