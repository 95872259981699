import { CanActivate, CanActivateChild, Router, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from './auth.service';
import { UserRole } from './defs';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivateChild, CanActivate {

  constructor(private authService: AuthService, private router: Router) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

    const redirectUrl = route['_routerState']['url'];
    if (this.authService.isAuthenticated()) {

      if (redirectUrl === '/') {
        if (this.authService.isRole(UserRole.SuperAdmin)) {
          return this.router.navigateByUrl('/admin/organisations');
        } else {
          return this.router.navigateByUrl('/jobs');
        }
      }

      return true;
    }

    return this.router.navigateByUrl(
      this.router.createUrlTree(
        ['/auth/login'], {
        queryParams: {
          redirectUrl
        }
      })
    );

  }

  public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

    if (this.authService.isAuthenticated()) {
      const roles = childRoute.data.roles as number[];
      if (!roles || roles.indexOf(this.authService.getSession().role) > -1) {
        return true;
      }
    }

    return this.router.navigate(['/auth/login']);
  }

}
