import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { BehaviorSubject, throwError } from 'rxjs';

import { IVisualPlotDTO, VisualService } from '@app/core/visual';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-plot-selector',
  templateUrl: './plot-selector.component.html',
  styleUrls: ['./plot-selector.component.scss']
})
export class PlotSelectorComponent implements OnChanges {

  @Input()
  public selectedId: number;

  @Input()
  public readOnly: boolean;

  @Input()
  public coordinates: { latitude: number, longitude: number };

  @Output()
  public selectedChanged: EventEmitter<IVisualPlotDTO>;

  public plots$: BehaviorSubject<IVisualPlotDTO[]>;
  public loading: boolean;

  constructor(
    private service: VisualService
  ) {
    this.selectedChanged = new EventEmitter();
    this.plots$ = new BehaviorSubject(null);
  }

  public ngOnChanges(): void {

    if (this.coordinates?.latitude && this.coordinates?.longitude) {

      this.loading = true;

      this.service
        .getPlotList(this.coordinates)
        .pipe(
          catchError(err => {
            this.loading = false;
            return throwError(err);
          })
        )
        .subscribe(plots => {
          this.loading = false;
          this.plots$.next(plots);
          this.syncSelection();
        });

    } else {
      this.plots$.next([]);
      this.syncSelection();
    }

  }

  private syncSelection(): void {

    if (this.readOnly) {
      return;
    }

    const plots = this.plots$.value;

    if (plots.length === 1 && plots[0].id !== this.selectedId) {
      this.selectedId = plots[0].id;
      this.raiseSelectedChanged();
    } else if (this.selectedId && !this.plots$.value.some(p => p.id === this.selectedId)) {
      delete this.selectedId;
      this.raiseSelectedChanged();
    }

  }

  public raiseSelectedChanged(): void {
    const plot = this.plots$.value.find(plot => plot.id == this.selectedId);
    this.selectedChanged.next(plot);
  }
}
