import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ISimpleDTO } from '@app/core';
import { LayoutService } from '@app/core/layout';
import { IUserDTO, UsersService } from '@app/core/users';
import { MessageBoxService } from '@app/shared';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

  public user: IUserDTO;
  public frmUser: FormGroup;
  public working: boolean;

  public roles$: Observable<ISimpleDTO[]>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: UsersService,
    private layout: LayoutService,
    private msgbox: MessageBoxService
  ) {
    this.frmUser = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.email]),
      role: new FormControl('', [Validators.required])
    });

    this.roles$ = this.service.getAllowedRolesForCreating().pipe(tap(values => {

      if (values.length === 1) {
        this.frmUser.patchValue({
          role: values[0].id
        });
      }

    }));
  }

  public ngOnInit(): void {
    this.user = (this.route.snapshot.data.user || {}) as IUserDTO;
    this.frmUser.patchValue(this.user);

    this.layout.setToolbar({
      title: 'Nuevo usuario',
      showMenuButton: false,
      leftButtons: [
        {
          action: () => this.router.navigate(['users']),
          icon: 'arrow_back',
        }
      ],
      rightButtons: [
        {
          action: () => this.saveChanges(),
          icon: 'save',
        }
      ]
    });

  }

  public saveChanges(): void {

    if (!this.frmUser.valid) {
      this.frmUser.markAllAsTouched();
      this.msgbox.showError('Complete los campos requeridos');
      return;
    }

    this.working = true;
    const request = this.frmUser.value;

    const op = this.service.createUser(request);
    op.subscribe(() => {
      this.msgbox.showSuccess('Cambios guardados');
      this.router.navigate(['users']);
    }, (ex) => {
      this.working = false;
      throw ex;
    }, () => this.working = false);

  }

}
