import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { IToolbarState, LayoutService } from '@app/core/layout';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {

  public state$: Observable<IToolbarState>;

  constructor(
    private service: LayoutService
  ) {
    this.state$ = this.service.toolbarState$;
  }

  public toggleSidenav(): void {
    this.service.toggleSidenav();
  }

}
