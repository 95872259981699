import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ISimpleExDTO } from '../defs';
import { RequestService } from '../_common';
import { ISetEmployeeToUserRequest } from './defs';

@Injectable({
  providedIn: 'root'
})
export class UserEmployeesService {

  constructor(
    private request: RequestService
  ) { }

  public setEmployeesToUser(request: ISetEmployeeToUserRequest): Observable<any> {
    return this.request.post('/api/user-employees', request);
  }

  public getEmployees(ownerId: string): Observable<ISimpleExDTO[]> {
    return this.request.get(`/api/user-employees/${ownerId}`);
  }
}
