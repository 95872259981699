import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';
import { JobTasksModule } from '@app/job-tasks';
import { EmployeesModule } from '@app/employees';

import { ReportsContainerComponent } from './container/reports-container.component';
import { EmployeeReportComponent } from './employee-report/employee-report.component';
import { DayReportComponent } from './day-report/day-report.component';
import { JobReportComponent } from './job-report/job-report.component';
import { ReportLocationsComponent } from './report-locations/report-locations.component';
import { ReportsService } from '@app/core/reports';

@NgModule({
  declarations: [
    ReportsContainerComponent,
    EmployeeReportComponent,
    DayReportComponent,
    JobReportComponent,
    ReportLocationsComponent
  ],
  imports: [
    SharedModule,
    EmployeesModule,
    JobTasksModule
  ]
})
export class ReportsModule {

  constructor(service: ReportsService) {

    service.registerReports([
      {
        name: 'Empleado',
        path: ['/reports/employee']
      },
      {
        name: 'Trabajos',
        path: ['/reports/jobs']
      },
      {
        name: 'Diario',
        path: ['/reports/day']
      },
      {
        name: 'Ausencias',
        path: ['/absences/report']
      }
    ]);

  }

}
