<app-spinner *ngIf="working"></app-spinner>

<div class="page-header">

  <div *ngIf="step === 0" class="w-100">
    <div *ngIf="job.task" class="d-flex justify-content-between align-items-center text-truncate">
      <span>Tarea seleccionada: </span>
      <span>{{ job.task.name }}</span>
    </div>

    <span *ngIf="!job.task">
      Seleccione una tarea
    </span>
  </div>

  <div *ngIf="step === 1">
    <span>Fecha y hora de inicio / final</span>
  </div>

  <div *ngIf="step === 2">
    <span>Ubicación</span>
  </div>

  <div *ngIf="step === 3">
    <span>Seleccione los empleados</span>
  </div>

  <div *ngIf="step === 4">
    <span>Resumen</span>
  </div>

</div>

<div class="alert alert-warning mb-0" *ngIf="job.closed">
  El parte ha sido sincronizado en VISUAL y no puede ser editado
</div>

<mat-tab-group class="no-header" [selectedIndex]="step">

  <mat-tab>
    <app-job-task-selector *ngIf="step === 0" [readOnly]="job.closed" [(ngModel)]="job.task"
      (ngModelChange)="markPendingChanges()"></app-job-task-selector>
  </mat-tab>

  <mat-tab>
    <app-job-detail-dates *ngIf="step === 1" [readOnly]="job.closed" [job]="job" (changed)="markPendingChanges()">
    </app-job-detail-dates>
  </mat-tab>

  <mat-tab>
    <app-job-detail-location *ngIf="step === 2" [readOnly]="job.closed" [job]="job" (changed)="markPendingChanges()">
    </app-job-detail-location>
  </mat-tab>

  <mat-tab>
    <app-job-detail-employees *ngIf="step === 3" [readOnly]="job.closed" [job]="job" (changed)="markPendingChanges()">
    </app-job-detail-employees>
  </mat-tab>

  <mat-tab>
    <app-job-detail-finish *ngIf="step === 4" [readOnly]="job.closed" [job]="job" (changed)="markPendingChanges()">
    </app-job-detail-finish>
  </mat-tab>

  <mat-tab>

    <ng-container *ngIf="result">
      <div class="alert alert-danger">
        Problemas encontrados
      </div>

      <div class="container">
        <div class="row mb-2" *ngFor="let problem of result.problems">
          <div class="col">
            <div><strong>{{ problem.employee }}</strong></div>
            <div>{{ problem.message }}</div>

            <mat-checkbox *ngIf="problem.problemType == 2" 
              [checked]="isEmployeeBypass(problem.employeeId)" 
              (change)="bypassChanged(problem.employeeId)">Aceptar de todos modos
            </mat-checkbox>

          </div>
        </div>  
      </div>
    </ng-container>

  </mat-tab>

</mat-tab-group>


<div class="d-flex justify-content-between">
  <button type="button" class="w-100" mat-flat-button color="accent" [disabled]="!canGoBack()" (click)="goBack()">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <button type="button" class="w-100" mat-flat-button color="accent" [disabled]="!canGoNext()" (click)="goNext()">
    <mat-icon>arrow_forward</mat-icon>
  </button>

</div>