import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { IJobDetailDTO } from '@app/core/jobs';
import { IVisualConfigDTO, IVisualPlotDTO, VisualService } from '@app/core/visual';

@Component({
  selector: 'app-job-detail-location',
  templateUrl: './job-detail-location.component.html',
  styleUrls: ['./job-detail-location.component.scss']
})
export class JobDetailLocationComponent implements OnChanges {

  @Input()
  public job: IJobDetailDTO;

  @Input()
  public readOnly: boolean;

  @Output()
  public changed: EventEmitter<any>;

  public mapSource: string;
  public error: string;
  public visualConfig: IVisualConfigDTO;

  constructor(
    private service: VisualService
  ) {
    this.changed = new EventEmitter();
    this.service.getConfiguration().subscribe(value => this.visualConfig = value);
  }

  public ngOnChanges(): void {

    if (!this.job.latitude || !this.job.longitude) {
      if (navigator.geolocation) {
        this.error = '';
        navigator.geolocation.getCurrentPosition(position => {

          this.job.latitude = position.coords.latitude;
          this.job.longitude = position.coords.longitude;
          this.raiseChanged();

        });
      } else {
        this.error = 'Sin acceso a geolocalización';
      }
    }

  }

  public plotSelected($event: IVisualPlotDTO): void {
      this.job.cropUnit = $event?.id;
      this.job.locationName = $event?.name;
      this.raiseChanged();
    

  }

  public mapChanged($event: any): void {
    this.job.latitude = $event.latitude;
    this.job.longitude = $event.longitude;
    this.raiseChanged();
  }

  public raiseChanged(): void {
    this.changed.next(1);
  }


}
