import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { PaymentMethodsService, IPaymentMethodDTO } from '@app/core/subscription';
import { MatDialog } from '@angular/material/dialog';
import { CreditCardComponent } from '../credit-card/credit-card.component';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss']
})
export class PaymentMethodsComponent implements OnInit {

  public loading: boolean;
  public records$: BehaviorSubject<IPaymentMethodDTO[]>;
  public totalRecords: number;

  @Input()
  public autoAdd: boolean;

  @Output()
  public anyDefault: EventEmitter<boolean>;

  constructor(
    private service: PaymentMethodsService,
    private dialog: MatDialog
  ) {
    this.anyDefault = new EventEmitter();
    this.records$ = new BehaviorSubject(null);
  }

  public ngOnInit(): void {
    this.loadData();
  }

  private loadData(): void {
    this.loading = true;

    this.service.reload()
    .pipe(
      switchMap(() => this.service.getList())
    )
    .subscribe(data => {

      this.totalRecords = data.length;
      this.records$.next(data);
      this.loading = false;

      if (data.some(d => d.isDefault)) {
        this.anyDefault.next(true);
      }

      if (!data.length && this.autoAdd) {
        this.addPaymentMethod();
      }

    }, (ex) => {
      this.loading = false;
      throw ex;
    });
  }


  public addPaymentMethod(): void {

    this.dialog.open(CreditCardComponent)
      .afterClosed()
      .subscribe(result => {

        if (result) {
          this.loadData();
        }

      });

  }

}
