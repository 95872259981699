import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SharedModule } from '@app/shared';
import { EmployeesRoutes } from '@app/employees';
import { JobsRoutes } from '@app/jobs';
import { JobTasksRoutes } from '@app/job-tasks';
import { AuthRoutingModule } from '@app/auth';
import { ConfigurationRoutes } from '@app/configuration';
import { OrganisationsRoutes, OrganisationsRoutingModule } from '@app/organisations';
import { UsersRoutes, UsersRoutingModule } from '@app/users';
import { SubscriptionRoutes } from '@app/subscription';
import { ReportsRoutes } from '@app/reports';
import { VisualRoutes } from '@app/visual';

import { AuthGuard } from './core/auth';
import { SubscriptionGuard } from './core/subscription';

import { MainComponent } from './layout/main/main.component';
import { PageNotFoundComponent } from './layout/page-not-found/page-not-found.component';

const routes: Routes = [

  {
    path: '',
    component: MainComponent,
    canActivate: [
      AuthGuard
    ],
    canActivateChild: [
      AuthGuard,
      SubscriptionGuard
    ],
    children: [
      ...JobsRoutes,
      ...EmployeesRoutes,
      ...JobTasksRoutes,
      ...ConfigurationRoutes,
      ...OrganisationsRoutes,
      ...UsersRoutes,
      ...SubscriptionRoutes,
      ...ReportsRoutes,
      ...VisualRoutes,
      {
        path: 'absences', loadChildren: () => import('./absences/absences.module').then(m => m.AbsencesModule)
      },
      {
        path: 'absence-reasons', loadChildren: () => import('./absence-reasons/absence-reasons.module').then(m => m.AbsenceReasonsModule)
      },
      {
        path: 'active-presence', loadChildren: () => import('./active-presence/active-presence.module').then(m => m.ActivePresenceModule)
      },
      {
        path: 'work-centers', loadChildren: () => import('./work-centers/work-centers.module').then(m => m.WorkCentersModule)
      },
    ]
  },
  {
    path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },
  { path: '404', component: PageNotFoundComponent },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [
    SharedModule,
    AuthRoutingModule,
    OrganisationsRoutingModule,
    UsersRoutingModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ]
})
export class AppRoutingModule { }
