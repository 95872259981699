import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { of } from 'rxjs';

import { ConfigService } from '@app/core';

const EXTENT = [-Math.PI * 6378137, Math.PI * 6378137];


@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements AfterViewInit, OnChanges, OnDestroy {

  static initialized: boolean;

  @Input()
  public organisationId: number;

  @Input()
  public latitude: number;

  @Input()
  public longitude: number;

  @Input()
  public readOnly: boolean;

  @Output()
  public changed: EventEmitter<{ latitude: number, longitude: number }>;

  private listener: any;
  private map: google.maps.Map;
  private marker: google.maps.Marker;

  constructor(
    private httpClient: HttpClient,
    private config: ConfigService
  ) {
    this.changed = new EventEmitter();
  }

  public ngAfterViewInit(): void {

    setTimeout(() => {

      const op = !MapComponent.initialized ?
        this.httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key=' + this.config.getConfig().googleMapsApiKey, 'callback') :
        of(null);


      op.subscribe(() => {

        MapComponent.initialized = true;

        this.map = new google.maps.Map(document.getElementById('map'), {
          zoom: 18,
          center: { lat: this.latitude, lng: this.longitude },
        });

        this.ensureOverlay();
        this.ensureMarker();

      });


    }, 200);

  }

  private ensureOverlay(): void {

    if (this.organisationId && this.map) {

      const landcover = new google.maps.ImageMapType({
        getTileUrl: this.getTileUrl.bind(this),
        name: 'Visual',
        minZoom: 0,
        maxZoom: 19,
        opacity: 0.6
      } as any);

      this.map.overlayMapTypes.push(landcover);
    }

  }

  private ensureMarker(): void {

    if (this.map && this.latitude && this.longitude) {

      const position = { lat: this.latitude, lng: this.longitude };

      if (!this.marker) {
        this.marker = new google.maps.Marker({
          position,
          map: this.map,
          title: 'Posición',
          draggable: this.readOnly ? false : true,
        });

        this.marker.addListener('dragend', () => {

          if (!this.readOnly) {
            const coords = this.marker.getPosition();
            this.changed.next({ latitude: coords.lat(), longitude: coords.lng() });
          }

        });

      } else {
        this.marker.setPosition(position);
      }
    }

  }

  public ngOnChanges(): void {
    this.ensureOverlay();
    this.ensureMarker();
  }

  private getTileUrl(coordinates, zoom): any {

    if (!this.organisationId) {
      return null;
    }

    return (
      this.config.getConfig().mapServer + '/cgi-bin/mapserv?map=/ms4w/apps/visual3/map/vn-3.0-selphi.map' +
      '&service=WMS&request=GetMap&layers=selphi&styles=&format=image%2Fpng&transparent=false&version=1.1.1' +
      '&id_organizacion=' + this.organisationId + '&width=256&height=256&srs=EPSG%3A3857' +
      '&bbox=' +
      this.xyzToBounds(coordinates.x, coordinates.y, zoom).join(',')
    );
  }

  private xyzToBounds(x, y, z): any {
    const tileSize = EXTENT[1] * 2 / Math.pow(2, z);
    const minx = EXTENT[0] + x * tileSize;
    const maxx = EXTENT[0] + (x + 1) * tileSize;
    const miny = EXTENT[1] - (y + 1) * tileSize;
    const maxy = EXTENT[1] - y * tileSize;
    return [minx, miny, maxx, maxy];
  }


  public ngOnDestroy(): void {
    if (this.listener) {
      this.listener.remove();
      delete this.listener;
    }
  }

}
