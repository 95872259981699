import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { RequestService } from '../_common';
import { IChunkedListDTO, ISimpleDTO, ISimpleExDTO } from '../defs';
import { IConfirmRegisterUserRequest, ICreateUserRequest, IForgotPasswordRequest, IGetUserListRequest, IUserDTO } from './users.defs';
import { AuthService, UserRole } from '../auth';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private requestService: RequestService,
    private auth: AuthService,
  ) { }

  public getUserList(request?: IGetUserListRequest): Observable<IChunkedListDTO<IUserDTO>> {
    return this.requestService.get('api/users', request);
  }

  public createUser(request: ICreateUserRequest): Observable<any> {
    return this.requestService.post('api/users', request);
  }

  public confirmRegister(request: IConfirmRegisterUserRequest): Observable<any> {
    return this.requestService.post('api/public/users/confirm', request);
  }

  public resetPassword(request: IForgotPasswordRequest): Observable<any> {
    return this.requestService.post('/api/public/users/forgot-password', request);
  }

  public confirmResetPassword(request: IForgotPasswordRequest): Observable<any> {
    return this.requestService.post('/api/public/users/confirm-reset', request);
  }

  public getAllowedRolesForCreating(): Observable<ISimpleDTO[]> {

    if (this.auth.isRole(UserRole.Coordinator)) {
      return of([
        {
          id: UserRole.Regular,
          name: 'Responsable de personal'
        }
      ]);
    }

    return of([
      {
        id: UserRole.Admin,
        name: 'Administrador'
      },
      {
        id: UserRole.Coordinator,
        name: 'Coordinador'
      },
      {
        id: UserRole.Regular,
        name: 'Responsable de personal'
      }
    ]);
  }

}
