import { Routes } from '@angular/router';

import { SyncJobComponent } from './sync-job/sync-job.component';

export const VisualRoutes: Routes = [
  {
    path: 'visual/sync-job/:jobId',
    component: SyncJobComponent
  }
];
