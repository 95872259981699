import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { ISimpleExDTO, Utils } from '@app/core';
import { IJobDetailDTO, IJobEmployeeDTO } from '@app/core/jobs';
import { MessageBoxService } from '@app/shared';

@Component({
  selector: 'app-job-detail-employees',
  templateUrl: './job-detail-employees.component.html',
  styleUrls: ['./job-detail-employees.component.scss']
})
export class JobDetailEmployeesComponent implements OnChanges {

  @Input()
  public job: IJobDetailDTO;

  @Input()
  public readOnly: boolean;

  @Output()
  public changed: EventEmitter<any>;

  public startTimes: Date[];
  public endTimes: Date[];

  constructor(
    private msgbox: MessageBoxService,
  ) {
    this.changed = new EventEmitter();
  }

  public ngOnChanges(): void {
    this.startTimes = this.job.employees.map(j => Utils.convertTimeStringToDate(new Date(), j.startTime));
    this.endTimes = this.job.employees.map(j => Utils.convertTimeStringToDate(new Date(), j.endTime));
  }

  public calcEmployeeTime(jobEmployee: IJobEmployeeDTO, idx: number): void {

    const startTime = Utils.convertDateToTime24(this.startTimes[idx]);
    const endTime = Utils.convertDateToTime24(this.endTimes[idx]);

    if (startTime === jobEmployee.startTime && endTime === jobEmployee.endTime) {
      return;
    }

    jobEmployee.startTime = startTime;
    jobEmployee.endTime = endTime;

    if (!jobEmployee.startTime || !jobEmployee.endTime) {
      delete jobEmployee.totalHours;
      delete jobEmployee.totalMinutes;
      return;
    }

    const timeDiff = Utils.getTimeDiff(jobEmployee.startTime, jobEmployee.endTime);
    jobEmployee.totalHours = timeDiff.hours;
    jobEmployee.totalMinutes = timeDiff.minutes;

    this.raiseChanged();
  }

  public raiseChanged(): void {
    this.changed.next(1);
  }

  public addEmployee(employees: ISimpleExDTO[]): void {

    if (this.job.employees.some(e => employees.some(ee => ee.uid === e.employee.uid))) {
      this.msgbox.showError('El empleado ya está en la lista');
      return;
    }

    employees.forEach(employee => {

      const jobEmployee = {
        employee,
        startTime: this.job.startTime,
        endTime: this.job.endTime,
      };
  
      this.job.employees.push(jobEmployee);
      this.startTimes.push(Utils.convertTimeStringToDate(this.job.startDate, this.job.startTime));
      this.endTimes.push(Utils.convertTimeStringToDate(this.job.startDate, this.job.endTime));

    });

  }

  public removeEmployee(employee: IJobEmployeeDTO): void {

    const idx = this.job.employees.indexOf(employee);
    this.job.employees.splice(idx, 1);
    this.startTimes.splice(idx, 1);
    this.endTimes.splice(idx, 1);
    this.raiseChanged();
  }

  public ratingChanged($event: number, employee: IJobEmployeeDTO): void {
    employee.rating = $event;
    this.raiseChanged();
  }

}
