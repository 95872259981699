import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { RequestService } from '../_common';
import { ISimpleExDTO } from '../defs';


@Injectable({
  providedIn: 'root'
})
export class JobTasksService {

  constructor(private requestService: RequestService) {
  }

  public getJobTaskById(uid: string): Observable<ISimpleExDTO> {
    return this.requestService.get(`api/job-tasks/${uid}`);
  }

  public getJobTaskList(): Observable<ISimpleExDTO[]> {
    return this.requestService.get('api/job-tasks');
  }

  public createJobTask(request: ISimpleExDTO): Observable<ISimpleExDTO> {
    return this.requestService.post('api/job-tasks', request);
  }

  public updateJobTask(uid: string, request: ISimpleExDTO): Observable<ISimpleExDTO> {
    return this.requestService.put(`api/job-tasks/${uid}`, request);
  }

  public deleteJobTask(uid: string): Observable<ISimpleExDTO> {
    return this.requestService.delete(`api/job-tasks/${uid}`);
  }

}
