<app-spinner *ngIf="working"></app-spinner>


<div class="page-header">

  <div *ngIf="step === 1">
    <span>
      Introduzca sus datos de facturación
    </span>
  </div>

  <div *ngIf="step === 2">
    <span>
      Establezca el método de pago
    </span>
  </div>

</div>


<mat-tab-group class="no-header" [selectedIndex]="step">

  <mat-tab>
    <app-subscription-disclaimer *ngIf="step === 0" (accepted)="disclaimerAccepted($event)">
    </app-subscription-disclaimer>

    <div class="text-center">
      <button type="button" class="w-50" mat-flat-button color="primary" [disabled]="!canGoNext" (click)="goNext()">
        Siguiente
      </button>
    </div>

  </mat-tab>

  <mat-tab>
    <app-legal-info *ngIf="step === 1" (completed)="legalInfoCompleted($event)"></app-legal-info>
  </mat-tab>

  <mat-tab>
    <app-payment-methods *ngIf="step === 2" [autoAdd]="true" (anyDefault)="paymentMethodReady()"></app-payment-methods>
  </mat-tab>

</mat-tab-group>