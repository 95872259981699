import { Component, forwardRef, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';

import { ISimpleDTO, ISimpleExDTO, SyncResult } from '@app/core';
import { AbsenceReasonsService } from '@app/core/absence-reasons';



@Component({
  selector: 'sync-result',
  templateUrl: './sync-result.component.html',
  styleUrls: ['./sync-result.component.scss']
})
export class SyncResultModalComponent implements OnInit {

  public records: SyncResult[];
  public multi: boolean;

  get syncOk() : number{
    return this.records.filter( r => r.success == true).length;
  }

  get failedRecords() : SyncResult[]{
    return this.records.filter( r => r.success == false);
  }
  
  get syncKo() : number{
    return this.records.filter( r => r.success == false).length;
  }

  constructor(
    private dialogRef: MatDialogRef<SyncResultModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: SyncResult[]
  ) {
    this.records = data;
  }

  public ngOnInit(): void {}

  public close(): void {
    this.dialogRef.close();
  }
}
