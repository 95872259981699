<div mat-dialog-content class="data-container">

  <div class="p-2">
    <h3 *ngIf="selectionMode === 'single'">Seleccione empleado</h3>
    <h3 *ngIf="selectionMode !== 'single'">Seleccione empleados</h3>
  </div>

  <app-input-search (changed)="onSearchText($event)"></app-input-search>

  <app-employee-selector [(ngModel)]="selectedEmployees" [searchText]="searchText" [selectionMode]="selectionMode"></app-employee-selector>

</div>

<mat-dialog-actions>
  <button mat-flat-button (click)="no()">Cancelar</button>
  <button mat-flat-button (click)="clearSelection()">Ninguno</button>
  <button mat-flat-button color="primary" (click)="yes()">Seleccionar</button>
</mat-dialog-actions>
