import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { MessageBoxService } from '@app/shared';
import { ICanSaveChanges } from '../defs';


@Injectable({ providedIn: 'root' })
export class SaveChangesGuard implements CanDeactivate<ICanSaveChanges> {

  constructor(
    private msgbox: MessageBoxService
  ) {
  }

  public canDeactivate(target: ICanSaveChanges): Observable<any> {

    if (target.hasChanges) {
      return this.msgbox
      .showYesNo({ title: '¿Guardar cambios?' })
      .pipe(
        switchMap(val => {

          if (val) {
            return target.saveChanges();
          }

          return of(true);

        })
      );
    }

    return of(true);
  }

}
