import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html'
})
export class MessageBoxComponent {

  constructor(
    public dialogRef: MatDialogRef<MessageBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    if (this.data.timeOut) {
      setTimeout(() => this.ok(), this.data.timeOut);
    }

  }

  
  public yes(): void {
    this.dialogRef.close({});
  }

  public no(): void {
    this.dialogRef.close();
  }

  public ok(): void {
    this.dialogRef.close();
  }

}
