import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MessageBoxService } from '@app/shared';
import { JobsService, IJobDetailDTO, ICreateUpdateJobRequest, ICreateUpdateJobResult } from '@app/core/jobs';
import { LayoutService } from '@app/core/layout';
import { ICanSaveChanges, Utils } from '@app/core';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { IVisualPlotDTO } from '@app/core/visual';

enum Steps {
  Task = 0,
  Dates = 1,
  Location = 2,
  Employees = 3,
  Finish = 4
}

@Component({
  selector: 'app-job-detail',
  templateUrl: './job-detail.component.html',
  styleUrls: ['./job-detail.component.scss'],
})
export class JobDetailComponent implements OnInit, ICanSaveChanges {

  public job: IJobDetailDTO;
  public step: number;
  public hasChanges: boolean;
  public working: boolean;

  public result: ICreateUpdateJobResult;

  private hasEndTime: boolean;
  private employeesBypass: string[];
  private iVisualPlotDTO: IVisualPlotDTO;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private msgbox: MessageBoxService,
    private service: JobsService,
    private layout: LayoutService
  ) {
    this.step = Steps.Task;
    this.hasEndTime = false;
    this.employeesBypass = [];
    this.iVisualPlotDTO = null;
  }

  public ngOnInit(): void {

    this.job = (this.route.snapshot.data.job || {
      startDate: new Date(),
      startTime: Utils.convertDateToTime24(new Date()),
      employees: []
    }) as IJobDetailDTO;

    this.hasEndTime = this.job.endTime ? true : false;

    this.layout.setToolbar({
      title: this.job.task?.name || 'Nuevo trabajo',
      showMenuButton: false,
      leftButtons: [
        {
          action: () => this.router.navigate(['jobs']),
          icon: 'arrow_back',
        }
      ],
      rightButtons: this.job.closed ? [] : [
        {
          action: () => this.saveChangesManually(),
          text: 'Guardar'
        }
      ]
    });
  }

  public markPendingChanges(): void {
    this.hasChanges = true;

    if (!this.hasEndTime && this.job.employees.length) {
      const anyEmp = this.job.employees.find(e => e.endTime);
      this.job.endTime = anyEmp?.endTime;
    }

  }

  public canGoBack(): boolean {
    return this.step > Steps.Task;
  }

  public canGoNext(): boolean {

    if (this.step === Steps.Task) {
      return this.job.task != null;
    }

    return this.step < Steps.Finish;
  }

  public goBack(): void {
    this.step--;
  }

  public goNext(): void {
    this.step++;
  }

  private saveChangesManually(): void {

    this.saveChanges().subscribe(success => {

      if (success) {
        this.router.navigate(['visual/sync-job', this.job.uid]);
      }

    });

  }

  public saveChanges(): Observable<boolean> {

    if (!this.job.task) {
      this.msgbox.showError('Seleccione una tarea');
      return of(false);
    }

    this.working = true;
    console.log(this.job)
    const request: ICreateUpdateJobRequest = {
      ...this.job,
      employees: this.job.employees.map(j => ({ ...j, employeeId: j.employee.uid })),
      taskId: this.job.task.uid,
      employeesBypass: this.employeesBypass
    };

    this.hasEndTime = this.job.endTime ? true : false;

    const operation = !this.job.uid
      ? this.service.createJob(request)
      : this.service.updateJob(this.job.uid, request);

    return operation
    .pipe(
      switchMap((result) => {

        if (!result.success) {
          this.working = false;
          this.result = result;
          this.step = 5;
          return of(false);
        } else {

          this.hasChanges = false;
          this.msgbox.showSuccess('Cambios guardados');

          if (!this.job.uid) {
            this.job.uid = result.jobId;
          }
  
          return of(true);
  
        }

      }),
      catchError(err => {
        this.working = false;
        throw(err);
      })
    );

  }

  public isEmployeeBypass(employeeId: string): boolean {
    return this.employeesBypass.indexOf(employeeId) > -1;
  }

  public bypassChanged(employeeId: string): void {
    const idx = this.employeesBypass.indexOf(employeeId);
    if (idx > -1) {
      this.employeesBypass.splice(idx, 1);
    } else {
      this.employeesBypass.push(employeeId);
    }
  }

}
