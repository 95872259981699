import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { IUserDTO, UsersService } from '@app/core/users';
import { LayoutService } from '@app/core/layout';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {

  public users$: BehaviorSubject<IUserDTO[]>;
  public totalRecords: number;
  public loading: boolean;

  constructor(
    private service: UsersService,
    private layout: LayoutService
  ) {

    this.loading = false;
    this.users$ = new BehaviorSubject(null);

    this.layout.setToolbar({
      showMenuButton: true,
      title: 'Usuarios'
    });

  }

  public ngOnInit(): void {
    this.loadData();
  }

  private loadData(): void {

    this.loading = true;
    this.service.getUserList().subscribe(data => {
      this.users$.next(data.records);
      this.totalRecords = data.totalRecords;
      this.loading = false;
    }, (ex) => {
      this.loading = false;
      throw ex;
    });
  }

}
