import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService, ILoginRequest } from '@app/core/auth';
import { IErrorResponse } from '@app/core';
import { CustomErrorStateMatcher } from '@app/shared';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  @ViewChild('formLogin', { static: true })
  public formLogin: NgForm;

  public request: ILoginRequest;
  public working: boolean;
  public error: string;
  public showPass: boolean;
  public matcher: CustomErrorStateMatcher;

  constructor(
    private authService: AuthService,
    private router: Router
  ) {

    this.matcher = new CustomErrorStateMatcher();

    const session = this.authService.getSession();
    this.request = {
      remember: session ? true : false
    } as any;
  }

  public shouldDisableButton(): boolean {
    return !this.formLogin.form.valid || this.working;
  }

  public doLogin(): void {

    if (!this.formLogin.valid) {
      return;
    }

    this.working = true;
    this.error = '';

    this.authService
      .login(this.request)
      .pipe(
        catchError((response: IErrorResponse) => {
          // catch the error to avoid global error handler catching it
          return of(response);
        })
      )
      .subscribe(response => {

        const resp = response as IErrorResponse;
        if (resp.error) {
          this.error = resp.status === 400 ? 'Datos de acceso no válidos' : 'Error de servidor';
          this.working = false;
        } else {
          this.router.navigateByUrl('/');
        }

      });

  }

}
