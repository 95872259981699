import { NgModule } from '@angular/core';

import { VisualModule } from '@app/visual';
import { EmployeesModule } from '@app/employees';
import { JobTasksModule } from '@app/job-tasks';
import { SharedModule } from '@app/shared';

import { JobsListComponent } from './list/jobs-list.component';
import { JobDetailComponent } from './detail/job-detail.component';
import { JobDetailDatesComponent } from './job-detail-dates/job-detail-dates.component';
import { JobDetailFinishComponent } from './job-detail-finish/job-detail-finish.component';
import { JobDetailLocationComponent } from './job-detail-location/job-detail-location.component';
import { JobDetailEmployeesComponent } from './job-detail-employees/job-detail-employees.component';
import { SyncResultModalComponent } from './sync-result/sync-result';

@NgModule({
  declarations: [
    JobsListComponent,
    JobDetailComponent,
    SyncResultModalComponent,
    JobDetailDatesComponent,
    JobDetailFinishComponent,
    JobDetailLocationComponent,
    JobDetailEmployeesComponent
  ],
  imports: [
    SharedModule,
    VisualModule,
    JobTasksModule,
    EmployeesModule
  ]
})
export class JobsModule {

}
