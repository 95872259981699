import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { Utils } from '@app/core';
import { IJobDetailDTO } from '@app/core/jobs';
import { MessageBoxService } from '@app/shared';

import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-job-detail-dates',
  templateUrl: './job-detail-dates.component.html',
  styleUrls: ['./job-detail-dates.component.scss'],
  providers: [NgModel]
})

export class JobDetailDatesComponent implements OnChanges{

  @Input()
  public job: IJobDetailDTO;

  @Input()
  public readOnly: boolean;

  @Output()
  public changed: EventEmitter<any>;

  public startTime: Date;
  public endTime: Date;

  constructor(private msgbox: MessageBoxService,  private ngModel: NgModel){
    this.changed = new EventEmitter();
  }

  public ngOnChanges(): void {

    if (this.job.startTime == null) {
      this.job.startTime = '';
    }

    if (this.job.endTime == null) {
      this.job.endTime = '';
    }

    this.startTime = Utils.convertTimeStringToDate(this.job.startDate, this.job.startTime);
    this.endTime = Utils.convertTimeStringToDate(this.job.startDate, this.job.endTime);

  }
  
  public startTimeChanged(): void {
    const originalStartTime = this.job.startTime;
    const startTime = Utils.convertDateToTime24(this.startTime);
   
   if (this.job.startTime !== startTime) {
        if(!this.job.endTime){
          this.job.startTime = startTime;
          this.raiseChanged();
        }
        else if (this.job.endTime && this.isEndTimeValid(startTime, this.job.endTime)) {
            this.job.startTime = startTime;
            this.raiseChanged();
        } else {
            this.job.startTime = originalStartTime;
            this.startTime = Utils.convertTimeStringToDate(this.job.startDate, this.job.startTime);
            this.msgbox.showError('Hora de inicio no valida y/o debe ser menor a la hora final')
        }
    }
    this.job.startTime = startTime;
    this.raiseChanged();
  }

  public endTimeChanged(): void {
      const originalEndTime = this.job.endTime;
      const endTime = Utils.convertDateToTime24(this.endTime);
    
      if (this.job.endTime !== endTime) {
          if (this.isEndTimeValid(this.job.startTime, endTime)) {
              this.job.endTime = endTime;
              this.job.employees.forEach(e => e.endTime = this.job.endTime);
              this.raiseChanged();
          } else {
              this.job.endTime = originalEndTime
              this.endTime = Utils.convertTimeStringToDate(this.job.startDate, this.job.endTime)
              this.msgbox.showError('Hora final no valida y/o debe ser mayor a la fecha de inicio')
              
          }
      }
  }

  private isEndTimeValid(startTime: string, endTime: string): boolean {
    const numericStartTime = startTime.match(/[0-9]/g)?.join("") || "";
    const numericEndTime = endTime ? endTime.match(/[0-9]/g)?.join("") || "":"";
    
    if (startTime == numericStartTime || endTime == numericEndTime) {
        return false;
    }
    
    const startDate = Utils.convertTimeStringToDate(this.job.startDate, startTime);
    const endDate = endTime ? Utils.convertTimeStringToDate(this.job.startDate, endTime): null;
    
    if (startDate < endDate) {
        return true;
    }

    return false;
}
  
  public raiseChanged(): void {
    this.changed.next(1);
  }

}
