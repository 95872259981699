import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { RequestService } from '../_common';
import { IVisualConfigDTO } from './defs';

@Injectable({
  providedIn: 'root'
})
export class VisualAdminService {

  constructor(
    private request: RequestService
  ) { }

  public getConfiguration(organisationId: string): Observable<IVisualConfigDTO> {
    return this.request
      .get('/api/admin/visual/configuration/' + organisationId)
      .pipe(
        catchError(response => {

          if (response.status === 404) {
            return of(null as any);
          }

          return throwError(response);

        })
      );
  }

  public setConfiguration(request: IVisualConfigDTO): Observable<any> {
    return this.request.post('/api/admin/visual/configuration', request);
  }
}
