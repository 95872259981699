import { Component, OnInit } from '@angular/core';

import { LayoutService } from '@app/core/layout';
import { KnownSettings, OrganisationSettingsService } from '@app/core/organisation-settings';

@Component({
  selector: 'app-configuration-container',
  templateUrl: './configuration-container.component.html',
  styleUrls: ['./configuration-container.component.scss']
})
export class ConfigurationContainerComponent {

  public showWorkCenters: boolean;

  constructor(
    private layout: LayoutService,
    private settingsService: OrganisationSettingsService
  ) {

    this.layout.setToolbar({
      showMenuButton: true,
      title: 'Configuración'
    });

    this.settingsService.getSettings()
    .subscribe(settings => {
      this.showWorkCenters = settings.some(s => s.key === KnownSettings.FACE_TO_FACE && s.value === 'true');
    });

  }

}
