import { Routes } from '@angular/router';

import { DayReportComponent } from './day-report/day-report.component';
import { EmployeeReportComponent } from './employee-report/employee-report.component';
import { JobReportComponent } from './job-report/job-report.component';
import { ReportsContainerComponent } from './container/reports-container.component';

export const ReportsRoutes: Routes = [
  {
    path: 'reports',
    component: ReportsContainerComponent,
    children: [
      {
        path: 'employee',
        component: EmployeeReportComponent
      },
      {
        path: 'jobs',
        component: JobReportComponent
      },
      {
        path: 'day',
        component: DayReportComponent
      }
    ]
  }
];
