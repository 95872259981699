import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ISimpleExDTO } from '@app/core';

@Component({
  selector: 'app-employee-selector-popup',
  templateUrl: './employee-selector-popup.component.html',
  styleUrls: ['./employee-selector-popup.component.scss']
})
export class EmployeeSelectorPopupComponent  {

  public selectedEmployees: ISimpleExDTO[];
  public searchText: string;
  public selectionMode: string;

  constructor(
    private dialogRef: MatDialogRef<EmployeeSelectorPopupComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.selectedEmployees = data?.selectedEmployees || [];
    this.selectionMode = data?.selectionMode;
  }

  public onSearchText($event: string): void {
    this.searchText = $event;
  }

  public no(): void {
    this.dialogRef.close();
  }

  public yes(): void {
    this.dialogRef.close(this.selectedEmployees);
  }

  public clearSelection(): void {
    this.selectedEmployees.length = 0;
    this.yes();
  }

}
