import { Routes } from '@angular/router';

import { JobTaskResolver } from '@app/core/job-tasks';

import { JobTaskDetailComponent } from './detail/job-task-detail.component';
import { JobTaskListComponent } from './list/job-task-list.component';

export const JobTasksRoutes: Routes = [
  {
    path: 'job-tasks',
    component: JobTaskListComponent
  },
  {
    path: 'job-tasks/new',
    component: JobTaskDetailComponent
  },
  {
    path: 'job-tasks/:id',
    component: JobTaskDetailComponent,
    resolve: {
      jobTask: JobTaskResolver
    }
  },

];
