import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ISubscriptionDTO, SubscriptionService } from '@app/core/subscription';
import { LayoutService } from '@app/core/layout';
import { Router } from '@angular/router';

@Component({
  selector: 'app-subscription-status',
  templateUrl: './subscription-status.component.html',
  styleUrls: ['./subscription-status.component.scss']
})
export class SubscriptionStatusComponent implements OnInit {

  public status$: BehaviorSubject<ISubscriptionDTO>;

  constructor(
    private router: Router,
    private layout: LayoutService,
    private service: SubscriptionService
  ) {
    this.status$ = new BehaviorSubject(null);
  }

  public ngOnInit(): void {

    this.layout.setToolbar({
      title: 'Subscripción',
      showMenuButton: false,
      leftButtons: [
        {
          action: () => this.router.navigate(['configuration']),
          icon: 'arrow_back',
        }
      ]
    });

    this.service.getSubscriptionStatus()
      .subscribe(status => {
        this.status$.next(status);
      });

  }

}
