import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { RequestService } from '../_common';

import { IChunkedListDTO } from '../defs';
import { IEmployeeDetailDTO, IEmployeeListDTO, IEmployeeMatchDTO, IGetEmployeeListRequest, IGetEmployeeMatchRequest } from './employees.defs';


@Injectable({
  providedIn: 'root'
})
export class EmployeesService {

  constructor(private requestService: RequestService) {
  }

  public getEmployeeById(uid: string): Observable<IEmployeeDetailDTO> {
    return this.requestService.get(`api/employees/${uid}`);
  }

  public getEmployeeList(request?: IGetEmployeeListRequest): Observable<IChunkedListDTO<IEmployeeListDTO>> {
    return this.requestService.get('api/employees', request);
  }

  public getEmployeeMatch(request: IGetEmployeeMatchRequest): Observable<IEmployeeMatchDTO> {
    return this.requestService.post('api/employees/match', request);
  }

  public createEmployee(request: IEmployeeDetailDTO): Observable<IEmployeeDetailDTO> {
    return this.requestService.post('api/employees', request);
  }

  public updateEmployee(request: IEmployeeDetailDTO): Observable<IEmployeeDetailDTO> {
    return this.requestService.put(`api/employees/${request.uid}`, request);
  }

  public deleteEmployee(uid: string): Observable<IEmployeeDetailDTO> {
    return this.requestService.delete(`api/employees/${uid}`);
  }

}
