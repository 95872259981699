import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

export enum RatingValue {
  No,
  Neutral,
  Yes
}

interface IRatingChoice {
  image: string;
  imageFilled: string;
  value: RatingValue;
}

const ratingChoices: IRatingChoice[] = [
  {
    image: '/assets/img/ratings/no.svg',
    imageFilled: '/assets/img/ratings/no_filled.svg',
    value: RatingValue.No
  },
  {
    image: '/assets/img/ratings/neutral.svg',
    imageFilled: '/assets/img/ratings/neutral_filled.svg',
    value: RatingValue.Neutral
  },
  {
    image: '/assets/img/ratings/yes.svg',
    imageFilled: '/assets/img/ratings/yes_filled.svg',
    value: RatingValue.Yes
  }
];

@Component({
  selector: 'app-rating-selector',
  templateUrl: './rating-selector.component.html',
  styleUrls: ['./rating-selector.component.scss']
})
export class RatingSelectorComponent implements OnChanges {

  @Input()
  public value: RatingValue;

  @Input()
  public readOnly: boolean;

  @Output()
  public valueChanged: EventEmitter<RatingValue>;


  public choices: IRatingChoice[];
  public selectedChoice: IRatingChoice;

  constructor() {
    this.choices = ratingChoices;
    this.valueChanged = new EventEmitter();
  }

  public ngOnChanges(): void {

    if (this.value) {
      this.selectedChoice = this.choices.find(c => c.value === this.value);
    } else {
      delete this.selectedChoice;
    }

  }

  public selectChoice(choice: IRatingChoice): void {
    if (choice === this.selectedChoice) {
      delete this.selectedChoice;
      this.valueChanged.next(null);
    } else {
      this.selectedChoice = choice;
      this.valueChanged.next(choice.value);
    }
  }

}
