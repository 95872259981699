import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { IJobDetailDTO } from '@app/core/jobs';

@Component({
  selector: 'app-job-detail-finish',
  templateUrl: './job-detail-finish.component.html',
  styleUrls: ['./job-detail-finish.component.scss']
})
export class JobDetailFinishComponent implements OnChanges {

  @Input()
  public job: IJobDetailDTO;

  @Input()
  public readOnly: boolean;

  public isCompleted: boolean;

  constructor() { }

  public ngOnChanges(): void {
    this.isCompleted = this.job.task && this.job.startDate && this.job.startTime && this.job.endTime && this.job.employees.length > 0;
  }

}
