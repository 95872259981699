import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { IJobDetailDTO, IJobListDTO } from '../jobs';
import { RequestService } from '../_common';
import { IGetPlotListRequest, IVisualConfigDTO, IVisualPlotDTO } from './defs';

@Injectable({
  providedIn: 'root'
})
export class VisualService {

  constructor(
    private request: RequestService
  ) { }

  public getConfiguration(): Observable<IVisualConfigDTO> {
    return this.request
      .get('/api/visual/configuration')
      .pipe(
        catchError(err => {

          if (err.status === 404) {
            return of(null as any);
          }

          return throwError(() => err);
        })
      )
      ;
  }

  public getPlotList(request: IGetPlotListRequest): Observable<IVisualPlotDTO[]> {
    return this.request.get('/api/visual/plots', request);
  }

  public canSyncJob(job: IJobDetailDTO): boolean {
    return job.cropUnit && !job.closed && job.employees.filter(e => e.startTime && e.endTime).length > 0;
  }

  public syncJob(id: string): Observable<any> {
    return this.request.post('/api/visual/sync-job', { jobId: id });
  }

  public syncMultipleJobs(jobIdList: string[]): Observable<any>{
    return this.request.post('api/visual/sync-jobs', {jobIdList});
  }
}
