import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LayoutService } from '@app/core/layout';
import { SubscriptionService, SubscriptionType } from '@app/core/subscription';
import { catchError } from 'rxjs/operators';

enum Steps {
  LegalInfo = 1,
  PaymentMethods = 2,
  Finish = 3
}


@Component({
  selector: 'app-enable-subscription',
  templateUrl: './enable-subscription.component.html',
  styleUrls: ['./enable-subscription.component.scss']
})
export class EnableSubscriptionComponent implements OnInit {

  public step: number;
  public working: boolean;

  public canGoBack: boolean;
  public canGoNext: boolean;

  constructor(
    private service: SubscriptionService,
    private router: Router,
    private layout: LayoutService
  ) {
    this.step = Steps.LegalInfo;
  }

  public ngOnInit(): void {

    this.layout.setToolbar({
      title: 'Activar subscripción',
      showMenuButton: false,
      leftButtons: [
        {
          action: () => this.router.navigate(['subscription/status']),
          icon: 'arrow_back',
        }
      ]
    });

  }

  public goNext(): void {

    this.step++;

    if (this.step === Steps.Finish) {
      this.working = true;
      this.service.enable({
        type: SubscriptionType.Employee
      })
      .pipe(catchError(err => {
        this.working = false;
        throw(err);
      }))
      .subscribe(() => {
        this.router.navigate(['subscription/created']);
      });

    }

  }

  public disclaimerAccepted(value: boolean): void {
    this.canGoNext = value;
  }

  public legalInfoCompleted($event): void {
    this.canGoNext = true;

    // event notifies that user has updated the information, go next automatically
    if ($event === true) {
      this.goNext();
    }

  }

  public paymentMethodReady(): void {
    this.goNext();
  }

}
