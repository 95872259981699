<div *ngIf="!isCompleted; else completedBlock" class="alert alert-warning">
  No ha completado todos los datos, pero puede guardar los cambios y continuar después.
</div>

<ng-template #completedBlock>
  <div class="alert alert-success m-0">
    Todos los datos requeridos completados
  </div>
</ng-template>

<div class="alert alert-info m-0" *ngIf="!readOnly">
  Haga click en el icono en la esquina superior derecha para guardar.
</div>

<div class="p-2">

  <h4>Tarea: {{ job.task?.name }}</h4>
  <h4>Fecha: {{ job.startDate | date: 'dd/MM/yy'}}</h4>
  <h4 [class.text-danger]="!job.startTime">Hora inicio: {{ job.startTime }}</h4>
  <h4 [class.text-danger]="!job.endTime">Hora final: {{ job.endTime }}</h4>

  <h4 *ngIf="!job.employees.length" class="text-danger">No hay empleados añadidos</h4>

  <div *ngFor="let jobEmployee of job.employees">
    <div class="d-flex">
      <div class="text-truncate mr-2 flex-fill">{{ jobEmployee.employee.name }}</div>
      <div class="mr-2">{{ jobEmployee.startTime }}</div>
      <div>{{ jobEmployee.endTime }}</div>
    </div>
  </div>

</div>