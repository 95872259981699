export interface IOrganisationSettingDTO {
  family: string;
  key: string;
  type: string;
  value: string;
}

export interface ISetOrganisationSettingsRequest {
  organisationId: string;
  settings: { key: string, value: string}[];
}

export enum KnownSettings {
  NO_WORK_IF_ABSENCE = 'NO_WORK_IF_ABSENCE',
  NO_WORK_IF_TIME_OVERLAP = 'NO_WORK_IF_TIME_OVERLAP',
  NOTICE_OPEN_WORK = 'NOTICE_OPEN_WORK',
  MANUAL_REGISTRATION = 'MANUAL_REGISTRATION',
  FACE_TO_FACE = 'FACE_TO_FACE'
}