<app-spinner *ngIf="loading; else taskList">
</app-spinner>

<ng-template #taskList>
  <ng-container *ngIf="tasks$ | async as tasks">

    
    <div class="alert alert-success" *ngIf="!totalRecords">

      <h3 class="text-center">
        <div>
        <mat-icon>schedule</mat-icon>
      </div>
        ¡Vaya! No hay tareas creadas
      </h3>
      <p class="text-center">
        Para añadir una tarea, haz click en el botón + que aparece abajo a la derecha.
      </p>
    </div>

    <cdk-virtual-scroll-viewport class="list-container" itemSize="72">

      <mat-action-list>
        <ng-container *cdkVirtualFor="let task of tasks">
          <mat-list-item [routerLink]="['/job-tasks', task.uid]">
            <p matLine>{{ task.name }}</p>
            
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Menu tareas"
              (click)="$event.stopPropagation()">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="deleteTask(task)">
                <mat-icon>delete</mat-icon>
                <span>Eliminar</span>
              </button>
            </mat-menu>

          </mat-list-item>
        </ng-container>
      </mat-action-list>

    </cdk-virtual-scroll-viewport>
  </ng-container>
</ng-template>

<div class="add-button-container">
  <a mat-fab color="primary" [routerLink]="['/job-tasks/new']">
    <mat-icon>add</mat-icon>
  </a>
</div>