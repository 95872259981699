import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

import { ILegalInfoDTO, OrganisationsService } from '@app/core/organisations';
import { toBase64String } from '@angular/compiler/src/output/source_map';

@Component({
  selector: 'app-legal-info',
  templateUrl: './legal-info.component.html',
  styleUrls: ['./legal-info.component.scss']
})
export class LegalInfoComponent implements OnInit {

  public working: boolean;
  public frmLegalInfo: FormGroup;
  public data$: BehaviorSubject<ILegalInfoDTO>;

  @Output()
  public completed: EventEmitter<boolean>;

  constructor(
    private service: OrganisationsService,
    private fb: FormBuilder
  ) {
    this.data$ = new BehaviorSubject(null);
    this.completed = new EventEmitter();
  }

  public ngOnInit(): void {

    this.frmLegalInfo = this.fb.group({
      legalName: ['', [Validators.required]],
      legalId: ['', [Validators.required, Validators.pattern('[A-Z0-9]+')]],
      legalAddress: [''],
      phoneNumber: [''],
      email: ['', [Validators.required, Validators.email]],
    });

    this.working = true;
    this.service
    .getLegalInfo()
    .subscribe(data => {

      this.data$.next(data);
      this.frmLegalInfo.patchValue(data);

      if (this.frmLegalInfo.valid){
        this.completed.next(false);
      }

      this.working = false;

    }, err => {
      this.working = false;
      throw err;
    });
  }

  public updateLegalInfo(): void {

    if (!this.frmLegalInfo.valid) {
      return;
    }

    this.working = true;

    this.service
    .setLegalInfo(this.frmLegalInfo.value)
    .subscribe(() => {
      this.working = false;
      this.completed.next(true);
    });

  }

}
