import { UserRole } from '../auth';
import { ISimpleExDTO } from '../defs';

export interface IUserDTO extends ISimpleExDTO {
  activated: boolean;
  role: string;
}

export interface IGetUserListRequest {
  userId?: string;
  roleId?: UserRole;
  excludeIds?: string[];
}

export interface ICreateUserRequest {
  name: string;
}


export interface IForgotPasswordRequest {
  userName: string;
}

export interface IConfirmResetPasswordRequest {
  organisation: string;
  userName: string;
  token: string;
  password: string;
  confirmPassword: string;
}


export interface IConfirmRegisterUserRequest {
  organisation: string;
  userName: string;
  token: string;
  password: string;
  confirmPassword: string;
}

