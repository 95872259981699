export interface IRegisterOrganisationRequest {
  organisation: string;
  userName: string;
}

export interface IConfirmRegisterOrganisationRequest {
  organisation: string;
  userName: string;
  token: string;
  password: string;
  confirmPassword: string;
}

export interface ILegalInfoDTO {
  legalId: string;
  email: string;
  legalName: string;
  legalAddress: string;
  phoneNumber: string;
}
