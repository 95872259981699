import { Component, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';


@Component({
  selector: 'app-input-search',
  templateUrl: './input-search.component.html',
  styleUrls: ['./input-search.component.scss']
})
export class InputSearchComponent implements OnDestroy {

  @Input()
  public debounce: number;

  @Input()
  public placeholder: string;

  @Output()
  public changed: EventEmitter<string>;

  public searchText: string;

  private search$: Subject<string>;
  private subscription: Subscription;


  constructor(
  ) {

    this.changed = new EventEmitter();

    this.search$ = new Subject<string>();
    this.subscription = this.search$
      .pipe(
        distinctUntilChanged(),
        debounceTime(this.debounce != null ? this.debounce : 500)
      ).subscribe(value => {
        this.changed.next(value);
      });

  }

  public inputChanged($event: string): void {
    this.search$.next($event);
  }

  public clear(): void {
    this.searchText = '';
    this.inputChanged('');
  }

  public ngOnDestroy(): void {

    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }

}
