import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-employee-capture',
  templateUrl: './employee-capture.component.html',
  styleUrls: ['./employee-capture.component.scss']
})
export class EmployeeCaptureComponent  {

  constructor(
    private dialogRef: MatDialogRef<EmployeeCaptureComponent>,
  ) { }

  public captureReady($event: string): void {
    this.dialogRef.close($event);
  }

}
