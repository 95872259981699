import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';
import { PaymentsListComponent } from './list/payments-list.component';

@NgModule({
  declarations: [
    PaymentsListComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    PaymentsListComponent
  ]
})
export class PaymentsModule { }
