import { ErrorHandler, NgModule } from '@angular/core';
import { SharedModule } from '@app/shared';
import { GlobalErrorHandler } from './_common/global-error-handler.service';



@NgModule({
  declarations: [],
  imports: [
    SharedModule
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
  ]
})
export class CoreModule { }
