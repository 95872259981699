import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';

import { IGetReportByDayRequest, IReportByDayDTO, ReportsService } from '@app/core/reports';
import { LayoutService } from '@app/core/layout';

@Component({
  selector: 'app-day-report',
  templateUrl: './day-report.component.html',
  styleUrls: ['./day-report.component.scss']
})
export class DayReportComponent {

  public report$: BehaviorSubject<IReportByDayDTO>;
  public request: IGetReportByDayRequest;
  public selectedDate: moment.Moment;
  public chartOptions: ChartOptions;

  public chartDatas: ChartDataSets[][];
  public chartLabels: Label[][];
  public loading: boolean;

  constructor(
    private service: ReportsService,
    private layout: LayoutService
  ) {

    this.chartOptions = {
      responsive: true,
      scales: {
        xAxes: [{}],
        yAxes: [{
          ticks: {
            stepSize: 2,
            beginAtZero: true,
            suggestedMax: 12
          }
        }]
      },
      tooltips: {
        enabled: false
      },
      plugins: {
        datalabels: {
          anchor: 'end',
          align: 'end',
          formatter: (value, ctx) => {

            const totalMins = value * 60;
            return (Math.floor(totalMins / 60) + 'h') + (totalMins % 60) + 'm';
          },
        }
      }
    };

    this.report$ = new BehaviorSubject(null);
    this.selectedDate = moment();
    this.selectedDateChanged();
  }

  public loadReport(): void {

    this.loading = true;
    this.service.getReportByDay(this.request).subscribe(report => {

      this.report$.next(report);

      this.chartLabels = report.weights.map(w => [w.employee.name]);

      this.chartDatas = report.weights.map(w => {

        return w.entries.map(ww => {
          return { data: [(ww.totalTime.minutes + (ww.totalTime.hours * 60)) / 60], label: ww.task.name };
        });

      });

      this.setToolbar();

    }, (e) => {
      this.loading = false;
      throw (e);
    }, () => this.loading = false);

  }

  private setToolbar(): void {

    this.layout.setToolbar({
      title: 'Informes - Día',
      showMenuButton: true,
      rightButtons: [
        {
          action: () => this.exportData(),
          text: 'Exportar',
          disabled: () => !this.report$.value.entries.length
        }
      ]
    });

  }

  private exportData(): void {

    if (!this.report$.value || !this.report$.value.entries.length) {
      return;
    }

    this.service.exportReportByDay(this.request).subscribe(blob => {

      const data = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = data;
      link.download = `visual-selphi-informe-dia-${this.request.date}.xlsx`;
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);

    });

  }

  public selectedDateChanged(): void {

    this.request = {
      date: this.selectedDate.format('yyyy/MM/DD')
    };

    this.loadReport();
  }

}
