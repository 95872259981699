<div mat-dialog-content class="data-container">

  <div class="p-2">
    <h3>Seleccione tareas</h3>
  </div>
  <app-job-task-selector [multiple]="true" [(ngModel)]="selectedTasks"></app-job-task-selector>

</div>

<mat-dialog-actions>
  <button mat-flat-button (click)="no()">Cancelar</button>
  <button mat-flat-button (click)="clearSelection()">Ninguna</button>
  <button mat-flat-button color="primary" (click)="yes()">Seleccionar</button>
</mat-dialog-actions>