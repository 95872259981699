import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { ICanSaveChanges } from '@app/core';
import { EmployeesService, IEmployeeDetailDTO } from '@app/core/employees';
import { LayoutService } from '@app/core/layout';
import { MessageBoxService } from '@app/shared';
import { EmployeeCaptureComponent } from '../capture/employee-capture.component';

@Component({
  selector: 'app-employee-detail',
  templateUrl: './employee-detail.component.html',
  styleUrls: ['./employee-detail.component.scss']
})
export class EmployeeDetailComponent implements OnInit, ICanSaveChanges {

  public employee: IEmployeeDetailDTO;
  public frmEmployee: FormGroup;
  public image: string;
  public working: boolean;
  public hasChanges: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private msgbox: MessageBoxService,
    private service: EmployeesService,
    private layout: LayoutService
  ) {

    this.frmEmployee = new FormGroup({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      legalId: new FormControl('', Validators.required),
      address: new FormControl(),
      postalCode: new FormControl(),
      city: new FormControl(),
      state: new FormControl(),
      province: new FormControl(),
      email: new FormControl(),
      phoneNumber: new FormControl(),
      notes: new FormControl(),
      image: new FormControl(this.image)
    });

  }

  public ngOnInit(): void {

    this.employee = (this.route.snapshot.data.employee || {}) as IEmployeeDetailDTO;
    this.image = this.employee.image;

    this.frmEmployee.patchValue(this.employee);
    this.frmEmployee.valueChanges.subscribe(() => {
      this.hasChanges = true;
    });

    this.layout.setToolbar({
      title: this.employee.name || 'Nuevo empleado',
      showMenuButton: false,
      leftButtons: [
        {
          action: () => this.router.navigate(['employees']),
          icon: 'arrow_back',
        }
      ],
      rightButtons: [
        {
          action: () => this.saveChangesManually(),
          text: 'Guardar'
        }
      ]
    });

  }


  public takeImage(): void {

    this.dialog
    .open(EmployeeCaptureComponent, {
      panelClass: 'modal-flex',
    })
    .afterClosed()
    .subscribe(value => {
      if (value) {
        this.image = value;
        this.frmEmployee.patchValue({ image: value });
      }
    });

  }

  private saveChangesManually(): void {

    this.saveChanges().subscribe((success) => {

      if (success) {
        this.hasChanges = false;
        this.router.navigate(['employees']);
      }

    });

  }
  public saveChanges(): Observable<boolean> {

    if (!this.frmEmployee.valid) {
      this.frmEmployee.markAllAsTouched();
      return of(false);
    }

    this.working = true;

    const request = {
      ...this.frmEmployee.value,
      uid: this.employee.uid
    };

    const operation = !this.employee.uid
      ? this.service.createEmployee(request)
      : this.service.updateEmployee(request);

    return operation
    .pipe(
      switchMap(() => {
        this.hasChanges = false;
        this.msgbox.showSuccess('Cambios guardados');
        return of(true);
      }),
      catchError(err => {
        this.working = false;
        throw(err);
      })
    );

  }

}
