
export class Utils {

  public static isAmPm(value: string): boolean {
    return /am|pm/.test(value);
  }

  public static convertAmTo24(value: string): string {

    const tks = value.split(' ');
    const ampm = tks[1].trim();
    const tts = tks[0].split(':');

    let hour = parseInt(tts[0], 0);
    const minutes = parseInt(tts[1], 0);
    if (ampm === 'pm') {
      hour += 12;
    }

    let hourStr: string = hour.toString();
    if (hour < 10) {
      hourStr = '0' + hourStr;
    }

    let minuteStr: string = minutes.toString();
    if (minutes < 10) {
      minuteStr = '0' + minuteStr;
    }

    return `${hourStr}:${minuteStr}`;
  }

  public static getTimeDiff(startTime: string, endTime: string): { hours: number, minutes: number } {

    const starts = startTime.split(':');
    const ends = endTime.split(':');
    const startDate = new Date(2020, 1, 1, parseInt(starts[0], 0), parseInt(starts[1], 0));
    const endDate = new Date(2020, 1, 1, parseInt(ends[0], 0), parseInt(ends[1], 0));

    const diff = new Date(endDate.getTime() - startDate.getTime());
    return {
      hours: diff.getUTCHours(),
      minutes: diff.getUTCMinutes()
    };
  }


  public static convertTimeStringToDate(date: Date|string, time: string): Date {
    let parsedDate: Date;
    if (!time) {
      return null;
    }

    if (typeof date === 'string') {
        parsedDate = new Date(date);
    } else {
        parsedDate = new Date(date);
    }

    const [hour, minute] = time.split(':').map(Number);


    parsedDate.setHours(hour);
    parsedDate.setMinutes(minute);

    return parsedDate;
    
  }

  public static convertDateToTime24(date: Date): string {
    if (!date) {
      return '';
    }
    return date.toLocaleTimeString('es', { hour: '2-digit', minute: '2-digit' });
  }

}

