<mat-form-field appearance="fill">
  <mat-label>Fecha</mat-label>
  <input matInput [matDatepicker]="picker" (click)="picker.open()" [(ngModel)]="job.startDate" (ngModelChange)="raiseChanged()" autocomplete="false"
    readonly>
  <mat-datepicker-toggle [disabled]="readOnly" matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker [disabled]="readOnly" #picker></mat-datepicker>
</mat-form-field>

<div class="d-flex justify-content-between p-2">
  <div>
    <mat-label>Hora inicio</mat-label>
    <timepicker [disabled]="readOnly" [(ngModel)]="startTime" [minuteStep]="1" (ngModelChange)="startTimeChanged()" [showMeridian]="false"></timepicker>
  </div>
  <div>
    <mat-label>Hora final</mat-label>
    <timepicker [disabled]="readOnly" [(ngModel)]="endTime" [minuteStep]="1" (ngModelChange)="endTimeChanged()" [showMeridian]="false"></timepicker>
  </div>
</div>

<mat-form-field>
  <mat-label>Notas</mat-label>
  <textarea matInput [disabled]="readOnly" [(ngModel)]="job.notes" (ngModelChange)="raiseChanged()"></textarea>
</mat-form-field>