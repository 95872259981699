import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ISimpleExDTO } from '../defs';

import { RequestService } from '../_common';
import { ISetUserToUserRequest } from './defs';

@Injectable({
  providedIn: 'root'
})
export class UserToUsersService {

  constructor(
    private request: RequestService
  ) { }

  public setUsersToUser(request: ISetUserToUserRequest): Observable<any> {
    return this.request.post('/api/user-to-users', request);
  }

  public getUsers(ownerId: string): Observable<ISimpleExDTO[]> {
    return this.request.get(`/api/user-to-users/${ownerId}`);
  }
}
