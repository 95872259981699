<div class="d-flex justify-content-between">

  <button type="button" class="w-100" mat-flat-button color="primary" (click)="openSelector()">

    <span *ngIf="selectedEmployees?.length">
      {{ selectedEmployees.length > 1 ? selectedEmployees.length + ' empleado/s' : selectedEmployees[0].name }} 
    </span>

    <span *ngIf="!selectedEmployees.length">
      Todos los empleados
    </span>
    
  </button>


</div>