import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ISimpleExDTO } from '@app/core';

import { EmployeeSelectorPopupComponent } from '../selector-popup/employee-selector-popup.component';

@Component({
  selector: 'app-employee-selector-input',
  templateUrl: './employee-selector-input.component.html',
  styleUrls: ['./employee-selector-input.component.scss']
})
export class EmployeeSelectorInputComponent implements OnChanges {

  @Input()
  public selectedEmployees: ISimpleExDTO[];

  @Input()
  public selectionMode: string;

  @Output()
  public selectedChanged: EventEmitter<ISimpleExDTO[]>;

  constructor(
    private dialog: MatDialog
  ) {
    this.selectedChanged = new EventEmitter();
    this.selectedEmployees = [];
  }

  public ngOnChanges(): void {
    if (!this.selectedEmployees) {
      this.selectedEmployees = [];
    }
  }

  public openSelector(): void {

    this.dialog.open(EmployeeSelectorPopupComponent, {
      panelClass: 'modal-flex',
      data: {
        selectionMode: this.selectionMode,
        selectedEmployees: [...this.selectedEmployees]
      }
    })
    .afterClosed().subscribe((employees) => {

      if (employees) {
        this.selectedEmployees = employees;
        this.selectedChanged.next(this.selectedEmployees);
      }

    });

  }
}
