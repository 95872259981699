import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { IPaymentDTO, PaymentsService } from '@app/core/payments';

@Component({
  selector: 'app-payments-list',
  templateUrl: './payments-list.component.html',
  styleUrls: ['./payments-list.component.scss']
})
export class PaymentsListComponent implements OnInit {

  public records$: Observable<IPaymentDTO[]>;

  constructor(
    private service: PaymentsService
  ) { }

  public ngOnInit(): void {
    this.records$ = this.service.getPaymentList();
  }

}
