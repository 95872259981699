<ng-container *ngIf="user$ | async as user">

  <nav mat-tab-nav-bar *ngIf="user.role.id !== 2" >
    <a mat-tab-link [class.active]="section === 'employees'" (click)="section = 'employees'">Empleados</a>
    <a mat-tab-link [class.active]="section === 'users'" (click)="section = 'users'">Usuarios</a>
  </nav>

  <ng-container *ngIf="section === 'users'">
    <div class="alert alert-info">
      Usuarios a los que tiene acceso
    </div>

    <div class="alert alert-success" *ngIf="user.role.id === 1">
      Administrador tiene acceso a todos los usuarios
    </div>

    <app-user-to-users [ownerId]="userId" *ngIf="user.role.id !== 1 && section === 'users'"></app-user-to-users>
    
  </ng-container>

  <ng-container *ngIf="section === 'employees'">

    <div class="alert alert-info">
      Empleados a los que tiene acceso
    </div>

    <div class="alert alert-success" *ngIf="user.role.id === 1">
      Administrador tiene acceso a todos los empleados
    </div>

    <app-user-employees [ownerId]="userId" *ngIf="user.role.id !== 1 && section === 'employees'"></app-user-employees>

  </ng-container>

</ng-container>