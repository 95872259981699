<div class="camera-type-container">
    <button mat-flat-button color="primary" (click)="switchCamera()">
        <mat-icon *ngIf="changingCamera">
            <mat-spinner diameter="20" color="accent"></mat-spinner>
        </mat-icon>
        Cambiar cámara
    </button>
</div>


<facephi-selphi *ngIf="isWidgetCaptureStarted" 
    bundlePath="./assets/selphi" 
    language="es"
    [attr.livenessMode]="widgetLivenessMode" 
    [attr.cameraWidth]="widgetCameraWidth"
    [attr.cameraHeight]="widgetCameraHeight" 
    [attr.cameraType]="widgetCameraType"
    [attr.interactible]="interactible" 
    [attr.tutorial]="widgetTutorial"
    [attr.stabilizationStage]="widgetStabilizationStage" 
    [attr.logImages]="true" 
    [attr.timeout]="widgetTimeout" 
    [attr.cropFactor]="1.7"
    [attr.imageFormat]="image/jpeg"
    [attr.imageQuality]="0.98"
    [attr.videoRecord]="widgetVideoRecord" 
    [attr.videoRecordType]="FPhiSelphiConstants.RecorderType.Remote"
    [attr.videoRecordScale]="widgetCameraWidth < 1280 ? 1 : 0.5" 
    [attr.showLog]="widgetShowLog"
    [attr.debugMode]="widgetDebugMode" 
    (onStabilizing)="onStabilizing($event)"
    (onExtractionFinish)="onExtractionFinish($event)" 
    (onExtractionTimeout)="onExtractionTimeout($event)" 
    (onUserCancel)="onUserCancel()"
    (onExceptionCaptured)="onExceptionCaptured($event)" 
    (onLivenessError)="onLivenessError($event)"
    (onLivenessErrorButtonClick)="onLivenessErrorButtonClick()" 
    (onTimeoutErrorButtonClick)="onTimeoutErrorButtonClick()">
</facephi-selphi>

