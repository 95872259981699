<app-map *ngIf="job.latitude && job.longitude"
  [latitude]="job.latitude" [longitude]="job.longitude" 
  [readOnly]="readOnly" 
  [organisationId]="visualConfig?.visualOrganisationId"
  (changed)="mapChanged($event)">
</app-map>

<div class="alert alert-error" *ngIf="error">
  {{ error }}
</div>

<div class="p-2">

  <mat-form-field>
    <mat-label>Latitud</mat-label>
    <input matInput [disabled]="readOnly" [(ngModel)]="job.latitude">
  </mat-form-field>

  <mat-form-field>
    <mat-label>Longitud</mat-label>
    <input matInput [disabled]="readOnly" [(ngModel)]="job.longitude">
  </mat-form-field>

  <mat-form-field>
    <mat-label>Notas</mat-label>
    <textarea matInput [disabled]="readOnly" [(ngModel)]="job.locationNotes" (ngModelChange)="raiseChanged()"></textarea>
  </mat-form-field>

  <div *ngIf="visualConfig">
    <app-plot-selector 
      [readOnly]="readOnly" 
      [coordinates]="{latitude: job.latitude, longitude: job.longitude}" 
      [selectedId]="job.cropUnit" (selectedChanged)="plotSelected($event)">
    </app-plot-selector>
  </div>

</div>