<div class="detail-container">

  <form [formGroup]="frmJobTask">

    <mat-form-field>
      <mat-label>Nombre de la tarea</mat-label>
      <input matInput formControlName="name" placeholder="Placeholder">
    </mat-form-field>

  </form>

</div>