import { NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { LayoutService } from '@app/core/layout';
import { UserRole } from '@app/core/auth';
import { SharedModule } from '@app/shared';
import { IdentificationModule } from '@app/identification';

import { EmployeeListContainerComponent } from './container/employees-container.component';
import { EmployeeDetailComponent } from './detail/employee-detail.component';
import { EmployeeFinderComponent } from './finder/employee-finder.component';
import { EmployeeCaptureComponent } from './capture/employee-capture.component';
import { EmployeeSelectorInputComponent } from './selector-input/employee-selector-input.component';
import { EmployeeSelectorComponent } from './selector/employee-selector.component';
import { EmployeeSelectorPopupComponent } from './selector-popup/employee-selector-popup.component';
import { AddEmployeeButtonComponent } from './add-employee-button/add-employee-button.component';

@NgModule({
  declarations: [
    EmployeeListContainerComponent,
    EmployeeDetailComponent,
    EmployeeFinderComponent,
    EmployeeCaptureComponent,
    EmployeeSelectorInputComponent,
    EmployeeSelectorComponent,
    EmployeeSelectorPopupComponent,
    AddEmployeeButtonComponent
  ],
  imports: [
    SharedModule,
    IdentificationModule
  ],
  exports: [
    EmployeeFinderComponent,
    EmployeeSelectorInputComponent,
    AddEmployeeButtonComponent
  ]
})
export class EmployeesModule {

 }
