import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ISimpleExDTO } from '@app/core';

@Component({
  selector: 'app-jobtask-selector-popup',
  templateUrl: './selector-popup.component.html',
  styleUrls: ['./selector-popup.component.scss']
})
export class JobTaskSelectorPopupComponent {

  public selectedTasks: ISimpleExDTO[];

  constructor(
    private dialogRef: MatDialogRef<JobTaskSelectorPopupComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.selectedTasks = data.selectedTasks || [];
  }

  public no(): void {
    this.dialogRef.close();
  }

  public yes(): void {
    this.dialogRef.close(this.selectedTasks);
  }

  public clearSelection(): void {
    this.selectedTasks.length = 0;
    this.yes();
  }
}
