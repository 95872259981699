export interface IPaymentMethodDTO {
  cardHolder: string;
  brand: string;
  isDefault: boolean;
  expiryDate: Date;
  last4: string;
}

export interface ISubscriptionDTO {
  active: boolean;
  renewFrom: Date;
  renewTo: Date;
  subscriptionType: SubscriptionType;
  maxEmployees?: number;
  employeesInUse: number;
}

export enum SubscriptionType {
  Free = 0,
  Employee = 1,
  Package = 2
}

export interface IEnableSubscriptionRequest {
  type: SubscriptionType;
}
