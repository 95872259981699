import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ISimpleExDTO } from '@app/core';
import { EmployeesService, IEmployeeListDTO, IGetEmployeeListRequest } from '@app/core/employees';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-employee-selector',
  templateUrl: './employee-selector.component.html',
  styleUrls: ['./employee-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmployeeSelectorComponent),
      multi: true
    }
  ]
})
export class EmployeeSelectorComponent implements OnInit, ControlValueAccessor {

  @Input()
  public selectionMode: string;

  @Input()
  public employees: IEmployeeListDTO[];

  @Input()
  public searchText: string;

  public records$: BehaviorSubject<IEmployeeListDTO[]>;

  public onChange = (_: any) => { };
  public onTouch = () => { };

  constructor(
    private service: EmployeesService
  ) {
    this.employees = [];
    this.records$ = new BehaviorSubject(null);
  }

  private loadRecords(): void {

    const req: IGetEmployeeListRequest = {};
    if (this.searchText) {
      req.searchText = this.searchText;
    }

    this.service.getEmployeeList(req).subscribe(data => this.records$.next(data.records));
  }

  public ngOnInit(): void {
    this.loadRecords();
  }

  public ngOnChanges(): void {
    this.loadRecords();
  }

  public registerOnValidatorChange?(fn: () => void): void {
  }

  public writeValue(obj: any): void {
    this.employees = obj || [];
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }

  public selectedChanged($event: any): void {

    const employee = $event.option.value as IEmployeeListDTO;

    const idx = this.employees.findIndex(t => t.uid === employee.uid);
    if (idx < 0) {

      if (this.selectionMode === 'single') {
        this.employees.length = 0;
      }

      this.employees.push(employee);
    } else {
      this.employees.splice(idx, 1);
    }

    this.onChange(this.employees);
  }

  public isRecordSelected(record: ISimpleExDTO): boolean {
    return this.employees?.some(t => t.uid === record.uid);
  }
}
