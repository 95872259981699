import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, catchError, Observable, throwError } from 'rxjs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

import { IGetJobListRequest, IJobListDTO, JobsService } from '@app/core/jobs';
import { ISimpleExDTO } from '@app/core';
import { LayoutService } from '@app/core/layout';
import { MessageBoxService } from '@app/shared';
import { VisualService } from '@app/core/visual';
import { createInjectableDefinitionMap } from '@angular/compiler/src/render3/partial/injectable';
import { MatDialog } from '@angular/material/dialog';
import { SyncResultModalComponent } from '../sync-result/sync-result';
import * as moment from 'moment';

@Component({
  selector: 'app-jobs-list',
  templateUrl: './jobs-list.component.html',
  styleUrls: ['./jobs-list.component.scss']
})
export class JobsListComponent implements OnInit {
  events: string[] = [];
  public jobs$: BehaviorSubject<IJobListDTO[]>;
  public totalRecords: number;
  public startDate: moment.Moment;
  public endDate: moment.Moment;
  public loading: boolean;
  public request: IGetJobListRequest;
  public selectedTasks: ISimpleExDTO[];
  public jobsFinished: IJobListDTO[];
  public jobAux: IJobListDTO[];
  public jobsIdList: string[];
  public startTime : moment.Moment;
      //private visualService: VisualService;

  constructor(
    private service: JobsService,
    private layout: LayoutService,
    private msgbox: MessageBoxService,
    private visualService: VisualService,
    private dialog: MatDialog
  ) {

    this.loading = false;
    this.jobs$ = new BehaviorSubject(null);
    this.request = {} as any;
    this.jobAux = [];
    this.jobsFinished = [];
    this.jobsIdList = [];


    this.layout.setToolbar({
      showMenuButton: true,
      title: 'Trabajos'
    });
  }

  public ngOnInit(): void {
    this.loadData();
  }

  private loadData(): void {
    this.loading = true;
    this.service.getJobList(this.request).subscribe(data => {

	  
      this.jobs$.next(data.records.sort(
        (a,b) => {
          const firstDate = Date.parse(a.startDate as any);
          const secondDate = Date.parse(b.startDate as any);

          return secondDate - firstDate;
        }
      ));
      this.totalRecords = data.totalRecords;
      this.loading = false;

    }, (ex) => {
      this.loading = false;
      throw ex;
    });
    
  }



  public deleteJob(job: IJobListDTO): void {

    this.msgbox.showYesNo({ title: '¿Eliminar trabajo?' })
      .subscribe(val => {

        if (val) {

          this.loading = true;
          this.service.deleteJob(job.uid).subscribe(() => {
            this.loadData();
          }, (ex) => {
            this.loading = false;
            throw ex;
          });

        }

      });
  }

  public dateChanged(): void {
    this.request.startDate = this.startDate ? this.startDate.format('yyyy/MM/DD') : null;
    this.request.endDate = this.endDate ? this.endDate.format('yyyy/MM/DD') : null;
    this.loadData();
  }

  public selectedTasksChanged($event: ISimpleExDTO[]): void {
    this.request.Tasks = $event.map(t => t.uid);
    this.loadData();
  }

  public syncJobs(): void {
    this.jobsFinished = [];
    this.jobAux = [];
    this.jobsIdList = [];
    for (let index = 0; index < this.jobs$.getValue().length; index++) {
      this.jobAux = this.jobs$.getValue();
      if (this.jobAux[index].synced == false) {
        this.jobsFinished.push(this.jobAux[index]);
      }
    }
    for (let index = 0; index < this.jobsFinished.length; index++) {
      this.jobsIdList.push(this.jobsFinished[index].uid)
    }
    
    this.loading = true;

    this.visualService.syncMultipleJobs(this.jobsIdList).pipe(
      catchError(err => {
        return throwError(err);
      })
    )
      .subscribe((data) => {
        this.loading = false;
        this.dialog.open(SyncResultModalComponent, {
          panelClass: 'modal-flex',
          data
        }).afterClosed().subscribe( () => {
          this.loadData();
        });
      });

  }
}
