
<div *ngIf="records$ | async as records">

  <div class="alert alert-success" *ngIf="!records.length">

    <h3 class="text-center">
      <div>
        <mat-icon>schedule</mat-icon>
      </div>
      No tienes pagos todavía
    </h3>
  </div>

  <mat-action-list>
    <mat-list-item *ngFor="let record of records">
      <p matLine>{{ record.createDate | date: 'dd MMMM yyyy' }}</p>
      <p matLine>{{ record.amount }} €</p>
      <p matLine>{{ record.description }}</p>
    </mat-list-item>
  </mat-action-list>

</div>