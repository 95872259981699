<app-spinner *ngIf="loading">
</app-spinner>

<ng-container *ngIf="records$ | async as records">

  <div class="d-flex justify-content-between p-2" *ngIf="records.length">

    <button type="button" mat-flat-button color="primary" (click)="selectAll()">
      Todos
    </button>

    <button type="button" mat-flat-button color="primary" (click)="selectNone()">
      Ninguno
    </button>

  </div>

  <div class="scroll-content">

    <mat-selection-list (selectionChange)="selectedChanged($event)">
      <mat-list-option *ngFor="let record of records" [value]="record" [selected]="isRecordSelected(record)">
        {{record.name}}
      </mat-list-option>
    </mat-selection-list>

  </div>

</ng-container>