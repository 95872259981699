import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';

import { ISimpleExDTO } from '@app/core';
import { IGetReportByJobRequest, IReportByJobDTO, ReportsService } from '@app/core/reports';
import { LayoutService } from '@app/core/layout';
import { Router } from '@angular/router';

@Component({
  selector: 'app-job-report',
  templateUrl: './job-report.component.html',
  styleUrls: ['./job-report.component.scss']
})
export class JobReportComponent {

  public report$: BehaviorSubject<IReportByJobDTO>;
  public request: IGetReportByJobRequest;
  public loading: boolean;
  public selectedTasks: ISimpleExDTO[];
  public startDate: moment.Moment;
  public endDate: moment.Moment;

  constructor(
    private service: ReportsService,
    private layout: LayoutService,
  ) {

    this.report$ = new BehaviorSubject(null);
    this.request = {} as any;
    this.startDate = moment().subtract(1, 'day');
    this.endDate = moment();
    this.dateChanged();
  }

  private loadReport(): void {
    
    this.loading = true;
    this.service.getReportByJob(this.request).subscribe(report => {
      console.log(this.report$)
      this.report$.next(report);    
      this.setToolbar();
    }, (e) => {
      this.loading = false;
      throw (e);
    }, () => this.loading = false);

  }

  private setToolbar(): void {

    this.layout.setToolbar({
      title: 'Informes - Trabajos',
      showMenuButton: true,
      rightButtons: [
        {
          action: () => this.exportData(),
          text: 'Exportar',
          disabled: () => !this.report$.value.entries.length
        }
      ]
    });

  }

  private exportData(): void {

    if (!this.report$.value || !this.report$.value.entries.length) {
      return;
    }

    this.service.exportReportByJob(this.request).subscribe(blob => {

      const data = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = data;
      link.download = 'visual-selphi-informe-trabajos.xlsx';
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
          link.remove();
      }, 100);

    });

  }
/*
  public dateChanged(): void {
    this.request.startDate = this.startDate ? this.startDate.format('yyyy/MM/DD') : null;
    this.request.endDate = this.endDate ? this.endDate.format('yyyy/MM/DD') : null;
    this.loadReport();
  }*/
  
  public selectedTasksChanged($event: ISimpleExDTO[]): void {
    this.request.tasks = $event.map(t => t.uid);
    this.loadReport();
  }
  public showErrorMessage: boolean = false;

public dateChanged(): void {
  this.request.startDate = this.startDate ? this.startDate.format('yyyy/MM/DD') : null;
  this.request.endDate = this.endDate ? this.endDate.format('yyyy/MM/DD') : null;
  
  const startDate = this.startDate ? this.startDate.toDate() : null;
  const endDate = this.endDate ? this.endDate.toDate() : null;
  
  if (startDate && endDate) {
    const differenceInDays = Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));
    
    if (differenceInDays > 60) {
      this.showErrorMessage = true;
      setTimeout(() => {
        this.showErrorMessage = false;
      }, 5000); 
      return;
    }
  }
  
  this.loadReport();
}

}
