import { ISimpleDTO } from '../defs';

export interface IVisualConfigDTO {
  organisationId: string;
  userId: number;
  visualOrganisationId: number;
  userName: string;
  password: string;
  roles: string;
}

export interface IGetPlotListRequest {
  longitude: number;
  latitude: number;
}

export interface IVisualPlotDTO extends ISimpleDTO {
  area: number;
}

export interface ISyncJobRequest {
  jobId: string;
}
