<div class="d-flex flex-row h-100">


  <div class="col d-flex flex-column align-items-center justify-content-center">


    <img src="/assets/logo.svg" />
    <h1>Visual Selphi</h1>

    <form #formLogin="ngForm" class="formLogin" (ngSubmit)="doLogin()">

      <div class="alert alert-danger" *ngIf="error" [innerHtml]="error">
      </div>

      <mat-form-field appearance="outline">
        <input matInput type="text" name="organisation" [(ngModel)]="request.client_id" [errorStateMatcher]="matcher" required />
        <mat-label>Empresa</mat-label>
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-100">
        <input matInput type="email" name="userName" [(ngModel)]="request.userName" [errorStateMatcher]="matcher" required />
        <mat-label>Email</mat-label>
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-100">
        <input matInput [type]="showPass ? 'text' : 'password'" name="password" [(ngModel)]="request.password" [errorStateMatcher]="matcher" required />
        <mat-label>Contraseña</mat-label>
        <button type="button" mat-button matSuffix mat-icon-button aria-label="Mostrar password" (click)="showPass = !showPass">
          <mat-icon *ngIf="!showPass">visibility</mat-icon>
          <mat-icon *ngIf="showPass">visibility_off</mat-icon>
        </button>
      </mat-form-field>

      <button mat-flat-button type="submit" color="primary" class="w-100" [disabled]="shouldDisableButton()">
        <mat-icon *ngIf="working">
          <mat-spinner diameter="20" color="accent"></mat-spinner>
        </mat-icon>
        <span>Iniciar sesión</span>
      </button>

      <mat-checkbox name="remember" [(ngModel)]="request.remember">Recordar mis datos</mat-checkbox>

    </form>

    <hr>

    <a mat-flat-button color="accent" class="mb-2" [routerLink]="['/organisations/register']">
      Registrarse
    </a>

    <a mat-flat-button color="accent" class="" [routerLink]="['/users/forgot-password']">
      Olvidé mi contraseña
    </a>


  </div>

  <div class="col col-background">

  </div>

</div>