import { Routes } from '@angular/router';
import { SubscriptionCreatedComponent } from './created/subscription-created.component';

import { CreditCardComponent } from './credit-card/credit-card.component';
import { EnableSubscriptionComponent } from './enable/enable-subscription.component';
import { PaymentMethodsComponent } from './payment-methods/payment-methods.component';
import { SubscriptionStatusComponent } from './status/subscription-status.component';
import { SubscriptionWelcomeComponent } from './welcome/subscription-welcome.component';

export const SubscriptionRoutes: Routes = [
  {
    path: 'payment-methods/new',
    component: CreditCardComponent
  },
  {
    path: 'payment-methods',
    component: PaymentMethodsComponent
  },
  {
    path: 'subscription/status',
    component: SubscriptionStatusComponent
  },
  {
    path: 'subscription/enable',
    component: EnableSubscriptionComponent
  },
  {
    path: 'subscription/created',
    component: SubscriptionCreatedComponent
  },
  {
    path: 'subscription/welcome',
    component: SubscriptionWelcomeComponent
  }

];
