import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ConfigService, IErrorResponse } from '@app/core';
import { OrganisationsService } from '@app/core/organisations';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {

  public frmRegister: FormGroup;

  public working: boolean;
  public error: string;
  public success: boolean;
  public submitted: boolean;
  public termsUrl: string;

  constructor(
    private config: ConfigService,
    private service: OrganisationsService,
    private fb: FormBuilder,
  ) {

    this.frmRegister = this.fb.group({
      organisation: ['', Validators.required],
      userName: ['', [Validators.required, Validators.email]],
      termsAccepted: ['', Validators.required],
    });

    this.termsUrl = this.config.getConfig().termsUrl;

  }

  public shouldDisableSubmit(): boolean {
    return !this.frmRegister.valid || this.working;
  }

  public doRegister(): void {

    if (!this.frmRegister.valid) {
      this.submitted = true;
      return;
    }

    this.working = true;
    this.error = '';

    this.service
      .register({
        ...this.frmRegister.value
      })
      .pipe(
        catchError((response: IErrorResponse) => {
          // catch the error to avoid global error handler catching it
          return of(response);
        })
      )
      .subscribe(response => {

        const resp = response as IErrorResponse;
        if (resp.error) {
          this.error = resp.error;
        } else {
          this.success = true;
        }

        this.working = false;

      });

  }

}
