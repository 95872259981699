<div class="options-container">

  <div class="row">

    <div class="col-6">
      <mat-form-field appearance="fill">
        <mat-label>Fecha inicio</mat-label>
        <input matInput [matDatepicker]="startDatePicker" (click)="startDatePicker.open()" [(ngModel)]="startDate"
          (ngModelChange)="dateChanged()" autocomplete="false" readonly>
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="col-6">
      <mat-form-field appearance="fill">
        <mat-label>Fecha fin</mat-label>
        <input matInput [matDatepicker]="endDatePicker" (click)="endDatePicker.open()" [(ngModel)]="endDate"
          (ngModelChange)="dateChanged()" autocomplete="false" readonly>
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="col-12">
      <app-job-task-selector-input [selectedTasks]="selectedTasks" (selectedChanged)="selectedTasksChanged($event)">
      </app-job-task-selector-input>
    </div>

  </div>

</div>
<div *ngIf="showErrorMessage" class="floating-message">
  <span class="floating-message-icon material-icons">info</span>
  <p>Debe acotar el rango de fechas a un máximo de 60 días</p>
</div>

<app-spinner *ngIf="loading">
</app-spinner>

<div class="data-container" *ngIf="report$ | async as report">

  <ng-container *ngIf="!report.entries.length">
    <div class="alert alert-success">
      <h3 class="text-center">
        <div>
        <mat-icon>timeline</mat-icon>
      </div>
        No se encuentran registros con los filtros actuales
      </h3>
    </div>
  </ng-container>

  <ng-container *ngIf="report.entries.length">

    <div class="row" *ngFor="let record of report.entries">

      <div class="col-12 col-sm-4 font-weight-bold">
        {{ record.employee.name }}
      </div>

      <div class="col-12 col-sm-2 text-nowrap">
        {{ record.date | date: 'dd/MM/yy' }}
      </div>

      <div class="col-4 col-sm-2 text-nowrap">
        {{ record.startTime }}
      </div>

      <div class="col-4 col-sm-2 text-nowrap">
        {{ record.Time }}
      </div>

      <div class="col-4 col-sm-2 text-nowrap">
        {{ record.totalTime | timeLapse }}
      </div>
      
      <div class="col-12 ">
        <span class="text-muted">
          {{ record.task.name }}
        </span>
      </div>
      <div class="col-12">
        {{ record.notes }}
      </div>
      <div class="col-12">
      {{ record.locationName }}
      </div>
      



    </div>

    <div class="row">
      <div class="col-12 font-weight-bold text-right">
        Total {{ report.total | timeLapse}}
      </div>
    </div>

  </ng-container>

</div>