import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LayoutService } from '@app/core/layout';
import { IReportDef, ReportsService } from '@app/core/reports';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-reports-container',
  templateUrl: './reports-container.component.html',
  styleUrls: ['./reports-container.component.scss']
})
export class ReportsContainerComponent implements OnDestroy {

  private sx: Subscription;
  public showMenu: boolean;
  public reports: IReportDef[];

  constructor(
    private layout: LayoutService,
    private service: ReportsService,
    private router: Router,
  ) {

    this.reports = this.service.getReports();

    this.sx = this.router.events.pipe(
      filter((e): e is NavigationEnd => e instanceof NavigationEnd),
    ).subscribe((d) => {
      this.showMenu = d.url === '/reports';

      if (this.showMenu) {
        this.layout.setToolbar({
          showMenuButton: true,
          title: 'Informes'
        });
      }
    });

  }

  public ngOnDestroy(): void {
    if (this.sx) {
      this.sx.unsubscribe();
    }
  }

}
