import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { RequestService } from '..';
import { IEnableSubscriptionRequest, ISubscriptionDTO } from './defs';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  private subscription: ISubscriptionDTO;

  constructor(
    private requestService: RequestService,
  ) {
  }

  public enable(request: IEnableSubscriptionRequest): Observable<any> {
    return this.requestService
      .post('api/subscription/enable', request)
      .pipe(
        switchMap(() => {
        // delete local value to force re-load
        delete this.subscription;
        return this.hasSubscription();
      }));
  }

  public getSubscriptionStatus(): Observable<ISubscriptionDTO> {
    return this.requestService.get('api/subscription/status');
  }

  public hasSubscription(): Observable<boolean> {

    // return of(false);

    if (this.subscription) {
      return of(this.subscription.active);
    }

    return this.getSubscriptionStatus().pipe(

      map(data => {
        this.subscription = data;
        return data.active;
      })

    );

  }


}
