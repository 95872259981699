import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';

export interface IAppConfig {
  apiEndpoint: string;
  captchaSiteKey: string;
  termsUrl: string;
  googleMapsApiKey: string;
  enableLivenessCheck: boolean;
  mapServer: string;
}


@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private config: IAppConfig;

  constructor(private http: HttpClient) {
  }

  public getConfig(): IAppConfig {
    if (!this.config) {
      throw new Error('Config not loaded');
    }
    return this.config;
  }

  public load() {
    return this.http.get('config.json').pipe(tap(x => {
      this.config = x as any;
    }));
  }
}
