<div class="inner-container">
  <div class="pb-2 pt-2" *ngFor="let jobEmployee of job.employees; let idx = index">
    <h4 class="m-0">{{jobEmployee.employee.name}}</h4>

    <div class="row align-items-center justify-content-between flex-wrap no-gutters mb-4">

      <div class="col">
        <timepicker [disabled]="readOnly" [(ngModel)]="startTimes[idx]" [minuteStep]="1" [max]="endTimes[idx]"
          (ngModelChange)="calcEmployeeTime(jobEmployee, idx)" [showMeridian]="false"></timepicker>
      </div>

      <div class="col">
        <timepicker [disabled]="readOnly" [(ngModel)]="endTimes[idx]" [minuteStep]="1" [min]="startTimes[idx]"
          (ngModelChange)="calcEmployeeTime(jobEmployee, idx)" [showMeridian]="false"></timepicker>
      </div>

      <div class="col-12 col-sm text-right mb-3">
        <span *ngIf="jobEmployee.totalHours">
          {{ jobEmployee.totalHours }} h
        </span>
        <span *ngIf="jobEmployee.totalMinutes">
          {{ jobEmployee.totalMinutes }} m
        </span>
        <button type="button" mat-icon-button color="primary" (click)="removeEmployee(jobEmployee)" *ngIf="!readOnly">
          <mat-icon>delete</mat-icon>
        </button>
      </div>

    </div>

    <div class="row no-gutters">
      <div class="col">
        <app-rating-selector [value]="jobEmployee.rating" [readOnly]="readOnly"
          (valueChanged)="ratingChanged($event, jobEmployee)">
        </app-rating-selector>
      </div>

      <div class="col-4">
        <mat-form-field class="w-100">
          <mat-label>Cantidad</mat-label>
          <input matInput type="number" [disabled]="readOnly" [(ngModel)]="jobEmployee.ratingQuantity"
            (ngModelChange)="raiseChanged()">
        </mat-form-field>
      </div>

    </div>

    <mat-divider></mat-divider>

  </div>
</div>

<div class="add-button-container" *ngIf="!readOnly">
  <app-add-employee-button (selected)="addEmployee($event)"></app-add-employee-button>
</div>