import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';

import { EmployeesService, IEmployeeMatchDTO, IEmployeeMatchEntryDTO } from '@app/core/employees';

@Component({
  selector: 'app-employee-finder',
  templateUrl: './employee-finder.component.html',
  styleUrls: ['./employee-finder.component.scss']
})
export class EmployeeFinderComponent {

  public capturing: boolean;
  public result$: BehaviorSubject<IEmployeeMatchDTO>;

  constructor(
    private service: EmployeesService,
    private dialogRef: MatDialogRef<EmployeeFinderComponent>,
  ) {
    this.result$ = new BehaviorSubject(null);
    this.capturing = true;

    // setTimeout(() => {
    //   this.dialogRef.close({uid: '4d1ef1c2-86cd-40f5-bebb-ef661259a126', name: 'Jose Luis Alfonso'})
    // }, 1000);

  }

  public captureReady($event: string): void {

    if ($event) {
      this.service.getEmployeeMatch({
        image: $event
      }).subscribe(result => {

        this.capturing = false;

        if (result.entries.length === 1) {
          this.selectEmployee(result.entries[0]);
        } else {
          this.result$.next(result);
        }

      });
    } else {
      this.dialogRef.close(null);
    }

  }

  public retryIdentify(): void {
    this.result$.next(null);
    this.capturing = true;
  }

  public selectEmployee(match: IEmployeeMatchEntryDTO): void {
    this.dialogRef.close(match.employee);
  }

}
