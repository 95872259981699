import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ISimpleExDTO } from '@app/core';
import { KnownSettings, OrganisationSettingsService } from '@app/core/organisation-settings';

import { EmployeeFinderComponent } from '../finder/employee-finder.component';
import { EmployeeSelectorPopupComponent } from '../selector-popup/employee-selector-popup.component';

@Component({
  selector: 'app-add-employee-button',
  templateUrl: './add-employee-button.component.html',
  styleUrls: ['./add-employee-button.component.scss']
})
export class AddEmployeeButtonComponent {

  @Output()
  public selected: EventEmitter<ISimpleExDTO[]>;

  public manualSelection: boolean;

  constructor(
    private dialog: MatDialog,
    private settingsService: OrganisationSettingsService
  ) {

    this.selected = new EventEmitter();
    this.settingsService.getSetting(KnownSettings.MANUAL_REGISTRATION, 'true').subscribe(setting => {
      this.manualSelection = setting ? true : false;
    });


  }

  private openDialog(dlgC: any): void {
    const dlg = this.dialog.open(dlgC, { panelClass: 'modal-flex'})
    ;

    dlg.afterClosed()
    .subscribe(selection => {

      if (selection) {

        if (selection.length) {
          this.selected.next(selection);
        } else {
          this.selected.next([selection]);
        }

      }

    });
  }

  public addEmployeeManual(): void {
    this.openDialog(EmployeeSelectorPopupComponent);
  }

  public addEmployeeAuto(): void {
    this.openDialog(EmployeeFinderComponent);
  }

}
