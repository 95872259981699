import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LayoutService } from '@app/core/layout';
import { IUserDTO, UsersService } from '@app/core/users';

@Component({
  selector: 'app-user-detail-container',
  templateUrl: './detail-container.component.html',
  styleUrls: ['./detail-container.component.scss']
})
export class UserDetailContainerComponent implements OnInit {

  public user$: Observable<IUserDTO>;
  public userId: string;
  public section: string;

  constructor(
    private layout: LayoutService,
    private route: ActivatedRoute,
    private service: UsersService
  ) {
    this.section = 'employees';
  }

  public ngOnInit(): void {

    this.layout.setToolbar({
      showMenuButton: true,
      title: 'Usuario'
    });

    this.route.paramMap.subscribe(prms => {
      if (prms.has('id')) {

        this.userId = prms.get('id');

        this.user$ = this.service.getUserList({
          userId: this.userId
        }).pipe(map(values => {

          return values.records.length ? values.records[0] : null;

        }));

      }
    });

  }

}
