<div class="page-title">Activa la subscripción</div>

<div class="alert alert-info">

  <p>
    Pagarás <strong>6€/mes</strong> por cada empleado que registres.
  </p>

  <p>
    Todos los meses, recibirás el cobro de acuerdo a los registros que hayas ingresado
  </p>

  <p>
    Aquí puedes leer toda nuestra <a href="https://www.visualnacert.com/privacidad-y-terminos-legales/" target="_blank">
      política de privacidad y términos legales</a>
  </p>

</div>

<p>

  <mat-checkbox name="condition" [(ngModel)]="condition">
    He leído y acepto los términos y condiciones, política de privacidad y condiciones de contratación
  </mat-checkbox>

</p>

<div class="text-center mb-4">
  <button type="button" class="w-50" mat-flat-button color="primary" (click)="enableSubscription()"
    [disabled]="!condition">
    Activar Subscripción
  </button>
</div>

<ng-container *ngIf="subscription$ | async as subscription">

  <ng-container *ngIf="!subscription.active">
    <p>
      También puedes acceder a una prueba gratuita para identificar a tu primer empleado
    </p>

    <div class="text-center">

      <app-spin-button [working]="working" [type]="button" text="Prueba Gratis" (click)="enableFree()">
      </app-spin-button>

    </div>

  </ng-container>

</ng-container>