<app-spinner *ngIf="working">
</app-spinner>

<div class="detail-container">

  <form [formGroup]="frmEmployee">

    <mat-form-field>

      <div class="d-flex justify-content-center">
        <button type="button" mat-flat-button color="primary"  (click)="takeImage()">Establecer foto</button>
      </div>
      <textarea matInput formControlName="image" hidden></textarea>

      <div class="image-container">
        <img [src]="image" class="img-fluid" />
      </div>

    </mat-form-field>

    <mat-form-field>
      <mat-label>NIF/DNI</mat-label>
      <input matInput formControlName="legalId">
      <mat-error *ngIf="frmEmployee.hasError('required', 'legalId')">NIF/DNI es requerido</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Nombre</mat-label>
      <input matInput formControlName="firstName">
      <mat-error *ngIf="frmEmployee.hasError('required', 'firstName')">Nombre es requerido</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Apellidos</mat-label>
      <input matInput formControlName="lastName">
      <mat-error *ngIf="frmEmployee.hasError('required', 'lastName')">Apellidos es requerido</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Dirección</mat-label>
      <input matInput formControlName="address">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Código Postal</mat-label>
      <input matInput formControlName="postalCode">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Ciudad</mat-label>
      <input matInput formControlName="city">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Provincia</mat-label>
      <input matInput formControlName="state">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Teléfono</mat-label>
      <input matInput formControlName="phoneNumber">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Email</mat-label>
      <input matInput formControlName="email">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Notas</mat-label>
      <textarea matInput formControlName="notes"></textarea>
    </mat-form-field>

  </form>

</div>