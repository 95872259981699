import { IAggregateExDTO, ISimpleExDTO } from '../defs';

export enum RatingValue {
  No,
  Neutral,
  Yes
}

export interface IJobListDTO extends IAggregateExDTO {
  task: ISimpleExDTO;
  endDate: string;
  synced: boolean;
  startDate: string;
  startTime?:string;
  locationName:string;
  numWorkers:number;
}


export interface IJobDetailDTO extends IAggregateExDTO {
  task: ISimpleExDTO;
  startDate?: Date;
  startTime?: string;
  endTime?: string;
  employees: IJobEmployeeDTO[];
  latitude?: number;
  longitude?: number;
  notes?: string;
  locationNotes?: string;
  cropUnit?: number;
  locationName?: string;
  closed?: boolean;
}

export interface IJobEmployeeDTO {
  employee: ISimpleExDTO;
  startTime?: string;
  endTime?: string;
  totalHours?: number;
  totalMinutes?: number;
  rating?: RatingValue;
  ratingQuantity?: number;
}

export interface IGetJobListRequest {
  startDate?: string;
  endDate?: string;
  Tasks?: string[];
}

export interface ICreateUpdateJobRequest {
  taskId: string;
  startDate?: Date;
  startTime?: string;
  endTime?: string;
  latitude?: number;
  longitude?: number;
  notes?: string;
  locationNotes?: string;
  locationName?: string;
  employees: IJobEmployeeRequest[];
  employeesBypass: string[];
}

export interface IJobEmployeeRequest {
  employeeId: string;
  totalHours?: number;
}

export interface ICreateUpdateJobResult {
  success: boolean;
  problems: string[];
  jobId: string;
}