import { Observable } from 'rxjs';

export interface IAggregateExDTO {
  uid: string;
  createDate: Date;
}

export interface ISimpleExDTO extends IAggregateExDTO {
  name: string;
}

export interface ISimpleDTO {
  id: number;
  name: string;
}

export interface IChunkedListDTO<T> {
  records: T[];
  totalRecords: number;
}

export interface ICanSaveChanges {
  hasChanges: boolean;
  saveChanges(): Observable<boolean>;
}

export interface ITimeLapseDTO {
  hours: number;
  minutes: number;
}
export interface SyncResult{
  taskName:string;
  locationName?:string;
  success:boolean;
  error?:string;
}