import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { EmployeesService } from './employees.service';
import { IEmployeeDetailDTO } from './employees.defs';

@Injectable({
  providedIn: 'root'
})
export class EmployeeResolver implements Resolve<any> {

  constructor(private service: EmployeesService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<IEmployeeDetailDTO> {
    return this.service.getEmployeeById(route.params.id);
  }

}
