import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { ISimpleExDTO } from '@app/core';
import { JobTasksService } from '@app/core/job-tasks';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-job-task-selector',
  templateUrl: './job-task-selector.component.html',
  styleUrls: ['./job-task-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => JobTaskSelectorComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => JobTaskSelectorComponent),
      multi: true,
    }
  ]
})
export class JobTaskSelectorComponent implements OnInit, ControlValueAccessor, Validator {

  @Input()
  public multiple: boolean;

  @Input()
  public task: ISimpleExDTO;

  @Input()
  public readOnly: boolean;

  @Input()
  public tasks: ISimpleExDTO[];

  public records$: BehaviorSubject<ISimpleExDTO[]>;

  public onChange = (_: any) => { };
  public onTouch = () => { };

  constructor(
    private service: JobTasksService
  ) {
    this.tasks = [];
    this.records$ = new BehaviorSubject(null);
  }

  public ngOnInit(): void {
    this.service.getJobTaskList().subscribe(tasks => this.records$.next(tasks));
  }

  public validate(control: AbstractControl): ValidationErrors {

    if (this.task) {
      return null;
    }

    return {
      taskSelector: {
        valid: false,
      }
    };

  }

  public registerOnValidatorChange?(fn: () => void): void {
  }

  public writeValue(obj: any): void {
    if (this.multiple) {
      this.tasks = obj || [];
    } else {
      this.task = obj;
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }

  public selectedChanged($event: any): void {

    const task = $event.option.value as ISimpleExDTO;

    if (this.multiple) {
      const idx = this.tasks.findIndex(t => t.uid === task.uid);
      if (idx < 0) {
        this.tasks.push(task);
      } else {
        this.tasks.splice(idx, 1);
      }
    } else {
      this.task = $event.option.value;
    }

    this.onTouch();
    this.onChange(this.multiple ? this.tasks : this.task);
  }

  public isRecordSelected(record: ISimpleExDTO): boolean {
    return this.multiple ? this.tasks?.some(t => t.uid === record.uid) : record.uid === this.task?.uid;
  }

}
