<div class="alert alert-success">

  <p>El proceso de registro se ha completado.</p>
  <p>
    Le sugerimos como siguiente paso, comenzar a añadir empleados a su organización, empezando por usted mismo
  </p>

  <a mat-flat-button color="primary" [routerLink]="['/employees/new']">
    Añadir empleado
  </a>

</div>