import { CanActivate, CanActivateChild, Router, UrlSegment, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SubscriptionService } from './subscription.service';
import { switchMap } from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';


const allowedRoutes = [
  '/subscription/welcome',
  '/subscription/enable',
];

@Injectable({
  providedIn: 'root'
})
export class SubscriptionGuard implements CanActivateChild {

  constructor(private service: SubscriptionService, private router: Router) {
  }

  public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

    if (allowedRoutes.indexOf(state.url) > -1) {
      return true;
    }

    return this.service.hasSubscription()
      .pipe(
        switchMap(value => {

          if (value) {
            return of(true);
          }

          return from(this.router.navigate(['/subscription/welcome']));

        })
      );
  }
}
