import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';
import { UsersListComponent } from './list/users-list.component';
import { UserDetailContainerComponent } from './detail-container/detail-container.component';
import { ConfirmRegisterUserComponent } from './confirm-register/confirm-register-user.component';
import { ConfirmResetComponent } from './confirm-reset/confirm-reset.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { UserToUsersComponent } from './user-to-users/user-to-users.component';
import { UserEmployeesComponent } from './user-employees/user-employees.component';
import { AddUserComponent } from './add/add-user.component';


@NgModule({
  declarations: [
    UsersListComponent,
    UserDetailContainerComponent,
    ConfirmRegisterUserComponent,
    ConfirmResetComponent,
    AddUserComponent,
    ForgotPasswordComponent,
    UserToUsersComponent,
    UserEmployeesComponent,
  ],
  imports: [
    SharedModule
  ]
})
export class UsersModule { }
