<mat-drawer-container autosize>

  <mat-drawer [mode]="sidenavMode" [opened]="sidenavVisible$ | async" (openedChange)="sidenavOpenedChanged($event)">
    <mat-nav-list>
      <ng-container *ngFor="let button of sidenavButtons$ | async" >
        <mat-divider *ngIf="button.beginSeparator"></mat-divider>
        <mat-list-item (click)="sidenavClick(button)" *ngIf="button.visible$ ? (button.visible$ | async) : true">
          {{ button.text }}
        </mat-list-item>
        </ng-container>
    </mat-nav-list>
  </mat-drawer>

  <mat-drawer-content>

    <app-toolbar></app-toolbar>

    <div class="content-container">
      <router-outlet>
      </router-outlet>
    </div>

  </mat-drawer-content>

</mat-drawer-container>