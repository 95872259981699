import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SharedModule } from '@app/shared';
import { ConfirmRegisterUserResolver, ConfirmResetResolver } from '@app/core/users';

import { UsersListComponent } from './list/users-list.component';
import { AddUserComponent } from './add/add-user.component';
import { ConfirmRegisterUserComponent } from './confirm-register/confirm-register-user.component';
import { ConfirmResetComponent } from './confirm-reset/confirm-reset.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { UserDetailContainerComponent } from './detail-container/detail-container.component';

export const UsersRoutes: Routes = [
  {
    path: 'users',
    component: UsersListComponent
  },
  {
    path: 'users/new',
    component: AddUserComponent,
  },
  {
    path: 'users/:id',
    component: UserDetailContainerComponent
  },

];

const publicRoutes: Routes = [
  {
    path: 'users/forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'users/confirm-register',
    component: ConfirmRegisterUserComponent,
    resolve: {
      registration: ConfirmRegisterUserResolver
    }
  },
  {
    path: 'users/confirm-reset',
    component: ConfirmResetComponent,
    resolve: {
      registration: ConfirmResetResolver
    }
  }
]
;


@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(publicRoutes)
  ],
  exports: [RouterModule]
})
export class UsersRoutingModule { }
