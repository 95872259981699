import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';

import { SubscriptionService } from '../subscription';
import { switchMap } from 'rxjs/operators';
import { EmployeesService } from './employees.service';

@Injectable({
  providedIn: 'root'
})
export class AddEmployeeGuard implements CanActivate {

  constructor(
    private empService: EmployeesService,
    private service: SubscriptionService,
    private router: Router) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    return this.empService.getEmployeeList({ take: 1 })
      .pipe(

        switchMap(emps => {

          if (!emps.totalRecords) {
            return of(true);
          }

          return this.service.getSubscriptionStatus().pipe(
            switchMap(subscription => {

              if (!subscription.active) {
                return this.router.navigate(['/subscription/status']);
              }

              return of(true);

            })
          );

        })

      );

  }
}
