<div class="options-container">

  <div class="row">

    <div class="col-4">
      <mat-form-field appearance="fill">
        <mat-label>Fecha inicio</mat-label>
        <input matInput [matDatepicker]="startDatePicker" (click)="startDatePicker.open()" [(ngModel)]="startDate"
          (ngModelChange)="dateChanged()" autocomplete="false" readonly>
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="col-4">
      <mat-form-field appearance="fill">
        <mat-label>Fecha fin</mat-label>
        <input matInput [matDatepicker]="endDatePicker" (click)="endDatePicker.open()" [(ngModel)]="endDate"
          (ngModelChange)="dateChanged()" autocomplete="false" readonly>
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="col-4">
      <mat-form-field>
        <mat-label>Agrupación</mat-label>
        <mat-select [(ngModel)]="request.groupBy" (ngModelChange)="loadReport()">
          <mat-option [value]="option.id" *ngFor="let option of groupByOptions">
            {{ option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-6">
      <app-job-task-selector-input [selectedTasks]="selectedTasks" (selectedChanged)="selectedTasksChanged($event)">
      </app-job-task-selector-input>
    </div>

    <div class="col-6">
      <app-employee-selector-input [selectedEmployees]="selectedEmployees" (selectedChanged)="selectedEmployeesChanged($event)">
      </app-employee-selector-input>
    </div>

  </div>

</div>


<app-spinner *ngIf="loading">
</app-spinner>

<div class="data-container" *ngIf="report$ | async as report">

  <ng-container *ngIf="!report.entries.length">
    <div class="alert alert-success">
      <h3 class="text-center">
        <div>
          <mat-icon>timeline</mat-icon>
        </div>
        No se encuentran registros con los filtros actuales
      </h3>
    </div>
  </ng-container>

  <ng-container *ngIf="report.entries.length">
    <div class="row" *ngFor="let record of report.entries; let index = index">

      <div class="col-6">
        {{ record.employee.name }}
      </div>

      <div class="col-6 text-right">
        {{ record.group }}
      </div>

      <div class="col-12">

        <div class="chart">
          <canvas baseChart [data]="chartData[index]" [labels]="chartLabels[index]" chartType="pie"
            [options]="chartOptions">
          </canvas>
        </div>

      </div>

      <div class="col-12 text-right font-weight-bold">
        Total {{ record.totalTime | timeLapse }}
      </div>

      <div class="col-12">
        <hr />
      </div>

    </div>

    
    <div class="row" *ngIf="report.locations.length">
      <div class="col-12">

        <app-report-locations [locations]="report.locations"></app-report-locations>

      </div>
    </div>

  </ng-container>

</div>