import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { ISimpleExDTO } from '../defs';
import { JobTasksService } from './job-tasks.service';

@Injectable({
  providedIn: 'root'
})
export class JobTaskResolver implements Resolve<any> {

  constructor(private service: JobTasksService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<ISimpleExDTO> {
    return this.service.getJobTaskById(route.params.id);
  }

}
