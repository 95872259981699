import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared';
import { MainComponent } from './main/main.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';


@NgModule({
  declarations: [
    MainComponent,
    ToolbarComponent,
    PageNotFoundComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    MainComponent
  ]
})
export class LayoutModule { }
