import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IErrorResponse, RequestService } from '../_common';
import { IConfirmRegisterOrganisationRequest, ILegalInfoDTO, IRegisterOrganisationRequest } from './defs';

@Injectable({
  providedIn: 'root'
})
export class OrganisationsService {

  constructor(
    private requestService: RequestService
  ) { }

  public register(request: IRegisterOrganisationRequest): Observable<any | IErrorResponse> {
    return this.requestService.post('api/public/organisations/register', request);
  }

  public confirmRegister(request: IConfirmRegisterOrganisationRequest): Observable<any | IErrorResponse> {
    return this.requestService.post('api/public/organisations/confirm', request);
  }

  public getLegalInfo(): Observable<ILegalInfoDTO> {
    return this.requestService.get('api/organisations/legal-info');
  }

  public setLegalInfo(request: ILegalInfoDTO): Observable<any | IErrorResponse> {
    return this.requestService.post('api/organisations/legal-info', request);
  }

}
