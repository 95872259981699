import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { switchMap } from 'rxjs/operators';

import { PaymentMethodsService } from '@app/core/subscription';

@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss']
})
export class CreditCardComponent implements OnInit {

  @ViewChild(StripeCardComponent)
  public card: StripeCardComponent;

  public frmCard: FormGroup;
  public cardComplete: boolean;
  public error: string;
  public working: boolean;

  public cardOptions: StripeCardElementOptions = {
    hidePostalCode: true,
    iconStyle: 'solid',
  };

  public elementsOptions: StripeElementsOptions = {
    locale: 'es'
  };

  constructor(
    private fb: FormBuilder,
    private stripeService: StripeService,
    private service: PaymentMethodsService,
    private dialogRef: MatDialogRef<CreditCardComponent>,
  ) {
  }

  public ngOnInit(): void {

    this.frmCard = this.fb.group({
      cardHolder: ['', [Validators.required]]
    });

  }

  public confirmPaymentMethod(): void {

    this.error = '';
    this.working = true;

    this.service
      .prepare()
      .pipe(
        switchMap(clientSecret => {

          return this.stripeService
            .confirmCardSetup(clientSecret, {
              payment_method: {
                card: this.card.element,
                billing_details: {
                  name: this.frmCard.get('cardHolder').value
                }
              }
            });

        }),

      )

      .subscribe(result => {

        if (result.error) {
          this.working = false;
          this.error = result.error.message;
        } else {

          this.service.reload().subscribe(() => {
            this.dialogRef.close(result);
            this.working = false;
          });

        }

      }, () => this.working = false);

  }

  public cardChanged($event): void {
    this.cardComplete = $event.complete;
  }

}
