import { ISimpleExDTO } from '../defs';

export interface IEmployeeListDTO extends ISimpleExDTO {
  legalId: string;
  phoneNumber: string;
}


export interface IEmployeeDetailDTO {
  uid: string;
  name: string;
  firstName: string;
  lastName: string;

  address: string;
  postalCode: string;
  city: string;
  province: string;
  state: string;

  phoneNumber: string;
  legalId: string;
  email: string;
  notes: string;

  image: string;
}


export interface IGetEmployeeListRequest {
  skip?: number;
  take?: number;
  searchText?: string;
}

export interface IGetEmployeeMatchRequest {
  image: string;
}

export interface IEmployeeMatchDTO {
  isNotLive: boolean;
  entries: IEmployeeMatchEntryDTO[];
}

export interface IEmployeeMatchEntryDTO {
  employee: IEmployeeListDTO;
  similarity: number;
}
