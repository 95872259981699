import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { RequestService } from '../_common';
import { IPaymentDTO } from './defs';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  constructor(
    private requestService: RequestService
  ) { }

  public getPaymentList(): Observable<IPaymentDTO[]> {
    return this.requestService.get('api/payments');
  }

}
