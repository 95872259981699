import { Routes } from '@angular/router';

import { UserRole } from '@app/core/auth';
import { ConfigurationContainerComponent } from './container/configuration-container.component';

export const ConfigurationRoutes: Routes = [
  {
    path: 'configuration',
    data: { roles: [UserRole.Admin, UserRole.Coordinator] },
    component: ConfigurationContainerComponent,
    children: []
  }
];
