import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

import { IJobDetailDTO, JobsService } from '@app/core/jobs';
import { VisualService } from '@app/core/visual';
import { MessageBoxService } from '@app/shared';

@Component({
  selector: 'app-sync-job',
  templateUrl: './sync-job.component.html',
  styleUrls: ['./sync-job.component.scss']
})
export class SyncJobComponent implements OnInit {

  public job$: BehaviorSubject<IJobDetailDTO>;
  public working: boolean;
  private jobId: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: JobsService,
    private visualService: VisualService,
    private msgbox: MessageBoxService
  ) {
    this.job$ = new BehaviorSubject(null);
  }

  public ngOnInit(): void {

    this.route.paramMap.subscribe(prms => {

      if (prms.has('jobId')) {

        this.jobId = prms.get('jobId');

        this.service
          .getJobById(this.jobId)
          .subscribe(job => {

            if (!this.visualService.canSyncJob(job)) {
              this.router.navigate(['/jobs']);
              return;
            }

            this.job$.next(job);

          });
      }

    });

  }

  public syncJob(): void {

    this.working = true;
    this.visualService.syncJob(this.jobId)
    .pipe(
      catchError(err => {
        this.working = false;
        return throwError(err);
      })
    )
    .subscribe(() => {
      this.msgbox.showSuccess('Parte sincronizado');
      this.router.navigate(['/jobs']);
    });

  }

}
