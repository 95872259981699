import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ConfirmRegisterOrganisationResolver } from '@app/core/organisations';
import { SharedModule } from '@app/shared';

import { ConfirmRegisterOrganisationComponent } from './confirm-register/confirm-register-organisation.component';
import { RegisterComponent } from './register/register.component';
import { OrganisationWelcomeComponent } from './welcome/organisation-welcome.component';

export const OrganisationsRoutes: Routes = [
  {
    path: 'organisations/welcome',
    component: OrganisationWelcomeComponent
  },
];

const publicRoutes: Routes = [
  {
    path: 'organisations/register',
    component: RegisterComponent
  },
  {
    path: 'organisations/confirm-register',
    component: ConfirmRegisterOrganisationComponent,
    resolve: {
      registration: ConfirmRegisterOrganisationResolver
    }
  },
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(publicRoutes)
  ],
  exports: [RouterModule]
})
export class OrganisationsRoutingModule { }
