<div class="d-flex flex-row h-100">

  <div class="col d-flex flex-column align-items-center justify-content-center">

    <img src="/assets/logo.svg" />
    <h1>Visual Selphi</h1>

    <div class="content-container">

      <div class="alert alert-success" *ngIf="success">
        Le hemos enviado un email de confirmación.
        Por favor compruebe su correo para continuar con el proceso de registro.
      </div>
    
      <form [formGroup]="frmRegister" class="formRegister" (ngSubmit)="doRegister()" [hidden]="success" novalidate="novalidate">

        <div class="alert alert-danger" *ngIf="error" [innerHtml]="error">
        </div>

        <mat-form-field>
          <input matInput type="text" name="organisation" formControlName="organisation" required />
          <mat-label>Empresa</mat-label>
          <mat-error *ngIf="submitted && frmRegister.hasError('required', 'organisation')">Empresa es requerida
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <input matInput type="email" name="userName" formControlName="userName" required />
          <mat-label>Email</mat-label>
          <mat-error *ngIf="submitted && frmRegister.hasError('required', 'userName')">Email es requerido</mat-error>
          <mat-error *ngIf="submitted && frmRegister.hasError('email', 'userName')">Email no válido</mat-error>
        </mat-form-field>

        <mat-checkbox formControlName="termsAccepted" required>
          Acepto los <a [href]="termsUrl" target="_blank">términos de uso</a>
        </mat-checkbox>

        <button mat-flat-button type="submit" color="primary" class="w-100" [disabled]="shouldDisableSubmit()">
          <mat-icon *ngIf="working">
            <mat-spinner diameter="20" color="accent"></mat-spinner>
          </mat-icon>
          <span>Registrar</span>
        </button>

        <mat-divider class="mt-2 mb-2"></mat-divider>

        <div>También puede</div>
        <a mat-flat-button color="accent" class="mb-1" [routerLink]="['/auth/login']">
          Iniciar sesión
        </a>


      </form>

    </div>

  </div>

  <div class="col col-background">

  </div>

</div>