import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ITimeLapseDTO } from '../defs';

import { RequestService } from '../_common';

import {
  IReportByEmployeeDTO,
  IGetReportByEmployeeRequest,
  IReportByJobDTO,
  IReportByDayDTO,
  IGetReportByDayRequest,
  IGetReportByJobRequest,
  IReportDef
} from './defs';


@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  private reports: IReportDef[];

  constructor(
    private request: RequestService
  ) {
    this.reports = [];
  }

  public getReports(): IReportDef[] {
    return this.reports;
  }

  public registerReports(reports: IReportDef[]): void {
    this.reports.push(...reports);
  }

  public getReportByEmployee(request: IGetReportByEmployeeRequest): Observable<IReportByEmployeeDTO> {
    return this.request.get('/api/reports/employees', request);
  }

  public exportReportByEmployee(request: IGetReportByEmployeeRequest): Observable<any> {
    return this.request.get('/api/reports/employees/export', request, { responseType: 'blob' });
  }

  public getReportByJob(request: IGetReportByJobRequest): Observable<IReportByJobDTO> {
    return this.request.get('/api/reports/jobs', request);
  }

  public exportReportByJob(request: IGetReportByJobRequest): Observable<any> {
    return this.request.get('/api/reports/jobs/export', request, { responseType: 'blob' });
  }

  public getReportByDay(request: IGetReportByDayRequest): Observable<IReportByDayDTO> {
    return this.request.get('/api/reports/day', request);
  }

  public exportReportByDay(request: IGetReportByDayRequest): Observable<any> {
    return this.request.get('/api/reports/day/export', request, { responseType: 'blob' });
  }

  public formatTime(value: ITimeLapseDTO): string {
    return `${value.hours ? value.hours + ' H ' : ''} ${value.minutes ? value.minutes + 'm' : ''}`;
  }

}
