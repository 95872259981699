import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '@app/core/auth';
import { IErrorResponse } from '@app/core';
import { IForgotPasswordRequest, UsersService } from '@app/core/users';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {

  @ViewChild('formForgot', { static: true })
  public formForgot: NgForm;

  public request: IForgotPasswordRequest;
  public working: boolean;
  public error: string;
  public success: boolean;

  constructor(
    private usersService: UsersService
  ) {
    this.request = {
    } as any;
  }

  public shouldDisableButton(): boolean {
    return !this.formForgot.form.valid || this.working;
  }

  public doLogin(): void {

    this.working = true;
    this.error = '';

    this.usersService
      .resetPassword(this.request)
      .pipe(
        catchError((response: IErrorResponse) => {
          // catch the error to avoid global error handler catching it
          return of(response);
        })
      )
      .subscribe(response => {

        const resp = response as IErrorResponse;
        if (resp.error) {
          this.error = 'Error de servidor';
        } else {
          this.success = true;
        }

        this.working = false;

      });

  }
}
