import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatSelectionListChange } from '@angular/material/list';
import { BehaviorSubject, forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';

import { ISimpleExDTO } from '@app/core';
import { UserEmployeesService } from '@app/core/user-employees';
import { EmployeesService } from '@app/core/employees';
import { MessageBoxService } from '@app/shared';

@Component({
  selector: 'app-user-employees',
  templateUrl: './user-employees.component.html',
  styleUrls: ['./user-employees.component.scss']
})
export class UserEmployeesComponent implements OnInit, OnDestroy {

  @Input()
  public ownerId: string;

  public records$: BehaviorSubject<ISimpleExDTO[]>;
  public selectedUsers: string[];

  private data$: Subject<string[]>;
  private subscription: Subscription;

  public loading: boolean;

  constructor(
    private employeesService: EmployeesService,
    private service: UserEmployeesService,
  ) {

    this.records$ = new BehaviorSubject(null);
    this.data$ = new Subject<string[]>();

    this.subscription = this.data$
      .pipe(
        debounceTime(1000),
        switchMap(employeeIds => this.service.setEmployeesToUser({
          ownerId: this.ownerId,
          employeeIds
        }))
      ).subscribe(value => {
      });
  }

  public ngOnInit(): void {

    this.selectedUsers = [];
    this.loading = true;

    forkJoin([
      this.employeesService.getEmployeeList(),
      this.service.getEmployees(this.ownerId)
    ]).subscribe(datas => {

      this.records$.next(datas[0].records);
      this.selectedUsers = datas[1].map(r => r.uid);
      this.loading = false;

    });

  }

  public isRecordSelected(record: ISimpleExDTO): boolean {
    return this.selectedUsers.indexOf(record.uid) > -1;
  }

  public selectedChanged($event: MatSelectionListChange): void {

    const option = $event.options[0];
    const value: ISimpleExDTO = option.value;

    if (!option.selected) {
      const idx = this.selectedUsers.indexOf(value.uid);
      this.selectedUsers.splice(idx, 1);
    } else {
      this.selectedUsers.push(value.uid);
    }

    this.data$.next(this.selectedUsers);

  }

  public selectAll(): void {
    this.selectedUsers = this.records$.value.map(r => r.uid);
    this.data$.next(this.selectedUsers);
  }

  public selectNone(): void {
    this.selectedUsers = [];
    this.data$.next(this.selectedUsers);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
