import { Component, OnInit } from '@angular/core';
import { ISimpleExDTO } from '@app/core';
import { JobTasksService } from '@app/core/job-tasks';
import { LayoutService } from '@app/core/layout';
import { MessageBoxService } from '@app/shared';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
  selector: 'app-job-task-list',
  templateUrl: './job-task-list.component.html',
  styleUrls: ['./job-task-list.component.scss']
})
export class JobTaskListComponent implements OnInit {

  public tasks$: Subject<ISimpleExDTO[]>;
  public loading: boolean;
  public totalRecords: number;

  constructor(
    private service: JobTasksService,
    private layout: LayoutService,
    private msgbox: MessageBoxService
  ) {
    this.loading = true;
    this.tasks$ = new BehaviorSubject(null);

    this.layout.setToolbar({
      showMenuButton: true,
      title: 'Tareas'
    });

  }

  public ngOnInit(): void {
    this.loadData();
  }

  private loadData(): void {
    this.service
      .getJobTaskList()
      .subscribe(tasks => {
        this.totalRecords = tasks.length;
        this.tasks$.next(tasks);
        this.loading = false;
      }, (ex) => {
        this.loading = false;
        throw ex;
      });
  }

  public deleteTask(task: ISimpleExDTO): void {

    this.msgbox.showYesNo({ title: '¿Eliminar tarea?' })
      .subscribe(val => {

        if (val) {

          this.loading = true;
          this.service.deleteJobTask(task.uid).subscribe(() => {
            this.loadData();
          }, (ex) => {
            this.loading = false;
            throw ex;
          });

        }

      });
  }

}
