<app-input-search *ngIf="showSearch" (changed)="searchChanged($event)"></app-input-search>

<app-spinner *ngIf="loading; else employeeList">
</app-spinner>

<ng-template #employeeList>
  <ng-container *ngIf="employees$ | async as employees">

    <div class="alert alert-success" *ngIf="!totalRecords">

      <h3 class="text-center">
        <div>
        <mat-icon>supervised_user_circle</mat-icon>
      </div>
        ¡Vaya! No hay empleados creados
      </h3>
      <p class="text-center">
        Para añadir un empleado, haz click en el botón + que aparece abajo a la derecha.
      </p>
    </div>

    <cdk-virtual-scroll-viewport class="list-container" itemSize="72">

      <mat-action-list>
        <ng-container *cdkVirtualFor="let employee of employees">
          <mat-list-item [routerLink]="['/employees', employee.uid]">
            <mat-icon mat-list-icon>person</mat-icon>
            <h3 matLine>{{employee.name }}</h3>
            <p matLine>
              <span> {{employee.legalId }} </span>
              <span *ngIf="employee.phoneNumber">
                | <a href="tel:+34{{employee.phoneNumber }}">{{employee.phoneNumber}}</a>
              </span>
            </p>
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Menu empleado"
              (click)="$event.stopPropagation()">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="deleteEmployee(employee)">
                <mat-icon>delete</mat-icon>
                <span>Eliminar</span>
              </button>
            </mat-menu>

          </mat-list-item>
        </ng-container>
      </mat-action-list>

    </cdk-virtual-scroll-viewport>

  </ng-container>
</ng-template>


<div class="add-button-container">
  <a mat-fab color="primary" [routerLink]="['/employees/new']">
    <mat-icon>add</mat-icon>
  </a>
</div>