import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from '..';

import { IPaymentMethodDTO } from './defs';

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodsService {

  constructor(
    private requestService: RequestService
  ) { }

  /**
   * Get list of payment methods from organisation
   */
  public getList(): Observable<IPaymentMethodDTO[]> {
    return this.requestService.get('api/payment-methods');
  }

  /**
   * Sync/Reload payment methods from stripe
   */
  public reload(): Observable<string> {
    return this.requestService.post('api/payment-methods/reload');
  }

  /**
   * Create client secret for adding new payment method
   */
  public prepare(): Observable<string> {
    return this.requestService.post('api/payment-methods/prepare');
  }
}
