import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { IErrorResponse, RequestService } from '../_common';
import { ILoginRequest, ISessionInfo, UserRole } from './defs';


const SESSION_KEY = 'visual-selphi-session';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private session: ISessionInfo;

  constructor(
    private requestService: RequestService,
    private router: Router,
  ) { }

  public isAuthenticated(): boolean {
    const session = this.getSession();
    return session != null;
  }

  public getSession(): ISessionInfo {

    if (!this.session) {
      const d = localStorage.getItem(SESSION_KEY);
      if (d) {
        this.session = JSON.parse(d);
      }
    }

    return this.session;
  }

  private saveSession(): void {
    localStorage.setItem(SESSION_KEY, JSON.stringify(this.session));
  }

  public clearSession(): void {
    localStorage.removeItem(SESSION_KEY);
  }

  public login(request: ILoginRequest): Observable<ISessionInfo | IErrorResponse> {

    return this.requestService.postUrlEncoded('/api/auth', {
      ...request,
      grant_type: 'password'
    }).pipe(
      switchMap(response => {

        this.session = {
          id: response['as:client_id'],
          token: response.access_token,
          refreshToken: response.refresh_token,
          userName: response.userName,
          role: parseInt(response.role, 0),
          expires: new Date(response['.expires']),
        };

        this.clearSession();
        if (request.remember) {
          this.saveSession();
        }

        return of(this.session);

      })
    );
  }

  /**
   * Try refreshing auth token
   */
  public refreshToken(): Observable<any> {

    const session = this.getSession();
    if (!session || !session.refreshToken) {
      return throwError({ status: 401 });
    }

    return this.requestService.postUrlEncoded('/api/auth', {
      client_id: session.id,
      grant_type: 'refresh_token',
      refresh_token: session.refreshToken
    }).pipe(

      tap(response => {

        this.session = {
          ...this.session,
          token: response.access_token,
          refreshToken: response.refresh_token,
          expires: new Date(response['.expires']),
        };

        this.saveSession();

      })

    );

  }

  public logout(): void {

    delete this.session;
    localStorage.removeItem(SESSION_KEY);
    this.router.navigate(['/auth/login']);

  }

  public isRole(role: UserRole): boolean {
    const session = this.getSession();
    return session?.role === role;
  }

}
