import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, pipe, Subject, Subscription } from 'rxjs';

import { ISimpleExDTO } from '@app/core';
import { UserToUsersService } from '@app/core/user-to-users';
import { UsersService } from '@app/core/users';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { UserRole } from '@app/core/auth';

@Component({
  selector: 'app-user-to-users',
  templateUrl: './user-to-users.component.html',
  styleUrls: ['./user-to-users.component.scss']
})
export class UserToUsersComponent implements OnInit, OnDestroy {

  @Input()
  public ownerId: string;

  public loading: boolean;

  public records$: BehaviorSubject<ISimpleExDTO[]>;
  public selectedIds: string[];

  private data$: Subject<string[]>;
  private subscription: Subscription;

  constructor(
    private usersService: UsersService,
    private service: UserToUsersService
  ) {

    this.records$ = new BehaviorSubject(null);
    this.data$ = new Subject<string[]>();

    this.subscription = this.data$
      .pipe(
        debounceTime(1000),
        switchMap(userIds => this.service.setUsersToUser({
          ownerId: this.ownerId,
          userIds
        }))
      ).subscribe(value => {

      });

  }

  public ngOnInit(): void {

    this.selectedIds = [];
    this.loading = true;

    forkJoin([
      this.usersService.getUserList({
        roleId: UserRole.Regular,
        excludeIds: [this.ownerId]
      }),
      this.service.getUsers(this.ownerId)
    ]).subscribe(datas => {

      this.records$.next(datas[0].records);
      this.selectedIds = datas[1].map(r => r.uid);
      this.loading = false;

    });

  }

  public selectAll(): void {
    this.selectedIds = this.records$.value.map(r => r.uid);
    this.data$.next(this.selectedIds);
  }

  public selectNone(): void {
    this.selectedIds = [];
    this.data$.next(this.selectedIds);
  }

  public selectedChanged($event): void {

    const option = $event.options[0];
    const value: ISimpleExDTO = option.value;

    if (!option.selected) {
      const idx = this.selectedIds.indexOf(value.uid);
      this.selectedIds.splice(idx, 1);
    } else {
      this.selectedIds.push(value.uid);
    }

    this.data$.next(this.selectedIds);
  }

  public isRecordSelected(record: ISimpleExDTO): boolean {
    return this.selectedIds.indexOf(record.uid) > -1;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
