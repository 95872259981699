import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';
import { OrganisationsModule } from '@app/organisations';
import { PaymentsModule } from '@app/payments';

import { CreditCardComponent } from './credit-card/credit-card.component';
import { PaymentMethodsComponent } from './payment-methods/payment-methods.component';
import { SubscriptionStatusComponent } from './status/subscription-status.component';
import { EnableSubscriptionComponent } from './enable/enable-subscription.component';
import { SubscriptionCreatedComponent } from './created/subscription-created.component';
import { SubscriptionWelcomeComponent } from './welcome/subscription-welcome.component';


@NgModule({
  declarations: [
    CreditCardComponent,
    PaymentMethodsComponent,
    SubscriptionStatusComponent,
    EnableSubscriptionComponent,
    SubscriptionCreatedComponent,
    SubscriptionWelcomeComponent
  ],
  imports: [
    SharedModule,
    OrganisationsModule,
    PaymentsModule
  ]
})
export class SubscriptionModule { }
