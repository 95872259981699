import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

import { MessageBoxComponent } from './message-box.component';


@Injectable({
  providedIn: 'root'
})
export class MessageBoxService {

  constructor(
    private dialog: MatDialog,
    private toastr: ToastrService
  ) { }

  public showYesNo(data: { title: string }): Observable<any> {
    return this.dialog.open(MessageBoxComponent, { data }).afterClosed();
  }

  public showDialog(data: { title: string, timeOut?: number }): Observable<any> {
    (data as any).hideYesNo = true;
    return this.dialog.open(MessageBoxComponent, { data }).afterClosed();
   
  }

  public showSuccess(message: string): void {
    this.toastr.success(message);
  }

  public showError(message: string): void {
    this.toastr.error(message);
  }

}
