<div class="alert alert-info mb-0">
  ¿Sincronizar el parte en VISUAL?
</div>

<div *ngIf="job$ | async">

  <div class="d-flex justify-content-between p-2">

    <app-spin-button text="Sincronizar" [working]="working" (click)="syncJob()">
    </app-spin-button>

    <button mat-flat-button color="primary" type="button" [routerLink]="['/jobs']">
      Ahora no
    </button>

  </div>

</div>