<app-spinner *ngIf="loading; else jobList">
</app-spinner>

<ng-template #jobList>
  <ng-container *ngIf="jobs$ | async as jobs">

    <div class="alert alert-success" *ngIf="!totalRecords">

      <h3 class="text-center">
        <div>
        <mat-icon>schedule</mat-icon>
      </div>
        ¡Vaya! No hay trabajos creados
      </h3>
      <p class="text-center">
        Para añadir un trabajo, haz click en el botón + que aparece abajo a la derecha.
      </p>
    </div>
    <div class="row">

      <div class="col-6">
        <mat-form-field appearance="fill">
          <mat-label>Fecha inicio</mat-label>
          <input matInput [matDatepicker]="startDatePicker" (click)="startDatePicker.open()" [(ngModel)]="startDate"
            (ngModelChange)="dateChanged()" autocomplete="false" readonly>
          <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field appearance="fill">
          <mat-label>Fecha fin</mat-label>
          <input matInput [matDatepicker]="endDatePicker" (click)="endDatePicker.open()" [(ngModel)]="endDate"
            (ngModelChange)="dateChanged()"  autocomplete="false" readonly>
          <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-12">
        <app-job-task-selector-input [selectedTasks]="selectedTasks" (selectedChanged)="selectedTasksChanged($event)">
        </app-job-task-selector-input>
      </div>
    </div>
    <cdk-virtual-scroll-viewport class="list-container" itemSize="72">

      <mat-action-list>
        <ng-container *cdkVirtualFor="let job of jobs">
          <mat-list-item [routerLink]="['/jobs', job.uid]">
            <mat-icon mat-list-icon [ngClass]="job.finished ? 'success' : 'pending'">
              circle
            </mat-icon>
            <h3 matLine style="font-weight: bold;">{{job.task.name }}</h3>
            <p matLine>
              <span> {{job.startDate | date: 'dd MMM yy' }} </span>
              <span>| {{job.startTime }} </span>
              <span *ngIf="job.synced"> (Sincronizado)</span><br>
               <span>Parcela: {{job.locationName || " N/D"}} </span> <br>
               <span>Trabajadores: {{job.numWorkers}}</span>
            </p>
           
              

            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Menu trabajos"
              (click)="$event.stopPropagation()">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="deleteJob(job)">
                <mat-icon>delete</mat-icon>
                <span>Eliminar</span>
              </button>
            </mat-menu>

          </mat-list-item>
        </ng-container>
      </mat-action-list>

    </cdk-virtual-scroll-viewport>
  </ng-container>
</ng-template>

<div class="add-button-container">
  <a mat-fab color="primary" [routerLink]="['/jobs/new']">
    <mat-icon>add</mat-icon>
  </a>
  <a mat-fab color="secondary" (click)="syncJobs()" class="sync-button">
    <mat-icon>cloud_upload</mat-icon>
  </a>
</div>