import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/core/auth';

@Component({
  selector: 'app-logout',
  template: ''
})
export class LogoutComponent implements OnInit {

  constructor(
    private authService: AuthService
  ) { }

  public ngOnInit(): void {
    this.authService.logout();
  }

}
