import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { environment } from 'src/environments/environment';

import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRippleModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { InputSearchComponent } from './input-search/input-search.component';
import { MessageBoxComponent } from './message-box';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';

import { AgmCoreModule } from '@agm/core';
import { AgmJsMarkerClustererModule, ClusterManager } from "@agm/js-marker-clusterer";

import { SpinnerComponent } from './spinner/spinner.component';
import { NgxStripeModule } from 'ngx-stripe';
import { ChartsModule } from 'ng2-charts';
import { TimeLapsePipe } from './time-lapse/time-lapse.pipe';
import { RatingSelectorComponent } from './rating-selector/rating-selector.component';
import { SpinButtonComponent } from './spin-button/spin-button.component';

@NgModule({
  declarations: [
    InputSearchComponent,
    MessageBoxComponent,
    SpinnerComponent,
    TimeLapsePipe,
    RatingSelectorComponent,
    SpinButtonComponent
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es' },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } }
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      preventDuplicates: true
    }),
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsApiKey
    }),
    AgmJsMarkerClustererModule,

    HttpClientModule,
    HttpClientJsonpModule,

    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatSnackBarModule,
    MatSidenavModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    ScrollingModule,
    TimepickerModule.forRoot(),
    NgxStripeModule.forRoot(environment.stripeKey),
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ToastrModule,
    HttpClientModule,
    HttpClientJsonpModule,

    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatMomentDateModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatStepperModule,
    MatToolbarModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    ScrollingModule,
    InputSearchComponent,
    TimepickerModule,
    AgmCoreModule,
    AgmJsMarkerClustererModule,
    SpinnerComponent,
    NgxStripeModule,
    ChartsModule,
    TimeLapsePipe,
    RatingSelectorComponent,
    SpinButtonComponent
  ]
})
export class SharedModule { }
