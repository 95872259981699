import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { MessageBoxService } from '@app/shared';
import { ISimpleExDTO } from '@app/core';
import { JobTasksService } from '@app/core/job-tasks';
import { LayoutService } from '@app/core/layout';

@Component({
  selector: 'app-job-task-detail',
  templateUrl: './job-task-detail.component.html',
  styleUrls: ['./job-task-detail.component.scss']
})
export class JobTaskDetailComponent implements OnInit {

  public jobTask: ISimpleExDTO;
  public frmJobTask: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: JobTasksService,
    private layout: LayoutService,
    private msgbox: MessageBoxService
  ) {
    this.frmJobTask = new FormGroup({
      name: new FormControl('', Validators.required)
    });
  }

  public ngOnInit(): void {
    this.jobTask = (this.route.snapshot.data.jobTask || {}) as ISimpleExDTO;
    this.frmJobTask.patchValue(this.jobTask);

    this.layout.setToolbar({
      title: this.jobTask?.name || 'Nueva tarea',
      showMenuButton: false,
      leftButtons: [
        {
          action: () => this.router.navigate(['job-tasks']),
          icon: 'arrow_back',
        }
      ],
      rightButtons: [
        {
          action: () => this.saveChanges(),
          text: 'Guardar'
        }
      ]
    });

  }

  public saveChanges(): void {

    if (!this.frmJobTask.valid) {
      this.msgbox.showError('Complete los campos requeridos');
      return;
    }

    const request = this.frmJobTask.value;

    const op = !this.jobTask.uid ? this.service.createJobTask(request) : this.service.updateJobTask(this.jobTask.uid, request);
    op.subscribe(() => {
      this.msgbox.showSuccess('Cambios guardados');
      this.router.navigate(['job-tasks']);
    });

  }

}
