<div class="alert alert-success">

  <h3 class="text-center">
    <div>
    <mat-icon>supervised_user_circle</mat-icon>
  </div>
    ¡Gracias!
  </h3>
  <p class="text-center">
    Su subscripción ha sido creada con éxito. 
  </p>
  <p class="text-center">
    Ya tiene acceso a todas las funciones que le ofrece Selphi sin ninguna limitación
  </p>
</div>