import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, EMPTY, throwError, Subject, BehaviorSubject } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';

import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';

import { AuthService } from './auth.service';
import { UserRole } from './defs';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  private refreshTokenInProgress = false;
  private refreshTokenSubject: Subject<any>;

  constructor(
    private auth: AuthService,
    private router: Router
  ) {
    this.refreshTokenSubject = new BehaviorSubject<any>(null);
  }

  private injectToken(request: HttpRequest<any>): any {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.auth.getSession().token}`
      }
    });
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.url.match(/auth|config.json|public/)) {
      return next.handle(request);
    }

    if (!this.auth.isAuthenticated()) {
      this.router.navigate(['/auth/login']);
      return EMPTY;
    }

    if (!this.refreshTokenInProgress) {
      this.refreshTokenInProgress = true;

      return this.auth.refreshToken().pipe(
        take(1),
        switchMap((authResponse) => {
          this.refreshTokenInProgress = false;
          this.refreshTokenSubject.next(authResponse.refreshToken);
          return next.handle(this.injectToken(request));
        }),
        catchError(err => {

          if (err.status === 401 || err.status === 403 || (err.status === 400 && err.error === 'invalid_grant')) {
            if (this.refreshTokenInProgress) {
              debugger;
              const superAdmin = this.auth.isRole(UserRole.SuperAdmin);
              this.auth.clearSession();
              this.refreshTokenInProgress = false;
              this.router.navigate(superAdmin ? ['/admin/auth'] : ['/auth/login']);
              return EMPTY;
            }
          }

          return throwError(err);

        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        take(1),
        switchMap((res) => {
          return next.handle(this.injectToken(request));
        })
      );
    }

  }

}
