<div class="d-flex flex-row h-100">

  <div class="col d-flex flex-column align-items-center justify-content-center">

    <img src="/assets/logo.svg" />
    <h1>Visual Selphi</h1>

    
    <div class="alert alert-danger" *ngIf="error" [innerHtml]="error">
    </div>

    <div class="alert alert-success" *ngIf="success">
      Hemos enviado un email con instrucciones a su email
    </div>

    <form #formForgot="ngForm" class="formLogin" (ngSubmit)="doLogin()" [hidden]="success">

      <mat-form-field appearance="outline" class="w-100">
        <input matInput type="email" name="userName" [(ngModel)]="request.userName" required />
        <mat-label>Email</mat-label>
      </mat-form-field>

      <button mat-flat-button type="submit" color="primary" class="w-100" [disabled]="shouldDisableButton()">
        <mat-icon *ngIf="working">
          <mat-spinner diameter="20" color="accent"></mat-spinner>
        </mat-icon>
        <span>Enviar instrucciones</span>
      </button>

    </form>

    <hr>

    <a mat-flat-button color="accent" class="mb-2" [routerLink]="['/auth','login']">
      Iniciar sesión
    </a>

    <a mat-flat-button color="accent" [routerLink]="['/organisations/register']">
      Registrarse
    </a>

  </div>


  <div class="col col-background">

  </div>

</div>