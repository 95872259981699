<div mat-dialog-content>

  <app-facephi *ngIf="capturing" (captureReady)="captureReady($event)">
  </app-facephi>


  <div *ngIf="result$ | async as result">

    <div *ngIf="!result.entries.length; else resultsContainer" class="alert alert-danger">

      <h3 class="text-danger" *ngIf="result.isNotLive; else noResults ">
        No superó la prueba de vida
      </h3>

      <ng-template #noResults>
        <h3 class="text-danger">
          No se encontraron empleados coincidentes
        </h3>
      </ng-template>

      <button type="button" mat-flat-button color="primary" (click)="retryIdentify()">
        Reintentar
      </button>
    </div>

    <ng-template #resultsContainer>

      <div class="alert alert-success">
        {{ result.entries.length }} empleado/s encontrado/s
      </div>

      <mat-action-list>
        <mat-list-item *ngFor="let entry of result.entries" (click)="selectEmployee(entry)">
          <img class="img-fluid" [src]="entry.employee.image">
          <h3 matLine>{{entry.employee.name }}</h3>
          <small matLine>{{entry.employee.legalId }}</small>
          <h4 matLine>{{entry.similarity | number: '2.2'}} %</h4>
        </mat-list-item>
      </mat-action-list>

    </ng-template>

  </div>

</div>