import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ChartOptions } from 'chart.js';
import 'chartjs-plugin-datalabels';
import { Label } from 'ng2-charts';
import * as moment from 'moment';

import { IGetReportByEmployeeRequest, IReportByEmployeeDTO, ReportsService, TimeGrouping } from '@app/core/reports';
import { ISimpleDTO, ISimpleExDTO } from '@app/core';
import { LayoutService } from '@app/core/layout';
import { IEmployeeListDTO } from '@app/core/employees';

@Component({
  selector: 'app-employee-report',
  templateUrl: './employee-report.component.html',
  styleUrls: ['./employee-report.component.scss']
})
export class EmployeeReportComponent implements OnInit {

  public report$: BehaviorSubject<IReportByEmployeeDTO>;
  public request: IGetReportByEmployeeRequest;
  public groupByOptions: ISimpleDTO[];

  public chartOptions: ChartOptions;

  public chartLabels: Label[][] = [];
  public chartData: number[][] = [];

  public selectedTasks: ISimpleExDTO[];
  public selectedEmployees: IEmployeeListDTO[];
  public startDate: moment.Moment;
  public endDate: moment.Moment;

  public loading: boolean;

  constructor(
    private service: ReportsService,
    private layout: LayoutService
  ) {

    this.groupByOptions = [
      { id: TimeGrouping.Day, name: 'Día' },
      { id: TimeGrouping.Week, name: 'Semana' },
      { id: TimeGrouping.Month, name: 'Mes' },
    ];

    this.chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        enabled: false
      },
      legend: {
        position: 'right'
      },
      plugins: {
        datalabels: {
          formatter: (value, ctx) => {
            let sum = 0;
            const dataArr = ctx.chart.data.datasets[0].data;
            (dataArr as number[]).map(data => {
              sum += data;
            });
            return (value * 100 / sum).toFixed(2) + '%';
          }
        }
      }
    };

    this.startDate = moment().subtract(1, 'day');
    this.endDate = moment();

    this.request = {
      groupBy: TimeGrouping.Day,
    };

    this.report$ = new BehaviorSubject(null);

  }

  public ngOnInit(): void {
    this.dateChanged();
  }

  private setToolbar(): void {

    this.layout.setToolbar({
      title: 'Informes - Empleados',
      showMenuButton: true,
      rightButtons: [
        {
          action: () => this.exportData(),
          text: 'Exportar',
          disabled: () => !this.report$.value.entries.length
        }
      ]
    });
  }

  private exportData(): void {

    if (!this.report$.value || !this.report$.value.entries.length) {
      return;
    }

    this.service.exportReportByEmployee(this.request).subscribe(blob => {

      const data = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = data;
      link.download = `visual-selphi-informe-empleados-${this.request.startDate || ''}-${this.request.endDate || ''}.xlsx`;
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);

    });

  }

  public loadReport(): void {

    this.loading = true;

    this.service
      .getReportByEmployee(this.request)
      .subscribe(report => {

        this.report$.next(report);

        this.chartLabels = report.entries.map(e => {
          return e.tasks.map(task => {
            return `${task.name} ${this.service.formatTime(task.time)}`;
          });
        });

        this.chartData = report.entries.map(e => {
          return e.tasks.map(t => t.time.hours * 60 + t.time.minutes);
        });

        this.setToolbar();

      }, (e) => {
        this.loading = false;
        throw (e);
      }, () => this.loading = false);
  }

  public dateChanged(): void {
    this.request.startDate = this.startDate ? this.startDate.format('yyyy/MM/DD') : null;
    this.request.endDate = this.endDate ? this.endDate.format('yyyy/MM/DD') : null;
    this.loadReport();
  }

  public selectedTasksChanged($event: ISimpleExDTO[]): void {
    this.request.tasks = $event.map(t => t.uid);
    this.loadReport();
  }

  public selectedEmployeesChanged($event: IEmployeeListDTO[]): void {
    this.request.employees = $event.map(t => t.uid);
    this.loadReport();
  }

}
