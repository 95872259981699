import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfigService } from '@app/core';

import { FPhi } from '@facephi/selphi-widget-web';

@Component({
  selector: 'app-facephi',
  templateUrl: './facephi.component.html',
  styleUrls: ['./facephi.component.scss']
})
export class FacephiComponent implements OnInit {

  FPhiWidgetLibPath: string = 'assets/selphi';

  @Input()
  public alwaysOn: boolean;

  @Input()
  public startCapture: boolean;

  @Output()
  public captureReady: EventEmitter<string>;

  FPhiSelphiConstants = FPhi.Selphi;
  objectEntriesFunc = Object.entries;

  readonly FPhiCameraResolutions = {
    res480p: { title: '640x480', width: 640, height: 480 },
    res600p: { title: '800x600', width: 800, height: 600 },
    res768p: { title: '1024x768', width: 1024, height: 768 },
    res720p: { title: '1280x720 (720p)', width: 1280, height: 720 },
    res1080p: { title: '1920x1080 (1080p)', width: 1920, height: 1080 },
  };

  isWidgetCaptureStarted: boolean = false;

  widgetLivenessMode: number = this.FPhiSelphiConstants.LivenessMode.None;

  interactible: boolean = false;
  widgetTutorial: boolean = false;
  widgetStabilizationStage: boolean = false;
  widgetVideoRecord: boolean = false;
  widgetDebugMode: boolean = false;

  widgetCameraResolution: string = 'res720p';
  widgetCameraType: number = this.FPhiSelphiConstants.CameraType.Front;

  public changingCamera: boolean;
  public hasMultipleCameras: boolean;

  public widgetTimeout = 30 * 60 * 1000;

  constructor(
    private config: ConfigService
  ) {

    this.captureReady = new EventEmitter();

    if (this.config.getConfig().enableLivenessCheck) {
      this.widgetLivenessMode = this.FPhiSelphiConstants.LivenessMode.Passive;
    }

    const cameraType = localStorage.getItem('camera_type');
    if (cameraType) {
      this.widgetCameraType = parseInt(cameraType, 0);
    }

  }

  private raiseReady(value: string): void {
    this.captureReady.next(value);
  }

  public ngOnInit(): void {

    if (this.alwaysOn) {
      this.interactible = false;
    } else {
      this.interactible = true;
    }

    this.onStartCapture();
  }

  public ngOnChanges(): void {
    console.log(this.startCapture);
  }

  get widgetCameraWidth(): number { return this.FPhiCameraResolutions[this.widgetCameraResolution].width; }
  get widgetCameraHeight(): number { return this.FPhiCameraResolutions[this.widgetCameraResolution].height; }

  onStartCapture() { this.isWidgetCaptureStarted = true; }
  onStopCapture() { 
    this.startCapture = false;
    this.isWidgetCaptureStarted = false; 
  }

  public onExtractionFinish(extractionResult): void {

    if (extractionResult.detail.bestImage) {
      this.raiseReady(extractionResult.detail.bestImage.src);
    } else {
      this.raiseReady(null);
    }

    this.onStopCapture();
    this.checkAlwaysOn();

  }

  public onExtractionTimeout(event): void {
    this.onStopCapture();
    this.checkAlwaysOn();
  }

  public switchCamera(): void {

    this.changingCamera = true;
    this.widgetCameraType = this.widgetCameraType === this.FPhiSelphiConstants.CameraType.Front
      ? this.FPhiSelphiConstants.CameraType.Back
      : this.FPhiSelphiConstants.CameraType.Front;

    localStorage.setItem('camera_type', this.widgetCameraType.toString());

    this.onStopCapture();
    setTimeout(() => {
      this.changingCamera = false;
      this.onStartCapture();
    }, 500);

  }

  private checkAlwaysOn(): void {
    if (this.alwaysOn) {
      setTimeout(() => {

        if (this.startCapture) {
          this.onStartCapture();
        } else {
          this.checkAlwaysOn();
        }

      }, 100);
    }
  }

  public onUserCancel(): void {
    this.onStopCapture();
    this.raiseReady(null);
  }

  public onExceptionCaptured(): void {
    this.onStopCapture();
    localStorage.removeItem('camera_type');
    this.raiseReady(null);
    this.checkAlwaysOn();
  }

  public onLivenessErrorButtonClick(): void {
    console.warn('[Selphi] onLivenessErrorButtonClick');
    this.onStopCapture();
    this.checkAlwaysOn();
  }

  public onTimeoutErrorButtonClick(): void {
    console.warn('[Selphi] onTimeoutErrorButtonClick');
    this.onStopCapture();
    this.checkAlwaysOn();
  }


}
