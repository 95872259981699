import { Component, OnInit } from '@angular/core';
import { LayoutService } from '@app/core/layout';

@Component({
  selector: 'app-organisation-welcome',
  templateUrl: './organisation-welcome.component.html',
  styleUrls: ['./organisation-welcome.component.scss']
})
export class OrganisationWelcomeComponent implements OnInit {

  constructor(
    private layout: LayoutService
  ) {

    this.layout.setToolbar({
      showMenuButton: true,
      title: 'Bienvenido a Visual Selphi'
    });

  }

  ngOnInit(): void {
  }

}
