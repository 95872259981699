<div *ngIf="status$ | async as status">

  <div class="alert alert-success">

    <h3>Subscripción activa</h3>

  </div>

  <div *ngIf="status.subscriptionType === 0; else paySubscription">
    Estás usando el modo gratuito de prueba

    <button type="button" class="w-50" mat-flat-button color="primary" [routerLink]="['/subscription/welcome']">
      Cambiar
    </button>

  </div>

  <ng-template #paySubscription>

    <div class="alert alert-success">

      <ng-container *ngIf="status.subscriptionType !== 3">

        <h4>Periodo: <strong>{{ status.renewFrom | date: 'dd MMMM yyyy' }} - {{ status.renewTo | date: 'dd MMMM yyyy'
            }}</strong></h4>

        <div *ngIf="status.subscriptionType === 1">
          <h4>Subscripción por empleado</h4>
        </div>

        <div *ngIf="status.subscriptionType === 2">
          <h4>Subscripción por volumen</h4>
          <h4>Total empleados: <strong>{{ status.totalEmployees }}</strong></h4>
        </div>

      </ng-container>

      <h4>Empleados en uso: <strong>{{ status.employeesInUse }}</strong></h4>

    </div>


    <div class="alert alert-success m-0">
      Resumen de pagos
    </div>

    <app-payments-list></app-payments-list>


  </ng-template>

</div>
