import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { ISidenavButton, IToolbarState } from './defs';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  public toolbarState$: BehaviorSubject<IToolbarState>;
  public sidenavVisible$: BehaviorSubject<boolean>;
  public sidenavButtons$: BehaviorSubject<ISidenavButton[]>;

  constructor() {
    this.toolbarState$ = new BehaviorSubject(null);
    this.sidenavVisible$ = new BehaviorSubject(false);
    this.sidenavButtons$ = new BehaviorSubject([]);
  }

  public setToolbar(toolbarState: IToolbarState): void {
    this.toolbarState$.next(toolbarState);
  }

  public toggleSidenav(): void {
    this.sidenavVisible$.next(!this.sidenavVisible$.value);
  }

  public showSidenav(value: boolean): void {
    this.sidenavVisible$.next(value);
  }

  public addSidenavButton(button: ISidenavButton, insert?: boolean): void {
    const buttons = this.sidenavButtons$.value;

    if (!insert) {
      buttons.push(button);
    } else {
      buttons.unshift(button);
    }

    this.sidenavButtons$.next(buttons);
  }
}
