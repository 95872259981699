import { NgModule } from '@angular/core';
import { Router } from '@angular/router';

import { LayoutService } from '@app/core/layout';
import { SharedModule } from '@app/shared';

import { JobTaskDetailComponent } from './detail/job-task-detail.component';
import { JobTaskSelectorComponent } from './selector/job-task-selector.component';
import { JobTaskListComponent } from './list/job-task-list.component';
import { JobTaskSelectorInputComponent } from './selector-input/selector-input.component';
import { JobTaskSelectorPopupComponent } from './selector-popup/selector-popup.component';


@NgModule({
  declarations: [
    JobTaskDetailComponent,
    JobTaskSelectorComponent,
    JobTaskListComponent,
    JobTaskSelectorInputComponent,
    JobTaskSelectorPopupComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    JobTaskSelectorComponent,
    JobTaskSelectorInputComponent
  ]
})
export class JobTasksModule {
}
