<app-spinner *ngIf="loading; else paymentMehodList">
</app-spinner>

<div class="inner-container">
<ng-template #paymentMehodList>
  <ng-container *ngIf="records$ | async as records">

    <div class="alert alert-success" *ngIf="!totalRecords">

      <h3 class="text-center">
        <div>
          <mat-icon>schedule</mat-icon>
        </div>
        ¡Vaya! No hay métodos de pago
      </h3>
      <p class="text-center">
        Para añadir un método de pago, haga click en el botón + que aparece abajo a la derecha.
      </p>
    </div>

    <mat-action-list>
      <mat-list-item *ngFor="let record of records">

        <div matLine>
          
          <span>{{ record.brand }} que termina en {{ record.last4 }}</span>
          <span class="ml-2">
            <strong>Caduca el </strong>{{ record.expiryDate | date: 'dd/MM/yy' }}
          </span>

        </div>
        <small matLine *ngIf="record.isDefault">
          Método de pago por defecto
        </small>
     
      </mat-list-item>

    </mat-action-list>
  </ng-container>

</ng-template>

</div>

<div class="add-button-container">
  <button type="button" mat-fab color="primary" (click)="addPaymentMethod()">
    <mat-icon>add</mat-icon>
  </button>
</div>