<div class="alert alert-info">
  Selección de parcela / unidad de cultivo
</div>

<app-spinner *ngIf="loading">
</app-spinner>

<div *ngIf="plots$ | async as plots">

  <div class="alert alert-warning" *ngIf="!plots.length">
    No se encuentran parcelas
  </div>

  <mat-radio-group [disabled]="readOnly" [(ngModel)]="selectedId" class="radio-group-vertical" (ngModelChange)="raiseSelectedChanged()">
    <mat-radio-button *ngFor="let plot of plots$ | async" [value]="plot.id">
      {{ plot.name }} / Superficie : {{ plot.area}}
    </mat-radio-button>
  </mat-radio-group>

</div>