import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ConfirmResetResolver implements Resolve<any> {

  constructor() {}

  public resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return of({
      token: route.queryParamMap.get('token'),
      user: route.queryParamMap.get('user'),
      organisation: route.queryParamMap.get('organisation'),
    });
  }

}
