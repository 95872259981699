import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared';

import '@facephi/selphi-widget-web';

import { FacephiComponent } from './facephi/facephi.component';

@NgModule({
  declarations: [
    FacephiComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    FacephiComponent
  ]
})
export class IdentificationModule { }
