<div class="d-flex flex-row h-100">

  <div class="col d-flex flex-column align-items-center justify-content-center">

    <img src="/assets/logo.svg" />
    <h1>Visual Selphi</h1>

    <div class="alert alert-danger" *ngIf="error">
      {{ error }}
    </div>

    <div class="content-container">

      <form [formGroup]="frmRegister" class="formRegister" (ngSubmit)="doConfirmRegister()" [hidden]="success"
        novalidate="novalidate">

        <div class="alert alert-success">
          Para completar su proceso de registro, por favor rellene los campos a continuación.
        </div>

        <mat-form-field appearance="outline" class="w-100">
          <input matInput type="password" name="password" formControlName="password" pattern=".{4,}" required />
          <mat-error *ngIf="submitted && frmRegister.hasError('required', 'password')">
            Contraseña es requerida
          </mat-error>
          <mat-error *ngIf="submitted && frmRegister.hasError('pattern', 'password')">
            Debe tener 4 caracteres mínimo, incluir al menos un número y una mayúscula
          </mat-error>
          <mat-label>Nueva contraseña</mat-label>
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-100">
          <input matInput type="password" name="confirmPassword" formControlName="confirmPassword"
            [errorStateMatcher]="matcher" required />
          <mat-label>Confirme la contraseña</mat-label>
          <mat-error *ngIf="submitted && frmRegister.hasError('notSame')">
            Las contraseñas no coinciden
          </mat-error>
        </mat-form-field>

        <button mat-flat-button type="submit" color="primary" class="w-100" [disabled]="working">
          <mat-icon *ngIf="working">
            <mat-spinner diameter="20" color="accent"></mat-spinner>
          </mat-icon>
          <span>Confirmar registro</span>
        </button>


      </form>
    </div>

  </div>

  <div class="col col-background">

  </div>

</div>