<nav mat-tab-nav-bar>

  <a mat-tab-link [routerLink]="['/users']" [routerLinkActive]="'active'">
    Usuarios
  </a>

  <a mat-tab-link [routerLink]="['/job-tasks']" [routerLinkActive]="'active'">
    Tareas
  </a>

  <a mat-tab-link [routerLink]="['/absence-reasons']" [routerLinkActive]="'active'">
    Motivos ausencias
  </a>

  <a mat-tab-link [routerLink]="['/subscription/status']" [routerLinkActive]="'active'">
    Subscripción
  </a>

  <a mat-tab-link [routerLink]="['/work-centers']" [routerLinkActive]="'active'" *ngIf="showWorkCenters">
    Centros de trabajo
  </a>

</nav>
<router-outlet></router-outlet>