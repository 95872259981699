import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(
    private injector: Injector,
    private ngZone: NgZone
  ) { }

  handleError(data) {

    this.ngZone.run(() => {
      this.injector.get(ToastrService).error(data ? data.error || data.message || data : 'unknown error');
    });

    console.error(data);

  }

}
