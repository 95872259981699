<h4>Ubicación tareas</h4>

<agm-map [mapTypeId]="'satellite'" [latitude]="locations[0].latitude" [longitude]="locations[0].longitude"
  [zoom]="18" [fitBounds]="true">

  <agm-marker-cluster>
    <ng-container *ngFor="let location of locations$ | async">
      <agm-marker [latitude]="location.latitude" [longitude]="location.longitude" [agmFitBounds]="true"
        (markerClick)="selectLocation(location)"></agm-marker>
    </ng-container>
  </agm-marker-cluster>

  <agm-info-window *ngIf="selectedLocation" [isOpen]="true" [latitude]="selectedLocation.latitude"
    [longitude]="selectedLocation.longitude" (infoWindowClose)="selectedLocation = null">
    <h2>{{selectedLocation.task}}</h2>
    <a [routerLink]="['/jobs', selectedLocation.jobId]" target="_blank">Detalles</a>
  </agm-info-window>

</agm-map>
