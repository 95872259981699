import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { IReportLocationDTO } from '@app/core/reports';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-report-locations',
  templateUrl: './report-locations.component.html',
  styleUrls: ['./report-locations.component.scss']
})
export class ReportLocationsComponent implements OnChanges {

  public locations$: BehaviorSubject<IReportLocationDTO[]>;

  @Input()
  public locations: IReportLocationDTO[];

  public selectedLocation: IReportLocationDTO;

  constructor() {
    this.locations$ = new BehaviorSubject([]);
  }


  public selectLocation(location: IReportLocationDTO): void {
    this.selectedLocation = location;
  }

  public ngOnChanges(): void {

    // delete duplicated locations, agm marker position fails
    const uniqueLocations: IReportLocationDTO[] = [];
    this.locations.forEach(ll => {

      const idx = uniqueLocations.findIndex(l => l.latitude === ll.latitude && l.longitude === ll.longitude);
      if (idx < 0) {
        uniqueLocations.push(ll);
      } else {
        uniqueLocations[idx].task += `, ${ll.task}`;
      }

    });

    this.locations$.next(uniqueLocations);
  }

}
