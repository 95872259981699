<div class="d-flex justify-content-between">

  <button type="button" class="w-100" mat-flat-button color="primary" (click)="openSelector()">

    <span *ngIf="selectedTasks?.length">
      {{ selectedTasks.length }} tarea/s
    </span>

    <span *ngIf="!selectedTasks.length">
      Todas las tareas
    </span>
    
  </button>


</div>