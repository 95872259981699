import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpErrorResponse, HttpClient, HttpHeaders } from '@angular/common/http';

import { ConfigService } from './config.service';

export declare interface IErrorResponse {
  status: number;
  error: string;
}

export interface IResponse {
  success: boolean;
  data: any;
}


@Injectable({
  providedIn: 'root'
})
export class RequestService {

  constructor(
    private config: ConfigService,
    private http: HttpClient
  ) {
  }

  /**
   * Appends / at the end of the url, it not set
   */
  private joinUrl(url1: string, url2: string): string {

    if (url2.substring(0, 1) !== '/' && url2.substring(url2.length - 1, 1) !== '/') {
      url2 = '/' + url2;
    }

    return url1 + url2;
  }

  public getApiUrl(url): string {
    return this.joinUrl(this.config.getConfig().apiEndpoint, url);
  }

  private handleError<T>(response): Observable<IErrorResponse> {
    if (response instanceof HttpErrorResponse) {

      return throwError(response.status === 0 ?
        { status: 0, error: 'No connection' } :
        {
          status: response.status, error: response.error
            ? response.error.error || (response.error.errors ? response.error.errors.join('') : JSON.stringify(response.error)) : response.statusText
        }
      );
    }
    return throwError({ status: 0, error: 'Unknown error' });
  }

  private request<T>(url: string, method: string, body?: any, options?: any): Observable<any> {
    return this.http
      .request<T>(method, this.getApiUrl(url), { ...options, body })
      .pipe(
        catchError(response => {
          return this.handleError(response);
        })
      );
  }


  public get<T>(url: string, params?: any, options?: any): Observable<T> {
    return this.request(url, 'GET', null, { params, ...options });
  }

  public post<T>(url: string, body?: T, options?: any): Observable<any> {
    return this
      .request(url, 'POST', body, options)
      .pipe(
        map(response => response.data ? response.data : response)
      )
      ;
  }

  public postUrlEncoded(url: string, body: any): Observable<any> {

    const prms = new URLSearchParams();
    Object.keys(body).forEach(key => {
      prms.append(key, body[key]);
    });

    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    return this.post(url, prms.toString(), options);
  }

  public put<T>(url: string, body: T): Observable<any> {
    return this
      .request(url, 'PUT', body)
      .pipe(
        map(response => response.data ? response.data : response)
      );
  }

  public delete<T>(url: string, body?: T): Observable<any> {
    return this
      .request(url, 'DELETE', body)
      .pipe(
        map(response => response.data ? response.data : response)
      );
  }

}
