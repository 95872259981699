import { ISimpleExDTO, ITimeLapseDTO } from '../defs';


export interface IReportDef {
  name: string;
  path: string[];
}


export interface IReportByEmployeeDTO {
  entries: IReportByEmployeeEntryDTO[];
  locations: IReportLocationDTO[];
}

export interface IReportByEmployeeEntryDTO {
  employee: ISimpleExDTO;
  tasks: ITaskTimeDTO[];
  group: string;
  totalTime: ITimeLapseDTO;
  locationName?: string;
}

export enum TimeGrouping {
  Day = 1,
  Week,
  Month
}

export interface ITaskTimeDTO extends ISimpleExDTO {
  time: ITimeLapseDTO;
}

export interface IGetReportByEmployeeRequest {
  groupBy: TimeGrouping;
  startDate?: Date|string;
  endDate?: Date|string;
  tasks?: string[];
  employees?: string[];
}

export interface IReportByJobDTO {
  total: ITimeLapseDTO;
  entries: IReportByJobEntryDTO[];
}

export interface IGetReportByJobRequest {
  startDate: string;
  endDate: string;
  groupBy: TimeGrouping;
  tasks?: string[];
  locationName?: string
}


export interface IReportByJobEntryDTO {
  employee: ISimpleExDTO;
  task: ISimpleExDTO;
  date: Date;
  startTime: string;
  endTime: string;
  notes: string;
  name: string;
  totalTime: ITimeLapseDTO;
  locationName?: string;
}

export interface IReportByDayDTO {
  entries: IReportByDayEntryDTO[];
  weights: IReportByDayWeightDTO[];
  locations: IReportLocationDTO[];
}

export interface IReportLocationDTO {
  jobId: string;
  task: string;
  latitude: number;
  longitude: number;
}

export interface IReportByDayEntryDTO {
  task: ISimpleExDTO;
  entries: IReportByDayEntryTaskDTO[];
  totalTime: ITimeLapseDTO;
}

export interface IReportByDayWeightDTO {
  employee: ISimpleExDTO;
  entries: IReportByDayWeightEntryDTO[];
}

export interface IReportByDayWeightEntryDTO {
  task: ISimpleExDTO;
  totalTime: ITimeLapseDTO;
}


export interface IReportByDayEntryTaskDTO {
  employee: ISimpleExDTO;
  date: Date;
  startTime: string;
  endTime: string;
  totalTime: ITimeLapseDTO;
}


export interface IGetReportByDayRequest {
  date: string;
}
