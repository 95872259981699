import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';

import { ConfirmRegisterOrganisationComponent } from './confirm-register/confirm-register-organisation.component';
import { RegisterComponent } from './register/register.component';
import { OrganisationWelcomeComponent } from './welcome/organisation-welcome.component';
import { LegalInfoComponent } from './legal-info/legal-info.component';

@NgModule({
  declarations: [
    OrganisationWelcomeComponent,
    ConfirmRegisterOrganisationComponent,
    RegisterComponent,
    LegalInfoComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    LegalInfoComponent
  ]
})
export class OrganisationsModule { }
