import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ISimpleExDTO } from '@app/core';
import { JobTaskSelectorPopupComponent } from '../selector-popup/selector-popup.component';

@Component({
  selector: 'app-job-task-selector-input',
  templateUrl: './selector-input.component.html',
  styleUrls: ['./selector-input.component.scss']
})
export class JobTaskSelectorInputComponent implements OnChanges {

  @Input()
  public selectedTasks: ISimpleExDTO[];

  @Output()
  public selectedChanged: EventEmitter<ISimpleExDTO[]>;

  constructor(
    private dialog: MatDialog
  ) {
    this.selectedChanged = new EventEmitter();
    this.selectedTasks = [];
  }

  public ngOnChanges(): void {
    if (!this.selectedTasks) {
      this.selectedTasks = [];
    }
  }

  public openSelector(): void {

    this.dialog.open(JobTaskSelectorPopupComponent, {
      panelClass: 'modal-flex',
      data: {
        selectedTasks: this.selectedTasks
      }
    })
    .afterClosed().subscribe((tasks) => {

      if (tasks) {
        this.selectedTasks = tasks;
        this.selectedChanged.next(this.selectedTasks);
      }

    });

  }

}
