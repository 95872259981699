import { NgModule } from '@angular/core';
import { LayoutService } from '@app/core/layout';

import { SharedModule } from '@app/shared';
import { AuthService } from '@app/core/auth';

import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';

@NgModule({
  declarations: [
    LoginComponent,
    LogoutComponent
  ],
  imports: [
    SharedModule
  ]
})
export class AuthModule {
}
