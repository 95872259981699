import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { CustomErrorStateMatcher } from '@app/shared';
import { IErrorResponse } from '@app/core';
import { AuthService } from '@app/core/auth';
import { IConfirmRegisterOrganisationRequest, OrganisationsService } from '@app/core/organisations';

@Component({
  selector: 'app-confirm-register-organisation',
  templateUrl: './confirm-register-organisation.component.html',
  styleUrls: ['./confirm-register-organisation.component.scss']
})
export class ConfirmRegisterOrganisationComponent implements OnInit {

  public frmRegister: FormGroup;
  public error: string;
  public success: boolean;
  public working: boolean;
  public submitted: boolean;
  public matcher: CustomErrorStateMatcher;
  public request: IConfirmRegisterOrganisationRequest;

  constructor(
    private authService: AuthService,
    private organisationService: OrganisationsService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {

    this.matcher = new CustomErrorStateMatcher();

    this.frmRegister = this.fb.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    }, { validator: this.checkPasswords });

  }

  public ngOnInit(): void {

    const data = this.route.snapshot.data?.registration;
    this.request = {
      token: data?.token,
      userName: data?.user,
      organisation: data?.organisation,
    } as any;

  }

  private checkPasswords(group: FormGroup): any {
    const pass = group.get('password').value;
    const confirmPass = group.get('confirmPassword').value;
    return pass === confirmPass ? null : { notSame: true };
  }

  public doConfirmRegister(): void {

    this.submitted = true;
    if (!this.frmRegister.valid) {
      return;
    }

    this.working = true;

    this.request = {
      ...this.request,
      ...this.frmRegister.value
    };

    this.organisationService
      .confirmRegister(this.request)
      .pipe(
        catchError((response: IErrorResponse) => {
          // catch the error to avoid global error handler catching it
          return of(response);
        })
      )
      .subscribe((response) => {

        const resp = response as IErrorResponse;
        if (response.error) {
          this.error = response.error;
          this.working = false;
        } else {
          this.success = true;
          this.authService.login({
            client_id: this.request.organisation,
            userName: this.request.userName,
            password: this.request.password,
            captcha: '',
            remember: true
          }).subscribe(() => {
            this.router.navigate(['/organisations/welcome']);
          });
        }

      });
  }

}
