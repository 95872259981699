import { Component, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, of } from 'rxjs';

import { ISidenavButton, LayoutService } from '@app/core/layout';
import { switchMap } from 'rxjs/operators';
import { AuthService } from '@app/core/auth';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent {

  public sidenavMode: string;

  public sidenavVisible$: Observable<boolean>;
  public sidenavButtons$: Observable<ISidenavButton[]>;

  public isDesktop: boolean;
  public hasBackdrop: boolean;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private layoutService: LayoutService,
    private authService: AuthService
  ) {

    const session = this.authService.getSession();

    this.breakpointObserver.observe([
      Breakpoints.Web
    ]).subscribe(result => {
      this.isDesktop = result.matches;
      this.sidenavMode = this.isDesktop ? 'side' : 'over';
      this.layoutService.showSidenav(result.matches);
    });

    this.sidenavVisible$ = this.layoutService.sidenavVisible$;
    this.sidenavButtons$ = this.layoutService
      .sidenavButtons$
      .pipe(
        switchMap(buttons => {
          return of(buttons.filter(b =>
            (!b.roles || !b.roles.length || b.roles.indexOf(session.role) > -1) &&
            (!b.visible || b.visible() === true))
          );
        })
      );
  }

  public toggleSidenav(): void {
    this.layoutService.toggleSidenav();
  }

  public sidenavOpenedChanged($event: boolean): void {
    this.layoutService.showSidenav($event);
  }

  public sidenavClick(button: ISidenavButton): void {

    button.action();

    if (!this.isDesktop) {
      this.toggleSidenav();
    }
  }

}
