import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared';

import { PlotSelectorComponent } from './plot-selector/plot-selector.component';
import { MapComponent } from './map/map.component';
import { SyncJobComponent } from './sync-job/sync-job.component';

@NgModule({
  declarations: [
    PlotSelectorComponent,
    MapComponent,
    SyncJobComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    PlotSelectorComponent,
    MapComponent
  ]
})
export class VisualModule { }
