import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { RequestService } from '../_common';
import { IChunkedListDTO } from '../defs';
import { IJobDetailDTO, IJobListDTO, IGetJobListRequest, ICreateUpdateJobRequest, ICreateUpdateJobResult } from './jobs.defs';

@Injectable({
  providedIn: 'root'
})
export class JobsService {

  constructor(
    private requestService: RequestService,
  ) {
  }

  public getJobById(uid: string): Observable<IJobDetailDTO> {
    return this.requestService
      .get<IJobDetailDTO>(`api/jobs/${uid}`)
      .pipe(map(job => {

        job.employees = job.employees.sort((a, b) => a.employee.name > b.employee.name ? -1 : 1);
        return job;

      }));
  }

  public getJobList(request?: IGetJobListRequest): Observable<IChunkedListDTO<IJobListDTO>> {
    return this.requestService.post('api/jobs/search', request);
  }

  public createJob(request: ICreateUpdateJobRequest): Observable<ICreateUpdateJobResult> {
    return this.requestService.post('api/jobs', request);
  }

  public updateJob(uid: string, request: ICreateUpdateJobRequest): Observable<ICreateUpdateJobResult> {
    return this.requestService.put(`api/jobs/${uid}`, request);
  }

  public deleteJob(uid: string): Observable<IJobDetailDTO> {
    return this.requestService.delete(`api/jobs/${uid}`);
  }

  

}
