<app-spinner *ngIf="loading; else userList">
</app-spinner>

<ng-template #userList>
  <ng-container *ngIf="users$ | async as users">
    
    <div class="alert alert-success" *ngIf="!users.length">

      <h3 class="text-center">
        <div>
        <mat-icon>supervised_user_circle</mat-icon>
      </div>
        ¡Vaya! No hay usuarios creados
      </h3>
      <p class="text-center">
        Para añadir un usuario, haz click en el botón + que aparece abajo a la derecha.
      </p>
    </div>

    <cdk-virtual-scroll-viewport class="list-container" itemSize="72">

      <mat-action-list>
        <ng-container *cdkVirtualFor="let user of users">
          <mat-list-item [routerLink]="['/users', user.uid]">

            <mat-icon mat-list-icon *ngIf="user.activated">done</mat-icon>
            <mat-icon mat-list-icon *ngIf="!user.activated">hourglass_empty</mat-icon>

            <h3 matLine>{{user.name }}</h3>
            <p matLine>
              {{ user.role.name }}
            </p>

          </mat-list-item>
        </ng-container>
      </mat-action-list>

    </cdk-virtual-scroll-viewport>
  </ng-container>
</ng-template>

<div class="add-button-container">
  <a mat-fab color="primary" [routerLink]="['/users/new']">
    <mat-icon>add</mat-icon>
  </a>
</div>