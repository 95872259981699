<div mat-dialog-content>

  <div class="alert alert-danger" *ngIf="error">
    {{ error }}
  </div>

  <form [formGroup]="frmCard" novalidate (ngSubmit)="confirmPaymentMethod()">

    <div class="alert alert-info">
      Introduzca los datos de su tarjeta de crédito a continuación
    </div>

    <div class="alert alert-info">
      El cargo se le aplicará el último día del mes en base a los empleados que haya dado de alta en el sistema
    </div>

    <mat-form-field>
      <mat-label>Titular de la tarjeta</mat-label>
      <input matInput formControlName="cardHolder">
      <mat-error *ngIf="frmCard.hasError('required', 'cardHolder')">Requerido</mat-error>
    </mat-form-field>

    <div class="card-container">
      <ngx-stripe-card [options]="cardOptions" [elementsOptions]="elementsOptions" (change)="cardChanged($event)">
      </ngx-stripe-card>
    </div>

    
    <button type="submit" mat-flat-button color="primary" [disabled]="!cardComplete || !frmCard.valid || working">
      <mat-icon *ngIf="working">
        <mat-spinner diameter="20" color="accent"></mat-spinner>
      </mat-icon>
      <span>Validar y establecer como método de pago</span>
    </button>


  </form>

</div>