import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-spin-button',
  templateUrl: './spin-button.component.html',
  styleUrls: ['./spin-button.component.scss']
})
export class SpinButtonComponent {

  @Input()
  public working: boolean;

  @Input()
  public disabled: boolean;

  @Input()
  public text: boolean;

  @Input()
  public type: string;

  constructor() {
    this.type = 'button';
  }
}
