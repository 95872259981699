<ng-container *ngIf="state$ | async as state">
  <mat-toolbar color="primary" *ngIf="!state.hideToolbar">

    <button *ngIf="state.showMenuButton" mat-icon-button aria-label="Menu principal" (click)="toggleSidenav()">
      <mat-icon>menu</mat-icon>
    </button>

    <button mat-icon-button *ngFor="let button of state.leftButtons" (click)="button.action()">
      <mat-icon *ngIf="button.icon">{{button.icon}}</mat-icon> {{ button.text }}
    </button>

    <div class="title text-truncate">{{ state.title }}</div>

    <button mat-button *ngFor="let button of state.rightButtons" (click)="button.action()" [disabled]="button.disabled && button.disabled()">
      <mat-icon *ngIf="button.icon">{{button.icon}}</mat-icon> {{ button.text }}
    </button>

  </mat-toolbar>

</ng-container>