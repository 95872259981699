<app-spinner *ngIf="working"></app-spinner>

<div class="detail-container">

  <form [formGroup]="frmUser" novalidate="novalidate">

    <div class="alert alert-info">
      Introduzca un email válido y enviaremos un link de activación.
    </div>

    <mat-form-field>
      <mat-label>Email</mat-label>
      <input matInput formControlName="name" placeholder="Placeholder">
      <mat-error *ngIf="frmUser.hasError('required', 'name')">Email es requerido</mat-error>
      <mat-error *ngIf="frmUser.hasError('email', 'name')">Email no válido</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Rol</mat-label>
      <mat-select formControlName="role">
        <mat-option *ngFor="let role of roles$ | async" [value]="role.id">
          {{ role.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="frmUser.hasError('required', 'role')">Rol es requerido</mat-error>
    </mat-form-field>

  </form>

</div>
