<mat-dialog-content>
    <h1>{{ data.title }}</h1>
</mat-dialog-content>

<mat-dialog-actions *ngIf="!data.hideYesNo">
    <button mat-flat-button (click)="no()">No</button>
    <button mat-flat-button color="primary" (click)="yes()">Sí</button>
</mat-dialog-actions>

<mat-dialog-actions *ngIf="data.hideYesNo">
    <button mat-flat-button color="primary" (click)="ok()">Ok</button>
</mat-dialog-actions>
