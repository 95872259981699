import { Injectable } from '@angular/core';
import { filter, map, Observable, of, switchMap } from 'rxjs';

import { RequestService } from '../_common';
import { IOrganisationSettingDTO, ISetOrganisationSettingsRequest } from './defs';

@Injectable({
  providedIn: 'root'
})
export class OrganisationSettingsService {

  constructor(private request: RequestService) {
  }

  public getSettings() : Observable<IOrganisationSettingDTO[]> {
    return this.request.get('/api/organisation-settings');
  }

  public getSetting(key: string, value: any) : Observable<IOrganisationSettingDTO> {
    return this.request
      .get<IOrganisationSettingDTO[]>('/api/organisation-settings')
      .pipe(
        switchMap(settings => of(settings.find(t => t.key === key && t.value == value)))
      );
  }

  public getSettingsForOrg(organisationId: string) : Observable<IOrganisationSettingDTO[]> {
    return this.request.get(`/api/admin/organisation-settings/${organisationId}`);
  }

  public setSettings(request: ISetOrganisationSettingsRequest): Observable<any> {
    return this.request.post('/api/admin/organisation-settings', request);
  }
}
