import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeLapse'
})
export class TimeLapsePipe implements PipeTransform {

  public transform(value: { hours: number; minutes: number; }, ...args: unknown[]): unknown {
    if (!value) {
      return '';
    }

    return `${value.hours ? value.hours + ' H ' : ''} ${value.minutes ? value.minutes + 'm' : ''}`;

  }

}
