<app-spinner *ngIf="working">
</app-spinner>

<div class="detail-container">

  <form [formGroup]="frmLegalInfo" novalidate (ngSubmit)="updateLegalInfo()">

    <mat-form-field>
      <mat-label>CIF</mat-label>
      <input matInput formControlName="legalId" maxlength="12">
      <mat-error *ngIf="frmLegalInfo.hasError('required', 'legalId')">Requerido</mat-error>
      <mat-error *ngIf="frmLegalInfo.hasError('pattern', 'legalId')">Introduzca sólo letras y números</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Razón social</mat-label>
      <input matInput formControlName="legalName">
      <mat-error *ngIf="frmLegalInfo.hasError('required', 'legalName')">Requerido</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Dirección</mat-label>
      <input matInput formControlName="legalAddress">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Teléfono</mat-label>
      <input matInput formControlName="phoneNumber">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Email</mat-label>
      <input matInput formControlName="email">
      <mat-error *ngIf="frmLegalInfo.hasError('required', 'email')">Requerido</mat-error>
      <mat-error *ngIf="frmLegalInfo.hasError('email', 'email')">Introduzca un email válido</mat-error>
    </mat-form-field>

    <div class="text-center">
      <button type="submit" mat-flat-button color="primary" [disabled]="!frmLegalInfo.valid || working">
        <mat-icon *ngIf="working">
          <mat-spinner diameter="20" color="accent"></mat-spinner>
        </mat-icon>
        <span>Siguiente</span>
      </button>
    </div>

  </form>

</div>