import { Observable } from 'rxjs';
import { UserRole } from '../auth';

export interface IToolbarState {
  title?: string;
  showMenuButton?: boolean;
  leftButtons?: IToolbarButton[];
  rightButtons?: IToolbarButton[];
}

export interface IToolbarButton {
  text?: string;
  icon?: string;
  action: () => void;
  disabled?: () => boolean;
}

export interface ISidenavButton {
  roles?: UserRole[];
  beginSeparator?: boolean;
  text?: string;
  icon?: string;
  action: () => void;
  visible?: () => boolean;
  visible$?: Observable<boolean>;
}
