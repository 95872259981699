import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { IJobDetailDTO } from './jobs.defs';
import { JobsService } from './jobs.service';

@Injectable({
  providedIn: 'root'
})
export class JobResolver implements Resolve<any> {

  constructor(private service: JobsService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<IJobDetailDTO> {
    return this.service.getJobById(route.params.id);
  }

}
