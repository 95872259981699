import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { Router } from '@angular/router';

import { LayoutModule } from '@app/layout/layout.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { SharedModule } from './shared';
import { ConfigService, CoreModule } from './core';
import { IdentificationModule } from './identification';
import { EmployeesModule } from './employees';
import { JobsModule } from './jobs';
import { JobTasksModule } from './job-tasks';
import { AuthModule } from './auth';
import { ConfigurationModule } from './configuration';
import { UsersModule } from './users';
import { SubscriptionModule } from './subscription';
import { ReportsModule } from './reports';

import { LayoutService } from './core/layout';
import { UserRole } from './core/auth';
import { KnownSettings, OrganisationSettingsService } from './core/organisation-settings';
import { map, of } from 'rxjs';

registerLocaleData(es);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    CoreModule,
    IdentificationModule,
    LayoutModule,
    EmployeesModule,
    JobsModule,
    JobTasksModule,
    ConfigurationModule,
    UsersModule,
    SubscriptionModule,
    ReportsModule,
    AuthModule,
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: initApp, deps: [ConfigService, LayoutService, Router, OrganisationSettingsService], multi: true },
    { provide: LOCALE_ID, useValue: 'es-ES' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}


export function initApp(config: ConfigService, layout: LayoutService, router: Router, settings: OrganisationSettingsService
): any {
  return () => {

    return new Promise((resolve, reject) => {

      config.load().subscribe(() => {


        layout.addSidenavButton({
          roles: [UserRole.Admin, UserRole.Coordinator, UserRole.Regular],
          action: () => router.navigate(['employees']),
          text: 'Empleados'
        });

        layout.addSidenavButton({
          roles: [UserRole.Admin, UserRole.Coordinator, UserRole.Regular],
          action: () => router.navigate(['jobs']),
          text: 'Trabajos'
        });

        layout.addSidenavButton({
          roles: [UserRole.Admin, UserRole.Coordinator, UserRole.Regular],
          action: () => router.navigate(['/absences']),
          text: 'Ausencias'
        });

        layout.addSidenavButton({
          roles: [UserRole.Admin, UserRole.Coordinator, UserRole.Regular],
          action: () => router.navigate(['/active-presence/selector']),
          text: 'Activación presencia',
          visible$: settings.getSetting(KnownSettings.FACE_TO_FACE, 'true').pipe(map(t => t ? true : false))
        });

        layout.addSidenavButton({
          roles: [UserRole.Admin, UserRole.Coordinator, UserRole.Regular],
          action: () => router.navigate(['/active-presence/list']),
          text: 'Presencia manual',
          visible$: settings.getSetting(KnownSettings.FACE_TO_FACE, 'true').pipe(map(t => t ? true : false))
        });

        layout.addSidenavButton({
          roles: [UserRole.Admin, UserRole.Coordinator],
          action: () => router.navigate(['configuration']),
          text: 'Configuración',
          beginSeparator: true,
        });

        layout.addSidenavButton({
          roles: [UserRole.Admin, UserRole.Coordinator, UserRole.Regular],
          action: () => router.navigate(['reports']),
          text: 'Informes',
          beginSeparator: true,
        });

        layout.addSidenavButton({
          beginSeparator: true,
          action: () => router.navigate(['/auth/logout']),
          text: 'Cerrar sesión'
        });



        resolve(true);

      }, () => reject());

    });

  };
}
