<div class="button-container">

  <button type="button" mat-flat-button color="primary" *ngIf="manualSelection" (click)="addEmployeeManual()">
    Fichaje manual
  </button>

  <button type="button" mat-fab color="primary" (click)="addEmployeeAuto()">
    <mat-icon>add</mat-icon>
  </button>


</div>