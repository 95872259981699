import { Routes } from '@angular/router';

import { SaveChangesGuard } from '@app/core';
import { AddEmployeeGuard, EmployeeResolver } from '@app/core/employees';

import { EmployeeListContainerComponent } from './container/employees-container.component';
import { EmployeeDetailComponent } from './detail/employee-detail.component';

export const EmployeesRoutes: Routes = [
  {
    path: 'employees',
    component: EmployeeListContainerComponent
  },
  {
    path: 'employees/new',
    canActivate: [
      AddEmployeeGuard
    ],
    component: EmployeeDetailComponent,
    canDeactivate: [
      SaveChangesGuard,
    ]
  },
  {
    path: 'employees/:id',
    component: EmployeeDetailComponent,
    canDeactivate: [
      SaveChangesGuard,
    ],
    resolve: {
      employee: EmployeeResolver
    }
  },
];
