<div class="options-container">
  <div class="row">
    <div class="col-12">

      <mat-form-field appearance="fill">
        <mat-label>Fecha</mat-label>
        <input matInput [matDatepicker]="picker" (click)="picker.open()" [(ngModel)]="selectedDate"
          (ngModelChange)="selectedDateChanged()" autocomplete="false" readonly>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <button mat-flat-button type="button" color="primary" (click)="loadReport()">Refrescar</button>

    </div>
  </div>
</div>


<app-spinner *ngIf="loading">
</app-spinner>


<div class="data-container" *ngIf="report$ | async as report">

  <ng-container *ngIf="!report.entries.length">
    <div class="alert alert-success">
      <h3 class="text-center">
        <div>
          <mat-icon>timeline</mat-icon>
        </div>
        No se encuentran registros con los filtros actuales
      </h3>
    </div>
  </ng-container>

  <ng-container *ngIf="report.entries.length">
    <div class="row" *ngFor="let entry of report.entries">

      <div class="col-12">
        <h3>{{ entry.task.name }}</h3>
      </div>

      <div class="col-12">
        <div class="container p-0">
          <div class="row no-gutters" *ngFor="let record of entry.entries">

            <div class="col-12 col-sm-4 font-weight-bold">
              {{ record.employee.name }}
            </div>

            <div class="col-12 col-sm-2 text-nowrap">
              {{ record.date | date: 'dd/MM/yy' }}
            </div>

            <div class="col-4 col-sm-2 text-nowrap">
              {{ record.startTime }}
            </div>

            <div class="col-4 col-sm-2 text-nowrap">
              {{ record.endTime }}
            </div>

            <div class="col-4 col-sm-2 text-nowrap text-right">
              {{ record.totalTime | timeLapse }}
            </div>

          </div>
        </div>
      </div>

      <div class="col-12 font-weight-bold text-right">
        Total {{ entry.totalTime | timeLapse}}
      </div>

      <div class="col-12">
        <hr />
      </div>

    </div>

    <div class="row" *ngFor="let chartData of chartDatas; let index = index">


      <div class="col-12">

        <div class="chart">
          <canvas baseChart [datasets]="chartData" [labels]="chartLabels[index]" chartType="bar"
            [options]="chartOptions">
          </canvas>
        </div>

      </div>

    </div>

    <div class="row" *ngIf="report.locations.length">
      <div class="col-12">

        <app-report-locations [locations]="report.locations"></app-report-locations>

      </div>
    </div>


  </ng-container>

</div>