import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { IErrorResponse } from '@app/core';
import { AuthService } from '@app/core/auth';
import { CustomErrorStateMatcher } from '@app/shared';
import { IConfirmResetPasswordRequest, UsersService } from '@app/core/users';

@Component({
  selector: 'app-confirm-reset',
  templateUrl: './confirm-reset.component.html',
  styleUrls: ['./confirm-reset.component.scss']
})
export class ConfirmResetComponent implements OnInit {

  public frmReset: FormGroup;
  public error: string;
  public success: boolean;
  public working: boolean;
  public submitted: boolean;
  public matcher: CustomErrorStateMatcher;
  public request: IConfirmResetPasswordRequest;

  constructor(
    private authService: AuthService,
    private usersService: UsersService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {

    this.matcher = new CustomErrorStateMatcher();

    this.frmReset = this.fb.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    }, { validator: this.checkPasswords });

  }

  public ngOnInit(): void {

    const data = this.route.snapshot.data?.registration;
    this.request = {
      token: data?.token,
      userName: data?.user,
      organisation: data?.organisation,
    } as any;

  }

  private checkPasswords(group: FormGroup): any {
    const pass = group.get('password').value;
    const confirmPass = group.get('confirmPassword').value;
    return pass === confirmPass ? null : { notSame: true };
  }

  public doConfirmReset(): void {

    this.submitted = true;
    if (!this.frmReset.valid) {
      return;
    }

    this.working = true;

    this.request = {
      ...this.request,
      ...this.frmReset.value
    };

    this.usersService
      .confirmResetPassword(this.request)
      .pipe(
        catchError((response: IErrorResponse) => {
          // catch the error to avoid global error handler catching it
          return of(response);
        })
      )
      .subscribe((response) => {

        const resp = response as IErrorResponse;
        if (response.error) {
          this.error = response.error;
          this.working = false;
        } else {
          this.success = true;
          this.authService.login({
            client_id: this.request.organisation,
            userName: this.request.userName,
            password: this.request.password,
            captcha: '',
            remember: true
          }).subscribe(() => {
            this.router.navigateByUrl('/');
          });
        }

      });
  }
}
