<div mat-dialog-content class="data-container">

  <div class="p-2">
    <h2>Resultados de la sincronización</h2>
  </div>

  <div class="p-2 sync-ok">{{syncOk}} actividades sincronizadas</div>
  <div ng-if="sync-ok==0" class="p-2 sync-ko">{{syncKo}} actividades con error</div>
  <mat-list>
    <mat-list-item *ngFor="let record of failedRecords">
      <p matLine>{{record.taskName}} en {{record.locationName||"N/D"}} ha fallado</p>
      <p class="error-description" matLine>{{record.error}}</p>
    </mat-list-item>
  </mat-list>

</div>

<mat-dialog-actions>
  <button mat-flat-button (click)="close()">Cerrar</button>
</mat-dialog-actions>