
export enum UserRole {
  Admin = 1,
  Regular = 2,
  SuperAdmin = 3,
  Coordinator = 4
}

export interface ILoginRequest {
  userName: string;
  password: string;
  client_id: string;
  remember: boolean;
  captcha: string;
}

export interface ISessionInfo {
  id: string;
  userName: string;
  token: string;
  refreshToken: string;
  expires: Date;
  role: UserRole;
}
