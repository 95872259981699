import { Routes } from '@angular/router';

import { SaveChangesGuard } from '@app/core';
import { JobResolver } from '@app/core/jobs';

import { JobsListComponent } from './list/jobs-list.component';
import { JobDetailComponent } from './detail/job-detail.component';

export const JobsRoutes: Routes = [
  {
    path: 'jobs',
    component: JobsListComponent
  },
  {
    path: 'jobs/new',
    component: JobDetailComponent,
    canDeactivate: [
      SaveChangesGuard,
    ]
  },
  {
    path: 'jobs/:id',
    component: JobDetailComponent,
    resolve: {
      job: JobResolver
    },
    canDeactivate: [
      SaveChangesGuard,
    ]
  },
];
