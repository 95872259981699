import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { ISubscriptionDTO, SubscriptionService, SubscriptionType } from '@app/core/subscription';

@Component({
  selector: 'app-subscription-welcome',
  templateUrl: './subscription-welcome.component.html',
  styleUrls: ['./subscription-welcome.component.scss']
})
export class SubscriptionWelcomeComponent {

  public condition: boolean;
  public subscription$: Observable<ISubscriptionDTO>;
  public working: boolean;

  constructor(
    private router: Router,
    private service: SubscriptionService,
  ) {
    this.subscription$ = this.service.getSubscriptionStatus();
  }

  public enableFree(): void {
    this.working = true;
    this.service.enable({
      type: SubscriptionType.Free
    }).subscribe(() => {
      this.router.navigate(['/employees']);
    });
  }

  public enableSubscription(): void {
    this.router.navigate(['/subscription/enable']);
  }

}
